import {
  Entity,
  NumberKeyframe,
  TopLeftCornerRadiusComponent,
  getValueNumber,
  round,
} from '@aninix-inc/model'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 8,
  y: 8,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const TopLeftCornerRadius: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const properties = layers.map((l) =>
    l.getComponentOrThrow(TopLeftCornerRadiusComponent)
  )

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={properties}
            time={time}
            icon={<icons.propertiesPanel.TopLeftRadius size={iconSize} />}
            min={0}
            format={(v) => `${round(v, { fixed: 2 })}`}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={properties}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}
