import { NodeColorComponent, Point2D, UndoRedoSystem } from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { getAbsoluteTransformMatrixV2 } from '@aninix/core/utils'
import { BezierPoint } from '@aninix/core/vector-helpers'
import _ from 'lodash'
import * as R from 'ramda'
import * as React from 'react'

import { usePlayback, useProject } from '@aninix/core/stores'
import { nodeColors } from '../../../registries'
import { RegionPoint } from './svg-vertices-region-point'

type BezierPath = { points: BezierPoint[]; isClosed: boolean }
type BezierRegion = BezierPath[]

export interface IProps {
  node: any
  vectorPath: VectorPath
  editable?: boolean
  onRegionUpdate: (vectorPathData: string) => void
  selectionRectBoundingRect?: {
    x: number
    y: number
    width: number
    height: number
  }
}
export const Region: React.FCC<IProps> = ({
  node,
  vectorPath,
  editable,
  onRegionUpdate,
  selectionRectBoundingRect,
}) => {
  const project = useProject()
  const playback = usePlayback()
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const [selection, setSelection] = React.useState<Set<string>>(new Set([]))

  const region: BezierRegion = React.useMemo(
    () => svgPathToBezierRegion(vectorPath.data),
    [vectorPath.data]
  )

  const [localRegion, setLocalRegion] = React.useState<BezierRegion>(region)

  React.useEffect(() => {
    // session.setHandleVectorSelectionMove((offset: Point2D) => {
    //   //this doesn't update any element, but updates selection
    //   updatePath(null, null, { point: offset } as BezierPoint)
    // })
  }, [selection])

  const updatePath = React.useCallback(
    (
      draggedPathIndex: number | null,
      draggedBezierIndex: number | null,
      offset: BezierPoint
    ) => {
      // updates dragged element without selection
      if (draggedPathIndex !== null && draggedBezierIndex !== null)
        localRegion[draggedPathIndex].points[draggedBezierIndex] =
          localRegion[draggedPathIndex].points[draggedBezierIndex].add(offset)

      // updates other elements in selection, except dragged
      selection?.forEach((id) => {
        if (id === `${draggedPathIndex}-${draggedBezierIndex}`) return

        let [pathIndex, bezierIndex] = id.split('-').map((e) => parseInt(e))

        localRegion[pathIndex].points[bezierIndex] =
          localRegion[pathIndex].points[bezierIndex].add(offset)
      })

      setLocalRegion([...region])
    },
    [selection, region, onRegionUpdate]
  )
  //that's been memoed, but caused bugs with moved shape selection caused selection on ghost points
  const absoluteTransformMatrix = (() =>
    getAbsoluteTransformMatrixV2({ entity: node, time: playback.time }))()

  const flatAbsolutePointsMap = React.useMemo(
    () =>
      _.flatten(
        localRegion.map((path, pathIndex) =>
          path.points.map((bezier, bezierIndex) => {
            return {
              id: `${pathIndex}-${bezierIndex}`,
              point: absoluteTransformMatrix.transform(bezier.point),
            }
          })
        )
      ),
    [localRegion, absoluteTransformMatrix]
  )

  React.useEffect(() => {
    if (selectionRectBoundingRect == null) return

    let newSelection = flatAbsolutePointsMap
      .filter(
        ({ point }) =>
          point.x >= selectionRectBoundingRect.x &&
          point.x <=
            selectionRectBoundingRect.x + selectionRectBoundingRect.width &&
          point.y >= selectionRectBoundingRect.y &&
          point.y <=
            selectionRectBoundingRect.y + selectionRectBoundingRect.height
      )
      .map((point) => point.id)

    setSelection(new Set(newSelection))
  }, [selectionRectBoundingRect])

  const updateTangents = React.useCallback(
    (
      updatedPathIndex: number,
      updatedBezierIndex: number,
      newRelativeStartPoint: Point2D,
      newRelativeEndPoint: Point2D
    ) => {
      // updates dragged element without selection

      localRegion[updatedPathIndex].points[updatedBezierIndex] = localRegion[
        updatedPathIndex
      ].points[updatedBezierIndex].updateRelativeStartTangent(
        newRelativeStartPoint
      )

      localRegion[updatedPathIndex].points[updatedBezierIndex] =
        localRegion[updatedPathIndex].points[
          updatedBezierIndex
        ].updateRelativeEndTangent(newRelativeEndPoint)

      // // updates other elements in selection, except dragged
      // selection?.forEach((id) => {
      //   if (id === `${updatedPathIndex}-${updatedBezierIndex}`) return

      //   let [pathIndex, bezierIndex] = id.split('-').map((e) => parseInt(e))

      //   localRegion[pathIndex].points[bezierIndex] = localRegion[
      //     pathIndex
      //   ].points[bezierIndex].updateRelativeStartTangent(newRelativeStartPoint)

      //   localRegion[pathIndex].points[bezierIndex] =
      //     localRegion[pathIndex].points[bezierIndex].updateRelativeEndTangent(
      //       newRelativeEndPoint
      //     )
      // })

      setSelection(new Set([`${updatedPathIndex}-${updatedBezierIndex}`]))

      setLocalRegion([...region])

      console.log(bezierRegionToSvgPath({ region }))
    },
    [selection, region, onRegionUpdate]
  )

  const handleEndChange = React.useCallback(() => {
    onRegionUpdate(bezierRegionToSvgPath({ region: region }))
    undoRedo.stopRecordingHistory()
  }, [localRegion])

  return (
    <>
      {localRegion?.map((path, pathIndex) => (
        <React.Fragment key={`node-${node.id}-path-${pathIndex}`}>
          {path?.points.map((bezier: BezierPoint, bezierIndex) => (
            <RegionPoint
              key={`node-${node.id}-path-${pathIndex}-bezier-${bezierIndex}`}
              node={node}
              bezier={bezier}
              onStartChange={undoRedo.startRecordingHistory}
              // @ts-ignore
              color={
                //@ts-ignore
                nodeColors[node.getComponentOrThrow(NodeColorComponent).value]
              }
              pathIndex={pathIndex}
              bezierIndex={bezierIndex}
              onPathUpdate={updatePath}
              selection={selection}
              setSelection={setSelection}
              onEndChange={handleEndChange}
              editable={editable}
              onTangentsUpdate={updateTangents}
              prevAndNextPoints={[
                path.points.at(bezierIndex - 1)!.point,
                path.points.at((bezierIndex + 1) % path.points.length)!.point,
              ]}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  )
}

Region.displayName = 'Region'

function svgPathToBezierRegion(providedData: string): BezierRegion {
  const data = new paper.CompoundPath(providedData).pathData
  const splitted = data.split(/(?=[MmVvHhLlCcSQTZz])/)

  const parsedCommands: (string | number)[][] = splitted.map((rawCommand) => {
    const command = R.take(1, rawCommand)
    const points = R.drop(1, rawCommand)
      .replace(/\,/g, ' ')
      .split(' ')
      .filter((point) => point !== '')
      .map((point) => parseFloat(point))

    return [command, ...points]
  })

  const bezierPoints: BezierPoint[][] = []

  for (let i = 0; i < parsedCommands.length; i += 1) {
    const commandWithPoints = parsedCommands[i]
    const command = R.head(commandWithPoints) as string
    const points = R.drop(1, commandWithPoints) as number[]
    const prevPoint = R.last(R.defaultTo([], R.last(bezierPoints)!))!

    if (command === 'M' || command === 'm') {
      bezierPoints.push([])
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'V') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: prevPoint.point.x, y: points[0] } })
      )
      continue
    }

    if (command === 'v') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x, y: prevPoint.point.y + points[0] },
        })
      )
      continue
    }

    if (command === 'H') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: prevPoint.point.y } })
      )
      continue
    }

    if (command === 'h') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x + points[0], y: prevPoint.point.y },
        })
      )
      continue
    }

    if (command === 'L') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'l') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: {
            x: prevPoint.point.x + points[0],
            y: prevPoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'C') {
      prevPoint.updateAbsoluteEndTangent({
        x: points[0],
        y: points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: { x: points[4], y: points[5] },
          startTangent: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'c') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      prevPoint.updateAbsoluteEndTangent({
        x: absolutePoint.point.x + points[0],
        y: absolutePoint.point.y + points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: {
            x: absolutePoint.point.x + points[4],
            y: absolutePoint.point.y + points[5],
          },
          startTangent: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
        })
      )

      continue
    }

    if (command === 'S') {
      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: prevPoint.toAbsoluteJson().endTangent,
          point: { x: points[2], y: points[3] },
          endTangent: { x: points[0], y: points[1] },
        })
      )
      continue
    }

    if (command === 's') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: absolutePoint.endTangent,
          point: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
          endTangent: {
            x: absolutePoint.point.x + points[0],
            y: absolutePoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'Q') {
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'T') {
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }
  }

  // const withoutTwoIdenticalPointsInARow = R.uniqWith(
  //   (left, right) => left.equals(right),
  //   bezierPoints
  // )

  const preparedPoints = bezierPoints // withoutTwoIdenticalPointsInARow

  return preparedPoints
    .map((points) => {
      const newPoints = R.clone(points)
      const lastCommand = R.last(parsedCommands)!

      let isClosed = lastCommand[0] === 'Z' || lastCommand[0] === 'z'

      return { points: newPoints, isClosed }
    })
    .filter(({ points }) => points.length > 0)
}

function bezierRegionToSvgPath(payload: { region: BezierRegion }): string {
  const { region } = payload

  if (region.length === 0) {
    return ''
  }

  let svgPath = ''

  for (let i = 0; i < region.length; i += 1) {
    const path = region[i]
    const points = path.points.map((point) => point.toAbsoluteJson())

    for (let j = 0; j < points.length; j += 1) {
      const point = points[j]

      // @NOTE: set start position to correct coordinate
      if (j === 0) {
        svgPath += `M ${point.point.x} ${point.point.y}`
        continue
      }

      const prevPoint = points[j - 1]

      const point0 = prevPoint.endTangent.x
      const point1 = prevPoint.endTangent.y
      const point2 = point.startTangent.x
      const point3 = point.startTangent.y
      const point4 = point.point.x
      const point5 = point.point.y

      // @NOTE: draw next point
      svgPath += ` C ${point0} ${point1}, ${point2} ${point3}, ${point4} ${point5}`

      // @NOTE: close the spline if needed
      if (j === points.length - 1 && i === region.length - 1) {
        const isClosed = path.isClosed

        if (isClosed) {
          svgPath += ' Z'
        }
      }
    }
  }

  return svgPath
}
