import {
  Entity,
  EntityType,
  EntryComponent,
  LayoutAspect,
  NodeColorComponent,
  ParentRelationAspect,
  PositionComponent,
  Project,
  SelectionSystem,
  SpatialPoint2dValueComponent,
  TargetRelationAspect,
  commitUndo,
  getNode,
  setPosition,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import {
  convertEntityToSnapshot as convertNodeToSnapshot,
  paper,
} from '@aninix-inc/renderer'
import { useMouseMove } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { nodeColors } from '../../../registries'
import {
  KeyModificator,
  Tool,
  useImagesStore,
  usePlayback,
  useSession,
  useTools,
  useViewport,
} from '../../../stores'
import { useEntities, useReloadOnAnyUpdate, useSystem } from '../../../updates'
import { getTransformMatrixV2 } from '../../../utils'
import { getAbsoluteBoundingBoxAtTimeV2 } from '../../../utils/get-absolute-bounding-box-at-time'
import { getAbsoluteTransformMatrixV2 } from '../../../utils/get-absolute-transform-matrix'
import { mapWindingRule } from './utils'

const noop = () => {}

export type BoundingBox = {
  x: number
  y: number
  width: number
  height: number
}

export interface IProps {
  project: Project
  onBoundingBoxUpdate?: (boundingBox: BoundingBox) => void
  editable?: boolean
}
export const SvgTransformer: React.FCC<IProps> = observer(
  ({ project, onBoundingBoxUpdate = noop, editable = true }) => {
    const selection = project.getSystemOrThrow(SelectionSystem)
    useSystem(selection)
    const updateVersion = useReloadOnAnyUpdate(project)
    const images = useImagesStore()
    const session = useSession()
    const viewport = useViewport()
    const tools = useTools()
    const playback = usePlayback()
    const bakedKeyframePositionsRef = React.useRef<Record<string, Point2D>>({})
    const bakedPositionsRef = React.useRef<Record<string, Point2D>>({})
    const bakedParentAbsoluteTransformMatriciesRef = React.useRef<
      Record<string, paper.Matrix>
    >({})
    const backedBoundingBoxRef = React.useRef<BoundingBox>({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    })
    const [boundingBox, setBoundingBox] = React.useState<BoundingBox>({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    })
    const onEndChange = React.useCallback(() => {
      commitUndo(project)
    }, [project])
    const { offsetX, offsetY, isListening, startListen, wasTriggered } =
      useMouseMove({
        threshold: 2,
        element: document.getElementById('stage')!,
        // @NOTE: 120 fps
        delay: 8.3,
        onFinish: onEndChange,
      })

    const selectedNodes = React.useMemo(
      () => selection.getEntitiesByEntityType(EntityType.Node),
      [updateVersion, selection]
    )
    const selectedNodesVersion = useEntities(selectedNodes)

    const highlightedNodes = React.useMemo(() => {
      const nodes = selection.getEntitiesByEntityType(EntityType.Node)
      const keyframes = selection.getEntitiesByEntityType(EntityType.Keyframe)
      const nodesFromKeyframes = keyframes
        .map((keyframe) => getNode(keyframe))
        .filter((node) => node != null) as Entity[]
      return R.uniqBy((node) => node.id, [...nodes, ...nodesFromKeyframes])
    }, [updateVersion, selection])

    const selectedKeyframes = React.useMemo(
      () =>
        selection
          .getEntitiesByEntityType(EntityType.Keyframe)
          .filter((keyframe) =>
            keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getRelationOrThrow()
              .includes(PositionComponent.name)
          ),
      [updateVersion, selection]
    )
    const highlightedNodesVersion = useEntities(highlightedNodes)

    React.useEffect(() => {
      if (isListening === false || wasTriggered === false) {
        return
      }

      const { movedX, movedY } = (() => {
        const actualOffsetX = Math.round(offsetX / viewport.zoom)
        const actualOffsetY = Math.round(offsetY / viewport.zoom)

        if (session.keyModificators.includes(KeyModificator.Shift)) {
          const isXBigger = Math.abs(actualOffsetX) > Math.abs(actualOffsetY)
          const isYBigger = Math.abs(actualOffsetX) < Math.abs(actualOffsetY)

          if (isXBigger) {
            return {
              movedX: actualOffsetX,
              movedY: 0,
            }
          }

          if (isYBigger) {
            return {
              movedX: 0,
              movedY: actualOffsetY,
            }
          }
        }

        return {
          movedX: actualOffsetX,
          movedY: actualOffsetY,
        }
      })()

      if (selectedKeyframes.length > 0) {
        selectedKeyframes.forEach((keyframe) => {
          const node = keyframe
            .getAspectOrThrow(ParentRelationAspect)
            .getParentEntityOrThrow()
          const position = bakedKeyframePositionsRef.current[keyframe.id]
          const parentAbsoluteTransformMatrix =
            bakedParentAbsoluteTransformMatriciesRef.current[node.id]

          // @NOTE: in case state was not updated
          if (position == null || parentAbsoluteTransformMatrix == null) {
            return
          }

          const movedPoint = parentAbsoluteTransformMatrix.inverseTransform(
            new paper.Point(movedX, movedY)
          )
          const startPoint = parentAbsoluteTransformMatrix.inverseTransform(
            new paper.Point(0, 0)
          )

          const newPoint = movedPoint.subtract(startPoint)

          keyframe
            .getComponentOrThrow(SpatialPoint2dValueComponent)
            .updatePoint({
              x: position.x + newPoint.x,
              y: position.y + newPoint.y,
            })
        })
      } else {
        selectedNodes.forEach((node) => {
          const position = bakedPositionsRef.current[node.id]
          const parentAbsoluteTransformMatrix =
            bakedParentAbsoluteTransformMatriciesRef.current[node.id]

          // @NOTE: in case state was not updated
          if (position == null || parentAbsoluteTransformMatrix == null) {
            return
          }

          const movedPoint = parentAbsoluteTransformMatrix.inverseTransform(
            new paper.Point(movedX, movedY)
          )
          const startPoint = parentAbsoluteTransformMatrix.inverseTransform(
            new paper.Point(0, 0)
          )

          const newPoint = movedPoint.subtract(startPoint)

          // @TODO: add proper tangents update
          setPosition(
            node,
            {
              x: position.x + newPoint.x,
              y: position.y + newPoint.y,
              tx1: position.x + newPoint.x,
              ty1: position.y + newPoint.y,
              tx2: position.x + newPoint.x,
              ty2: position.y + newPoint.y,
            },
            playback.time
          )
        })
      }

      setBoundingBox({
        x: backedBoundingBoxRef.current.x + movedX,
        y: backedBoundingBoxRef.current.y + movedY,
        width: backedBoundingBoxRef.current.width,
        height: backedBoundingBoxRef.current.height,
      })
    }, [isListening, offsetX, offsetY])

    React.useEffect(() => {
      if (isListening === false && wasTriggered) {
        backedBoundingBoxRef.current = boundingBox
      }
    }, [isListening, boundingBox, wasTriggered])

    React.useEffect(() => {
      onBoundingBoxUpdate(boundingBox)
    }, [onBoundingBoxUpdate, boundingBox])

    // @NOTE: update bounding box cache
    React.useEffect(() => {
      if (isListening) {
        return
      }

      if (selectedNodes.length === 0) {
        bakedPositionsRef.current = {}
        bakedParentAbsoluteTransformMatriciesRef.current = {}
        backedBoundingBoxRef.current = {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
        }
        return
      }

      if (selectedKeyframes.length === 0) {
        bakedKeyframePositionsRef.current = {}
      }

      const currentBoundingBox = selectedNodes.reduce<BoundingBox>(
        (box, entity) => {
          const nodeBoundingBox = getAbsoluteBoundingBoxAtTimeV2({
            entity,
            time: playback.time,
          })

          const left: number = R.min(box.x, nodeBoundingBox.x)
          const top: number = R.min(box.y, nodeBoundingBox.y)
          const right: number = R.max(
            box.x + box.width,
            nodeBoundingBox.x + nodeBoundingBox.width
          )
          const bottom: number = R.max(
            box.y + box.height,
            nodeBoundingBox.y + nodeBoundingBox.height
          )

          return {
            x: left,
            y: top,
            width: Math.abs(right - left),
            height: Math.abs(bottom - top),
          }
        },
        getAbsoluteBoundingBoxAtTimeV2({
          entity: R.head(selectedNodes)!,
          time: playback.time,
        })
      )

      selectedNodes.forEach((node) => {
        const layout = node.getAspectOrThrow(LayoutAspect)
        bakedPositionsRef.current[node.id] = layout.position.getValue(
          playback.time
        )

        bakedParentAbsoluteTransformMatriciesRef.current[node.id] =
          node.hasComponent(EntryComponent)
            ? getTransformMatrixV2({ entity: node, time: playback.time })
            : getAbsoluteTransformMatrixV2({
                entity: node
                  .getAspectOrThrow(ParentRelationAspect)
                  .getParentEntityOrThrow(),
                time: playback.time,
              })
      })

      selectedKeyframes.forEach((keyframe) => {
        bakedKeyframePositionsRef.current[keyframe.id] =
          keyframe.getComponentOrThrow(SpatialPoint2dValueComponent).value
      })

      backedBoundingBoxRef.current = currentBoundingBox
      setBoundingBox(currentBoundingBox)
    }, [
      isListening,
      selectedNodes,
      selectedKeyframes,
      playback.time,
      selectedNodesVersion,
      highlightedNodesVersion,
    ])

    const handleDragStart = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        if (editable === false) {
          return
        }

        e.preventDefault()
        e.stopPropagation()

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        if (
          R.any(
            (n) => n.hasComponent(EntryComponent),
            selection.getEntitiesByEntityType(EntityType.Node)
          )
        ) {
          return
        }

        // @ts-ignore
        startListen(e)
      },
      [startListen, tools, editable, selection]
    )

    const handleClick = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        if (editable === false) {
          return
        }

        if (wasTriggered) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      [wasTriggered, editable]
    )

    if (highlightedNodes.length === 0 && selectedNodes.length === 0) {
      return null
    }

    return (
      <>
        {highlightedNodes.map((child) => {
          const snapshot = convertNodeToSnapshot({
            entity: child,
            time: playback.time,
            imagesStore: images,
          })

          if (snapshot.fillData.length === 0) {
            return null
          }

          return (
            <path
              key={child.id}
              paintOrder="stroke"
              transform={`matrix(${snapshot.absoluteTransformMatrix.values})`}
              d={snapshot.fillData.map((i) => i.data).join('')}
              fillRule={mapWindingRule(snapshot.fillData[0].windingRule)}
              fill="none"
              stroke={
                nodeColors[child.getComponentOrThrow(NodeColorComponent).value]
              }
              // @NOTE: required to divide by current scale because stroke depends on scale here
              strokeWidth={1 / viewport.zoom}
            />
          )
        })}

        <rect
          id="transformer"
          x={boundingBox.x}
          y={boundingBox.y}
          width={boundingBox.width}
          height={boundingBox.height}
          fill="rgba(255, 255, 255, 0.001)"
          stroke={
            selectedNodes.length === 1
              ? nodeColors[
                  selectedNodes[0].getComponentOrThrow(NodeColorComponent).value
                ]
              : nodeColors.BLUE
          }
          strokeWidth={1 / viewport.zoom}
          onMouseDown={handleDragStart}
          onClick={handleClick}
          pointerEvents={
            selectedNodes[0] != null &&
            session.buffer.includes(selectedNodes[0].id)
              ? 'auto'
              : 'none'
          }
        />
      </>
    )
  }
)

SvgTransformer.displayName = 'SvgTransformer'
