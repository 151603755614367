import { Component, commitUndo, mixed } from '@aninix-inc/model'
import { InputWithIcon, Select } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValueSpatialPoint2d } from '../../../../../hooks/value-controllers/spatial-point-2d'

// @TODO: provide generic types
// @TODO: extract tangent selector to separated component
export interface IProps {
  components: Component[]
  time?: number
  iconX?: React.ReactNode
  iconY?: React.ReactNode
  formatValue?: (value: number) => string
}
export const SpatialPoint2dValue: React.FCC<IProps> = observer(
  ({ components, time, iconX, iconY, formatValue }) => {
    const {
      x,
      y,
      tangentMirrorId,
      updateX,
      updateXByDelta,
      updateY,
      updateYByDelta,
      updateTangetMirror,
    } = useValueSpatialPoint2d({ components, time })

    const id = components.map((component) => component.id).join('-')
    const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())

    return (
      <div>
        <div className="flex flex-row">
          <InputWithIcon
            value={x === mixed ? mixed : x}
            id={id}
            icon={iconX}
            onChange={updateX}
            onDeltaChange={updateXByDelta}
            onEndChange={endChange}
            format={formatValue}
          />
          <InputWithIcon
            value={y === mixed ? mixed : y}
            id={id}
            icon={iconY}
            onChange={updateY}
            onDeltaChange={updateYByDelta}
            onEndChange={endChange}
            format={formatValue}
          />
        </div>

        {/* @NOTE: enable only for static properties eg keyframes */}
        {time == null && (
          <Select
            className="pl-[6px]"
            activeValueId={tangentMirrorId}
            onChange={(value) => updateTangetMirror(value as any)}
            options={[
              {
                id: 'no-mirroring',
                title: 'No mirroring',
              },
              // {
              //   id: 'mirror-angle',
              //   title: 'Mirror angle',
              // },
              // {
              //   id: 'mirror-angle-and-length',
              //   title: 'Mirror angle and length',
              // },
              {
                id: 'custom',
                title: 'Custom',
              },
            ]}
          />
        )}
      </div>
    )
  }
)

SpatialPoint2dValue.displayName = 'SpatialPoint2dValue'
