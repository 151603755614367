import { Component, commitUndo } from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValuePoint2d } from '../../../../../hooks/value-controllers/point-2d'
import { NumericValue } from '../numeric-value'

export interface IProps {
  components: Component[]
  color?: string
  time?: number
  formatValue?: (value: number) => string
}
export const Point2dValue: React.FCC<IProps> = observer(
  ({ components, color = 'black', time, formatValue }) => {
    const { x, y, updateX, updateY } = useValuePoint2d({ components, time })

    const project = components[0].entity.getProjectOrThrow()
    const onEndChange = React.useCallback(() => {
      commitUndo(project)
    }, [])

    return (
      <>
        <NumericValue
          onEndChange={onEndChange}
          suffix=", "
          value={x as number}
          onChange={updateX}
          textColor={color}
          format={formatValue}
        />
        <NumericValue
          onEndChange={onEndChange}
          value={y as number}
          onChange={updateY}
          textColor={color}
          format={formatValue}
        />
      </>
    )
  }
)

Point2dValue.displayName = 'Point2dValue'
