import * as React from 'react'

import { Locked } from './Locked'
import { Normal } from './Normal'

const defaultSize = {
  x: 20,
  y: 20,
}

export enum LinkType {
  Locked = 'LOCKED',
  Normal = 'NORMAL',
}

export interface IProps {
  type: LinkType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Link: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (LinkType.Locked === type) {
    return <Locked size={size} style={style} />
  }

  return <Normal size={size} style={style} />
}
