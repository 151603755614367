import {
  ChildrenRelationsAspect,
  ClipContentComponent,
  CornerRadiusComponent,
  EndAngleComponent,
  Entity,
  FillsRelationsAspect,
  InnerRadiusComponent,
  PointCountComponent,
  SmoothCornerRadiusComponent,
  StartAngleComponent,
  StrokesRelationsAspect,
  isIndividualCornerRadiusAvailable,
  isIndividualCornerRadiusEnabled,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
} from '@aninix-inc/model'
import { useEntities } from '@aninix/core'
import { useMathScopes } from '@aninix/editor/use-cases/use-math-scopes'
import React, { useCallback } from 'react'
import { Align } from './align'
import { AnchorPoint } from './anchor-point'
import { ArcEndingAngle } from './arc-ending-angle'
import { ArcStartingAngle } from './arc-starting-angle'
import { BottomLeftCornerRadius } from './bottom-left-corner-radius'
import { BottomRightCornerRadius } from './bottom-right-corner-radius'
import { ClipsContent } from './clips-content'
import { ColorSelection } from './color-selection'
import { CornerRadius } from './corner-radius'
import { Effects } from './effects'
import { Fills } from './fills'
import { InnerRadius } from './inner-radius'
import { Opacity } from './opacity'
import { PointCount } from './point-count'
import { Position } from './position'
import { Rotation } from './rotation'
import { Scale } from './scale'
import { Size } from './size'
import { Skew } from './skew'
import { SmoothCorners } from './smooth-corners'
import { StrokeBottomWeight } from './stroke-bottom-weight'
import { StrokeLeftWeight } from './stroke-left-weight'
import { StrokeRightWeight } from './stroke-right-weight'
import { StrokeTopWeight } from './stroke-top-weight'
import { Strokes } from './strokes'
import { TopLeftCornerRadius } from './top-left-corner-radius'
import { TopRightCornerRadius } from './top-right-corner-radius'
import { TrimLayer } from './trim-layer'

export const getFlattenChildren = (providedLayers: Entity[]): Entity[] => {
  let layers: Entity[] = [...providedLayers]

  for (let i = 0; i < layers.length; i += 1) {
    const layer = layers[i]

    if (layer.hasAspect(ChildrenRelationsAspect)) {
      layers.push(
        ...layer.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList()
      )
    }
  }

  return layers
}

const Wrapper: React.FC<{
  layers: Entity[]
  predicate: (entity: Entity) => boolean
  Render: React.FC<{ layers: Entity[] }>
}> = ({ layers, predicate, Render }) => {
  const requiredLayers = layers.filter(predicate)

  if (requiredLayers.length === 0) {
    return null
  }

  return <Render layers={requiredLayers} />
}

export interface IProps {
  layers: Entity[]
  time: number
}
/**
 * @note useCallback required to prevent unnecessary component reloads
 */
export const NodeProperties: React.FCC<IProps> = ({
  layers: providedLayers,
  time,
}) => {
  useEntities(providedLayers)
  useMathScopes(providedLayers, time)

  return (
    <>
      <Align layers={providedLayers} />
      <AnchorPoint layers={providedLayers} time={time} />
      <Position layers={providedLayers} time={time} />
      <Size layers={providedLayers} time={time} />
      <Scale layers={providedLayers} time={time} />
      <Skew layers={providedLayers} time={time} />
      <Rotation layers={providedLayers} time={time} />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback(
          (l) => l.hasComponent(CornerRadiusComponent),
          []
        )}
        Render={useCallback(
          ({ layers }) => (
            <CornerRadius layers={layers} time={time} />
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback(
          (l) =>
            isIndividualCornerRadiusAvailable(l) &&
            isIndividualCornerRadiusEnabled(l),
          []
        )}
        Render={useCallback(
          ({ layers }) => (
            <>
              <TopLeftCornerRadius layers={layers} time={time} />
              <TopRightCornerRadius layers={layers} time={time} />
              <BottomLeftCornerRadius layers={layers} time={time} />
              <BottomRightCornerRadius layers={layers} time={time} />
            </>
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback(
          (l) => l.hasComponent(SmoothCornerRadiusComponent),
          []
        )}
        Render={useCallback(
          ({ layers }) => (
            <SmoothCorners layers={layers} />
          ),
          []
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback(
          (l) =>
            l.hasComponent(StartAngleComponent) &&
            l.hasComponent(EndAngleComponent),
          []
        )}
        Render={useCallback(
          ({ layers }) => (
            <>
              <ArcStartingAngle layers={layers} time={time} />
              <ArcEndingAngle layers={layers} time={time} />
            </>
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback((l) => l.hasComponent(InnerRadiusComponent), [])}
        Render={useCallback(
          ({ layers }) => (
            <InnerRadius layers={layers} time={time} />
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback((l) => l.hasComponent(PointCountComponent), [])}
        Render={useCallback(
          ({ layers }) => (
            <PointCount layers={layers} time={time} />
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback((l) => l.hasComponent(ClipContentComponent), [])}
        Render={useCallback(
          ({ layers }) => (
            <ClipsContent layers={layers} />
          ),
          []
        )}
      />

      <Opacity layers={providedLayers} time={time} />

      <TrimLayer layers={providedLayers} time={time} />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback((l) => l.hasAspect(FillsRelationsAspect), [])}
        Render={useCallback(
          ({ layers }) => (
            <Fills layers={layers} time={time} />
          ),
          [time]
        )}
      />

      <Wrapper
        layers={providedLayers}
        predicate={useCallback((l) => l.hasAspect(StrokesRelationsAspect), [])}
        Render={useCallback(
          ({ layers }) => (
            <Strokes
              layers={layers}
              time={time}
              individualStrokes={
                <Wrapper
                  layers={providedLayers}
                  predicate={useCallback(
                    (l) =>
                      isIndividualStrokesAvailable(l) &&
                      isIndividualStrokesEnabled(l),
                    []
                  )}
                  Render={useCallback(
                    ({ layers }) => (
                      <>
                        <StrokeTopWeight layers={layers} time={time} />
                        <StrokeRightWeight layers={layers} time={time} />
                        <StrokeBottomWeight layers={layers} time={time} />
                        <StrokeLeftWeight layers={layers} time={time} />
                      </>
                    ),
                    [time]
                  )}
                />
              }
            />
          ),
          [time]
        )}
      />

      <Wrapper
        layers={getFlattenChildren(providedLayers)}
        predicate={useCallback(
          (l) =>
            l.hasAspect(FillsRelationsAspect) &&
            l.hasAspect(StrokesRelationsAspect),
          []
        )}
        Render={useCallback(
          ({ layers }) => (
            <ColorSelection layers={layers} time={time} />
          ),
          [time]
        )}
      />

      <Effects layers={providedLayers} time={time} />
    </>
  )
}

NodeProperties.displayName = 'NodeProperties'
