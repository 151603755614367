import {
  Entity,
  PaintTypeComponent,
  SolidPaint,
  StrokesRelationsAspect,
  UpdatesSystem,
  commitUndo,
} from '@aninix-inc/model'
import { PropertyRowV2, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { Dashes } from '../dashes'
import { Paint, isPaintsMixed } from '../fills'
import { StrokeCaps } from '../stroke-caps'
import { StrokeWeight } from '../stroke-weight'
import { TrimPath } from '../trim-path'
import * as styles from './index.scss'

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  layers: Entity[]
  time: number
  individualStrokes?: React.ReactNode
}
export const Strokes: React.FCC<IProps> = ({
  layers,
  time,
  individualStrokes,
}) => {
  useEntities(layers)
  const paints = layers.flatMap((l) =>
    l.getAspectOrThrow(StrokesRelationsAspect).getChildrenList()
  )
  const project = layers[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const isMixed = isPaintsMixed(layers, StrokesRelationsAspect)

  return (
    <PropertyRowV2
      name="Stroke"
      headerButtons={
        <>
          {!isMixed && paints.length > 0 && (
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  layers.forEach((layer) => {
                    const internalPaints = layer
                      .getAspectOrThrow(StrokesRelationsAspect)
                      .getChildrenList()

                    if (internalPaints.length === 0) {
                      return
                    }

                    layer
                      .getAspectOrThrow(StrokesRelationsAspect)
                      .removeChild(R.last(internalPaints)!.id)
                  })
                })
                commitUndo(project)
              }}
              btnSize={btnSize}
            >
              <icons.Remove />
            </buttons.Icon>
          )}

          <buttons.Icon
            onClick={() => {
              updates.batch(() => {
                if (isMixed) {
                  layers.forEach((layer) => {
                    const strokes = layer.getAspectOrThrow(
                      StrokesRelationsAspect
                    )
                    strokes.clear()
                    strokes.addChild(project.createEntity(SolidPaint))
                  })
                  commitUndo(project)
                  return
                }

                layers.forEach((layer) => {
                  layer
                    .getAspectOrThrow(StrokesRelationsAspect)
                    .addChild(project.createEntity(SolidPaint))
                })
              })
              commitUndo(project)
            }}
            btnSize={btnSize}
          >
            <icons.Add />
          </buttons.Icon>
        </>
      }
      inputs={
        paints.length === 0 ? undefined : (
          <div className="flex w-full flex-col gap-[8px]">
            {isMixed ? (
              <p className={styles.mixed}>Click + to replace mixed content</p>
            ) : (
              <div className="flex w-full flex-col gap-[2px]">
                {paints.map((paint, idx) => {
                  const selectedPaints = layers
                    .map(
                      (l) =>
                        l
                          .getAspectOrThrow(StrokesRelationsAspect)
                          .getChildrenList()[idx]
                    )
                    .filter(
                      (e) =>
                        e != null &&
                        e.getComponentOrThrow(PaintTypeComponent).value ===
                          paint.getComponentOrThrow(PaintTypeComponent).value
                    )

                  if (selectedPaints.length === 0) {
                    return null
                  }

                  return (
                    <Paint key={paint.id} paints={selectedPaints} time={time} />
                  )
                })}
              </div>
            )}

            <StrokeWeight layers={layers} time={time} />
            {individualStrokes}
            <StrokeCaps layers={layers} />
          </div>
        )
      }
      wideInputs={
        paints.length === 0 ? undefined : (
          <div className="flex w-full flex-col gap-[8px]">
            <Dashes layers={layers} time={time} />
            <TrimPath layers={layers} time={time} />
          </div>
        )
      }
      empty={paints.length === 0}
    />
  )
}
