import classnames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'

import * as styles from './styles.scss'

const noop = () => {}

export interface IProps {
  isSelected: boolean
  highlightColor?: string
  color?: string
  maskColor?: string
  isHighlighted?: boolean
  isDropTarget?: boolean
  highlightIndent?: number
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onDoubleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  ref?: any
  onHoverIn?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onHoverOut?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  variant?: 'node' | 'property'
  alignment?: 'left' | 'right' | 'center'
  onContextMenu?: (e: React.MouseEvent<Element, MouseEvent>) => void
}
export const TimelineTrack: React.FCC<IProps> = observer(
  React.forwardRef<any, IProps>(
    (
      {
        isSelected,
        isHighlighted = false,
        isDropTarget = false,
        highlightColor = 'transparent',
        color = 'transparent',
        maskColor = 'transparent',
        highlightIndent,
        onMouseDown = noop,
        onClick = noop,
        onDoubleClick = noop,
        className = '',
        onHoverIn,
        onHoverOut,
        onMouseMove,
        onMouseUp,
        variant = 'node',
        alignment = 'left',
        onContextMenu,
        // @ts-ignore
        children,
      },
      ref
    ) => {
      const style = React.useMemo(
        () => ({
          '--color': color,
          '--highlight-color': highlightColor,
          '--highlight-indent': `${Number(highlightIndent) > 0 ? Number(highlightIndent) * 16 : 0}px`,
          '--mask-color': maskColor,
        }),
        [color, maskColor, highlightIndent]
      )

      return (
        // TODO: change div with button?
        /* eslint-disable-next-line */
        <div
          ref={ref}
          className={classnames(styles.container, {
            [styles['container--selected']]: isSelected,
            [styles['container--highlighted']]: isHighlighted,
            [styles['container--highlighted-left']]: alignment === 'left',
            [styles['container--highlighted-right']]: alignment === 'right',
            [styles['container--highlighted-bottom']]: isDropTarget,
          })}
          // @ts-ignore
          style={style}
          onMouseDown={onMouseDown}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          onMouseOver={onHoverIn}
          onMouseMove={onMouseMove}
          onMouseOut={onHoverOut}
          onMouseUp={onMouseUp}
          onContextMenu={onContextMenu}
        >
          <div
            className={classnames(styles.background, {
              [styles['background--property']]: variant === 'property',
            })}
            style={{
              backgroundColor: color,
              display: isSelected ? 'block' : 'none',
            }}
          />

          <div className={classnames(styles.content, className)}>
            {children}
          </div>
        </div>
      )
    }
  )
)

TimelineTrack.displayName = 'TimelineTrack'
