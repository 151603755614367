import {
  BlurRadiusComponent,
  Component,
  CurveType,
  DropShadow,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  EntityType,
  EntityTypeComponent,
  LayerBlur,
  OpacityComponent,
  PositionComponent,
  PropertiesExpandedComponent,
  RotationComponent,
  ScaleComponent,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  ShadowSpreadComponent,
  SolidPaint,
  SpringCurveComponent,
  StrokesRelationsAspect,
  TargetRelationAspect,
  TimeComponent,
  TimingCurveComponent,
  TrimEndComponent,
  TrimStartComponent,
  enableTrimPath,
  generateId,
  getAnimatableValue,
  getEntryOrThrow,
  getInitialSize,
  getPresetId,
  getSize,
  isPresetLinked,
  isTrimPathEnabled,
  lerp,
  setAnchorPoint,
  setAnimatableValue,
  setCurveType,
  setPresetId,
} from '@aninix-inc/model'
import { Point2D, RGBA } from '@aninix-inc/model/legacy'
import { AnchorPointPosition } from '@aninix/app-design-system'
import { action, computed, makeObservable, observable } from 'mobx'
import * as R from 'ramda'
import { mapAnchorPointToPoint2D } from '../modules/properties-panel/components/nodes/anchor-point'

export const none = 'NONE'

export enum PresetType {
  In = 'IN',
  Out = 'OUT',
  Effect = 'EFFECT',
}

export enum PresetAnimationId {
  AppearanceAlignmentInBox = 'APPEARANCE_ALIGNMENT_IN_BOX',
  AppearanceAlignmentOutBox = 'APPEARANCE_ALIGNMENT_OUT_BOX',
  AppearanceLayerBlur = 'APPEARANCE_LAYER_BLUR',
  AppearanceDirection = 'APPEARANCE_DIRECTION',
  AppearanceFade = 'APPEARANCE_FADE',
  AppearanceRotation = 'APPEARANCE_ROTATION',
  AppearanceScale = 'APPEARANCE_SCALE',
  AppearanceShadow = 'APPEARANCE_SHADOW',
  AppearanceTrimPath = 'APPEARANCE_TRIM_PATH',

  EffectTranslate = 'EFFECT_TRANSLATE',
  EffectRotation = 'EFFECT_ROTATION',
  EffectScale = 'EFFECT_SCALE',
}

export enum PresetSpeedType {
  Slow = 'SLOW',
  Medium = 'MEDIUM',
  Fast = 'FAST',
}

export enum PresetTimingCurveType {
  Eager = 'EAGER',
  Gentle = 'GENTLE',
  Bounce = 'BOUNCE',
}

export enum PresetAnimationAppearanceAlignmentInBoxType {
  Top = 'TOP',
  Right = 'RIGHT',
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Center = 'CENTER',
  None = 'NONE',
}

export enum PresetAnimationAppearanceAlignmentOutBoxType {
  Top = 'TOP',
  Right = 'RIGHT',
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  None = 'NONE',
}

export enum PresetAnimationAppearanceLayerBlurType {
  Layer = 'LAYER',
  // @TODO: move to another preset
  // Background = 'BACKGROUND',
  None = 'NONE',
}

export enum PresetAnimationAppearanceDirectionType {
  Up = 'UP',
  Right = 'RIGHT',
  Down = 'DOWN',
  Left = 'LEFT',
  None = 'NONE',
}

export enum PresetAnimationAppearanceFadeType {
  Fade = 'FADE',
  None = 'NONE',
}

export enum PresetAnimationAppearanceRotationType {
  CounterClockwise = 'COUNTERCLOCKWISE',
  Clockwise = 'CLOCKWISE',
  None = 'NONE',
}

export enum PresetAnimationAppearanceScaleType {
  Vertical = 'VERTICAL',
  Horizontal = 'HORIZONTAL',
  Both = 'BOTH',
  None = 'NONE',
}

export enum PresetAnimationAppearanceShadowType {
  Drop = 'DROP',
  Inner = 'INNER',
  None = 'NONE',
}

export enum PresetAnimationAppearanceTrimPathType {
  CounterClockwise = 'COUNTERCLOCKWISE',
  Clockwise = 'CLOCKWISE',
  None = 'NONE',
}

export enum PresetAnimationEffectTranslateType {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
  None = 'NONE',
}

export enum PresetAnimationEffectRotationType {
  CounterClockwise = 'COUNTERCLOCKWISE',
  Clockwise = 'CLOCKWISE',
  None = 'NONE',
}

export enum PresetAnimationEffectScaleType {
  Both = 'BOTH',
  None = 'NONE',
}

export const PresetAnimationTypes = {
  AppearanceAlignmentInBox: PresetAnimationAppearanceAlignmentInBoxType,
  AppearanceAlignmentOutBox: PresetAnimationAppearanceAlignmentOutBoxType,
  AppearanceLayerBlur: PresetAnimationAppearanceLayerBlurType,
  AppearanceDirection: PresetAnimationAppearanceDirectionType,
  AppearanceFade: PresetAnimationAppearanceFadeType,
  AppearanceRotation: PresetAnimationAppearanceRotationType,
  AppearanceScale: PresetAnimationAppearanceScaleType,
  AppearanceShadow: PresetAnimationAppearanceShadowType,
  AppearanceTrimPath: PresetAnimationAppearanceTrimPathType,

  EffectTranslate: PresetAnimationEffectTranslateType,
  EffectRotation: PresetAnimationEffectRotationType,
  EffectScale: PresetAnimationEffectScaleType,
}

export const PresetAnimation = {
  Id: PresetAnimationId,
  Types: PresetAnimationTypes,
}

interface IPresetAnimationBase<Id, Type, Value> {
  id: Id
  type: Type

  /**
   * @description map of values in format { [progress: number]: Value }, where "progress" in range 0...1.
   * It's progress from start to end animation.
   * Value on the other side is DELTA of changes relative to start value.
   */
  values: Record<number, Value>

  /**
   * @description timing curves for provided values. Keys should be equals otherwise it throws.
   */
  timingCurves?: Record<number, [number, number, number, number]>
}

/**
 * @description Align preset works in percents related to the root node.
 * But final position sets as absolute (not relative as other presets).
 * @example We have node with size `[25, 25]`,
 * start position `[50, 50]`
 * root node with size `[100, 100]`,
 * and preset with align "TOP" and values `false -> true`,
 * then position would be animated as `[50, 50] -> [50, 75]`.
 */
interface IPresetAnimationAppearanceAlignmentInBox
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceAlignmentInBox,
    PresetAnimationAppearanceAlignmentInBoxType,
    boolean
  > {}

/**
 * @description Align preset works in percents related to the root node.
 * But final position sets as absolute (not relative as other presets).
 * @example We have node with size `[25, 25]`,
 * start position `[50, 50]`
 * root node with size `[100, 100]`,
 * and preset with align "TOP" and values `false -> true`,
 * then position would be animated as `[50, 50] -> [50, 125]`.
 */
interface IPresetAnimationAppearanceAlignmentOutBox
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceAlignmentOutBox,
    PresetAnimationAppearanceAlignmentOutBoxType,
    boolean
  > {}

/**
 * @description Blur preset works in absolute values.
 * @example We have preset with values `0 -> 50`,
 * then blur would be animated as `0 -> 50`.
 */
interface IPresetAnimationAppearanceBlur
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceLayerBlur,
    PresetAnimationAppearanceLayerBlurType,
    number
  > {}

/**
 * @description Direction preset works in percents related to current node size.
 * @example We have node with size `[25, 25]`,
 * start position `[0, 0]`
 * and preset with direction "RIGHT" and values `0 -> 1`,
 * then position would be animated as `[0, 0] -> [25, 0]`.
 */
interface IPresetAnimationAppearanceDirection
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceDirection,
    PresetAnimationAppearanceDirectionType,
    number
  > {}

/**
 * @description Fade preset works in percents related to current node opacity.
 * @example We have node with opacity `0.6`,
 * and preset has values `0 -> 1`
 * then opacity would be animated as `0 -> 0.6`
 */
interface IPresetAnimationAppearanceFade
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceFade,
    PresetAnimationAppearanceFadeType,
    number
  > {}

/**
 * @description Rotation preset works in absolute values related to initial rotation.
 * @example We have node with rotation `90`,
 * and preset has type `clockwise`, and values `0 -> 270`,
 * then rotation would be animated as `90 -> 360`.
 */
interface IPresetAnimationAppearanceRotation
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceRotation,
    PresetAnimationAppearanceRotationType,
    number
  > {}

/**
 * @description Scale preset works as `delta` in `relative values` related to initial scale.
 * @example We have node with scale `[0.5, 1]`,
 * and preset has type `horizontal`, and values `-0.5 -> 0`,
 * then scale would be animated as `[0.25, 1] -> [0.5, 1]`.
 *
 * And if preset has type `horizontal`, and values `0.5 -> 0`,
 * then scale would be animated as `[0.75, 1] -> [0.5, 1]`.
 */
interface IPresetAnimationAppearanceScale
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceScale,
    PresetAnimationAppearanceScaleType,
    number
  > {}

/**
 * @todo define API
 */
interface IPresetAnimationAppearanceShadow
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceShadow,
    PresetAnimationAppearanceShadowType,
    { radius: number; color: RGBA; offset: Point2D; spread: number }
  > {}

/**
 * @description Trim path preset works as `absolute` values.
 * @example We have node,
 * and preset has type `clockwise`, and values `0 -> 1`,
 * then trim path would be animated as start `0 -> 100%`.
 */
interface IPresetAnimationAppearanceTrimPath
  extends IPresetAnimationBase<
    PresetAnimationId.AppearanceTrimPath,
    PresetAnimationAppearanceTrimPathType,
    number
  > {}

/**
 * @description Direction preset works in percents related to current node size.
 * @example We have node with size `[25, 25]`,
 * start position `[0, 0]`
 * and preset with direction "HORIZONTAL" and values `0 -> 1`,
 * then position would be animated as `[0, 0] -> [25, 0]`.
 */
interface IPresetAnimationEffectTranslate
  extends IPresetAnimationBase<
    PresetAnimationId.EffectTranslate,
    PresetAnimationEffectTranslateType,
    number
  > {}
/**
 *
 * @description Rotation preset works in absolute values related to initial rotation.
 * @example We have node with rotation `90`,
 * and preset has type `clockwise`, and values `0 -> 270`,
 * then rotation would be animated as `90 -> 360`.
 */
interface IPresetAnimationEffectRotation
  extends IPresetAnimationBase<
    PresetAnimationId.EffectRotation,
    PresetAnimationEffectRotationType,
    number
  > {}

/**
 * @description Scale preset works as `delta` in `relative values` related to initial scale.
 * @example We have node with scale `[0.5, 1]`,
 * and preset has type `horizontal`, and values `-0.5 -> 0`,
 * then scale would be animated as `[0.25, 1] -> [0.5, 1]`.
 *
 * And if preset has type `horizontal`, and values `0.5 -> 0`,
 * then scale would be animated as `[0.75, 1] -> [0.5, 1]`.
 */
interface IPresetAnimationEffectScale
  extends IPresetAnimationBase<
    PresetAnimationId.EffectScale,
    PresetAnimationEffectScaleType,
    [number, number]
  > {}

export type PresetAnimation =
  | IPresetAnimationAppearanceAlignmentInBox
  | IPresetAnimationAppearanceAlignmentOutBox
  | IPresetAnimationAppearanceBlur
  | IPresetAnimationAppearanceDirection
  | IPresetAnimationAppearanceFade
  | IPresetAnimationAppearanceRotation
  | IPresetAnimationAppearanceScale
  | IPresetAnimationAppearanceShadow
  | IPresetAnimationAppearanceTrimPath
  | IPresetAnimationEffectTranslate
  | IPresetAnimationEffectRotation
  | IPresetAnimationEffectScale

function validatePresetAnimation(presetAnimation: PresetAnimation) {
  if (presetAnimation.timingCurves == null) {
    return
  }

  if (
    R.keys(presetAnimation.values).length !==
    R.keys(presetAnimation.timingCurves).length
  ) {
    throw new Error(
      `Number of keys in ".values" and ".timingCurves" are not equals. Preset animation with id "${presetAnimation.id}" and type "${presetAnimation.type}".`
    )
  }
}

export type PresetSnapshot = {
  id: string
  title: string
  type: PresetType
  /**
   * @default false
   */
  isPro?: boolean
  duration?: number | Record<PresetSpeedType, number>
  speed?: PresetSpeedType
  timingCurve?: PresetTimingCurveType
  baseAnimations: PresetAnimation[]
  requiredAnimations: PresetAnimation[]
  optionalAnimations: PresetAnimation[]
  isCustomizable?: boolean
  /**
   * @description time when grab the cover
   */
  coverAt?: number
}

export class Preset {
  static Type = PresetType

  static Speed = PresetSpeedType

  static TimingCurve = PresetTimingCurveType

  public id: string

  public title: string

  public isPro: boolean

  public type: PresetType

  private _duration?: number | Record<PresetSpeedType, number>

  public speed?: PresetSpeedType

  public timingCurve?: PresetTimingCurveType

  public baseAnimations: PresetAnimation[]

  public requiredAnimations: PresetAnimation[]

  public optionalAnimations: PresetAnimation[]

  public isDirty: boolean = false

  private initialSnapshot: PresetSnapshot

  private _isCustomizable?: boolean

  private _coverAt?: number

  constructor(snapshot: PresetSnapshot) {
    const presetAnimations = [
      ...snapshot.baseAnimations,
      ...snapshot.requiredAnimations,
      ...snapshot.optionalAnimations,
    ]
    for (let i = 0; i < presetAnimations.length; i += 1) {
      validatePresetAnimation(presetAnimations[i])
    }

    this.reset(snapshot)
    this.initialSnapshot = R.clone(snapshot)

    makeObservable(this, {
      id: observable,
      title: observable,
      isPro: observable,
      type: observable,
      speed: observable,
      timingCurve: observable,
      baseAnimations: observable,
      requiredAnimations: observable,
      optionalAnimations: observable,
      isDirty: observable,
      duration: computed,
      isCustomizable: computed,
      finalStateAt: computed,
      reset: action,
      updateSpeed: action,
      updateTimingCurve: action,
      updateRequiredAnimation: action,
      updateOptionalAnimation: action,
    })
  }

  get finalStateAt() {
    if (this._coverAt != null) {
      return this._coverAt
    }

    if (this.type === PresetType.Out) {
      return 0
    }

    return this.duration
  }

  get duration() {
    if (typeof this._duration === 'number') {
      return this._duration
    }

    const durations = this._duration ?? {
      [PresetSpeedType.Fast]: 0.6,
      [PresetSpeedType.Medium]: 0.8,
      [PresetSpeedType.Slow]: 1.5,
    }
    const speed = this.speed ?? PresetSpeedType.Fast

    return durations[speed]
  }

  get isCustomizable() {
    if (this._isCustomizable != null) {
      return this._isCustomizable
    }

    return (
      this.speed != null ||
      this.timingCurve != null ||
      this.requiredAnimations.length > 0 ||
      this.optionalAnimations.length > 0
    )
  }

  get timingCurveValue() {
    if (this.timingCurve === PresetTimingCurveType.Eager) {
      return {
        out: {
          x: 0.5,
          y: 0.35,
        },
        in: {
          x: 0.15,
          y: 1,
        },
      }
    }

    if (this.timingCurve === PresetTimingCurveType.Gentle) {
      return {
        out: {
          x: 0.5,
          y: 0,
        },
        in: {
          x: 0.5,
          y: 1,
        },
      }
    }

    return {
      out: {
        x: 0.5,
        y: 0.35,
      },
      in: {
        x: 0.15,
        y: 1,
      },
    }
  }

  get animations() {
    return [
      ...this.requiredAnimations,
      ...this.baseAnimations,
      ...this.optionalAnimations,
    ]
  }

  public reset = (providedSnapshot?: PresetSnapshot) => {
    const snapshot = providedSnapshot ?? this.initialSnapshot
    this.id = snapshot.id
    this.title = snapshot.title
    this.isPro = snapshot.isPro ?? false
    this.type = snapshot.type
    this._duration = snapshot.duration
    this.speed = snapshot.speed
    this.timingCurve = snapshot.timingCurve
    this.baseAnimations = snapshot.baseAnimations
    this.requiredAnimations = snapshot.requiredAnimations
    this.optionalAnimations = snapshot.optionalAnimations
    this.isDirty = false
    this._isCustomizable = snapshot.isCustomizable
    this._coverAt = snapshot.coverAt
    return this
  }

  public updateSpeed = (type: PresetSpeedType) => {
    this.speed = type
    this.isDirty = true
    return this
  }

  public updateTimingCurve = (type: PresetTimingCurveType) => {
    this.timingCurve = type
    this.isDirty = true
    return this
  }

  public updateRequiredAnimation = (payload: {
    id: PresetAnimationId
    type: string
  }) => {
    const { id, type } = payload

    const index = R.findIndex(
      (presetItem) => presetItem.id === id,
      this.requiredAnimations
    )

    if (index === -1) {
      return this
    }

    const newPresetItem = R.assoc('type', type, this.requiredAnimations[index])

    this.requiredAnimations = R.update(
      index,
      newPresetItem,
      this.requiredAnimations
    ) as any
    this.isDirty = true

    return this
  }

  public updateOptionalAnimation = (payload: {
    id: PresetAnimationId
    type: string
  }) => {
    const { id, type } = payload

    const index = R.findIndex(
      (presetItem) => presetItem.id === id,
      this.optionalAnimations
    )

    if (index === -1) {
      return this
    }

    const newPresetItem = R.assoc('type', type, this.optionalAnimations[index])

    this.optionalAnimations = R.update(
      index,
      newPresetItem,
      this.optionalAnimations
    ) as any
    this.isDirty = true

    return this
  }

  public apply = (payload: {
    nodes: Entity[]
    time: number
    preview?: boolean
  }) => {
    const { nodes, time, preview } = payload
    const presetId = generateId()
    const delay = this.duration * 0.1

    nodes.forEach((node, idx) => {
      const newTime = idx * delay + time
      const properties = this.animations.flatMap(
        (presetAnimation): Component[] => {
          if (
            presetAnimation.id === PresetAnimation.Id.AppearanceAlignmentInBox
          ) {
            return [node.getComponentOrThrow(PositionComponent)]
          }

          if (
            presetAnimation.id === PresetAnimation.Id.AppearanceAlignmentOutBox
          ) {
            return [node.getComponentOrThrow(PositionComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceDirection) {
            return [node.getComponentOrThrow(PositionComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceFade) {
            return [node.getComponentOrThrow(OpacityComponent)]
          }

          // @TODO: add blur here on apply
          if (presetAnimation.id === PresetAnimation.Id.AppearanceLayerBlur) {
            const effects = node.getAspectOrThrow(EffectsRelationsAspect)
            const blursCount = effects
              .getChildrenList()
              .filter(
                (effect) =>
                  effect.getComponentOrThrow(EffectTypeComponent).value ===
                  EffectType.LayerBlur
              ).length

            // @NOTE: required to properly create required components before return them
            if (blursCount === 0) {
              const blur = node.getProjectOrThrow().createEntity(LayerBlur)
              blur.updateComponent(BlurRadiusComponent, 0)
              effects.addChild(blur)
            }

            return node
              .getAspectOrThrow(EffectsRelationsAspect)
              .getChildrenList()
              .filter(
                (effect) =>
                  effect.getComponentOrThrow(EffectTypeComponent).value ===
                  EffectType.LayerBlur
              )
              .map((blur) => blur.getComponentOrThrow(BlurRadiusComponent))
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceRotation) {
            return [node.getComponentOrThrow(RotationComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceScale) {
            return [node.getComponentOrThrow(ScaleComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceShadow) {
            const effects = node.getAspectOrThrow(EffectsRelationsAspect)
            const shadowsCount = effects
              .getChildrenList()
              .filter(
                (effect) =>
                  effect.getComponentOrThrow(EffectTypeComponent).value ===
                  EffectType.LayerBlur
              ).length

            // @NOTE: required to properly create required components before return them
            if (shadowsCount === 0) {
              effects.addChild(
                node.getProjectOrThrow().createEntity(DropShadow)
              )
            }

            return effects
              .getChildrenList()
              .filter(
                (effect) =>
                  effect.getComponentOrThrow(EffectTypeComponent).value ===
                  EffectType.DropShadow
              )
              .flatMap((shadow) => [
                shadow.getComponentOrThrow(ShadowOffsetComponent),
                shadow.getComponentOrThrow(ShadowRadiusComponent),
                shadow.getComponentOrThrow(ShadowColorComponent),
                shadow.getComponentOrThrow(ShadowSpreadComponent),
              ])
          }

          if (presetAnimation.id === PresetAnimation.Id.AppearanceTrimPath) {
            // @NOTE: required to properly create required components before return them
            if (isTrimPathEnabled(node) === false) {
              enableTrimPath(node)
            }

            return [
              node.getComponentOrThrow(TrimStartComponent),
              node.getComponentOrThrow(TrimEndComponent),
            ]
          }

          if (presetAnimation.id === PresetAnimation.Id.EffectRotation) {
            return [node.getComponentOrThrow(RotationComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.EffectScale) {
            return [node.getComponentOrThrow(ScaleComponent)]
          }

          if (presetAnimation.id === PresetAnimation.Id.EffectTranslate) {
            return [node.getComponentOrThrow(PositionComponent)]
          }

          return []
        }
      )

      this.clearKeyframes({
        time: newTime,
        properties,
        removeAll: preview,
      })

      this.animations.forEach(
        this.applyPresetAnimation({
          node,
          time: newTime,
          presetId,
          preview,
        })
      )
    })

    return this
  }

  static fromSnapshot = (snapshot: PresetSnapshot) => {
    const preset = new Preset(snapshot)
    return preset
  }

  toSnapshot = (): PresetSnapshot => ({
    id: this.id,
    title: this.title,
    type: this.type,
    duration: this.duration,
    speed: this.speed,
    timingCurve: this.timingCurve,
    baseAnimations: this.baseAnimations,
    requiredAnimations: this.requiredAnimations,
    optionalAnimations: this.optionalAnimations,
    isCustomizable: this.isCustomizable,
    coverAt: this._coverAt,
  })

  /**
   * @description clear all keyframes at selected time to prevent mutations
   */
  private clearKeyframes = (payload: {
    time: number
    properties: Component[]
    removeAll?: boolean
  }): this => {
    const { time, properties, removeAll = false } = payload
    const start = time
    const end = start + this.duration

    if (payload.properties.length === 0) {
      return this
    }

    const propertyIds = properties.map((property) => property.id)
    const project = properties[0].entity.getProjectOrThrow()
    const keyframes = project.getEntitiesByPredicate(
      (entity) =>
        entity.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.Keyframe &&
        propertyIds.includes(
          entity.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow()
        )
    )

    if (removeAll) {
      keyframes.forEach((keyframe) => project.removeEntity(keyframe.id))
      return this
    }

    const intersectedKeyframes = keyframes.filter(
      (keyframe) =>
        start <= keyframe.getComponentOrThrow(TimeComponent).value &&
        keyframe.getComponentOrThrow(TimeComponent).value <= end
    )
    const presetIds = R.uniq(
      intersectedKeyframes
        .filter((keyframe) => isPresetLinked(keyframe))
        .map((keyframe) => getPresetId(keyframe))
    )
    const keyframesWithLinkedPresets = presetIds.flatMap((presetId) => {
      return keyframes.filter(
        (keyframe) =>
          isPresetLinked(keyframe) && getPresetId(keyframe) === presetId
      )
    })
    const uniqueKeyframes = R.uniqBy(
      (keyframe) => keyframe.id,
      [...intersectedKeyframes, ...keyframesWithLinkedPresets]
    )

    uniqueKeyframes.forEach((keyframe) => {
      project.removeEntity(keyframe.id)
    })

    return this
  }

  private applyTransitionToProperty<T>(payload: {
    property: Component
    presetId: string
    presetAnimation: PresetAnimation
    time: number
    valueStrategy: (value: T) => void
  }) {
    const { property, presetId, presetAnimation, time, valueStrategy } = payload

    const startTime = time
    const endTime = startTime + this.duration

    R.keys(presetAnimation.values).forEach((time) => {
      const value = presetAnimation.values[time] as unknown as T
      const providedTimingCurve =
        presetAnimation.timingCurves && presetAnimation.timingCurves[time]
      const timingCurve = providedTimingCurve
        ? {
            out: {
              x: providedTimingCurve[0],
              y: providedTimingCurve[1],
            },
            in: {
              x: providedTimingCurve[2],
              y: providedTimingCurve[3],
            },
          }
        : this.timingCurveValue

      const t = lerp(time, startTime, endTime)
      const keyframe = setAnimatableValue(
        property,
        valueStrategy(value),
        t,
        true
      )
      setPresetId(keyframe, presetId)

      if (this.timingCurve === PresetTimingCurveType.Bounce) {
        setCurveType(keyframe, CurveType.Spring)
        keyframe.updateComponent(SpringCurveComponent, {
          stiffness: 100,
          damping: 10,
          mass: 1,
        })
      } else {
        setCurveType(keyframe, CurveType.Timing)
        keyframe.updateComponent(TimingCurveComponent, timingCurve)
      }
      property.entity.updateComponent(PropertiesExpandedComponent, true)
    })

    return this
  }

  // @TODO: refactor by strategy pattern here
  private applyPresetAnimation =
    (payload: {
      node: Entity
      time: number
      presetId: string
      preview?: boolean
    }) =>
    (presetAnimation: PresetAnimation) => {
      const { node, time, presetId, preview = false } = payload

      if (R.values(presetAnimation.values).length < 2) {
        throw new Error(
          `Preset animation with id "${presetAnimation.id}" with type "${presetAnimation.type}" has less than 2 values. Animation cannot be created.`
        )
      }

      if (presetAnimation.type === none) {
        // @NOTE: cleanup presets for preview
        if (preview === true) {
          // @TODO: move to separated method
          // @NOTE: cleanup blur
          const blurGroup = node
            .getAspectOrThrow(EffectsRelationsAspect)
            .getChildrenList()
            .find(
              (effect) =>
                effect.getComponentOrThrow(EffectTypeComponent).value ===
                EffectType.LayerBlur
            )

          if (blurGroup != null) {
            node
              .getAspectOrThrow(EffectsRelationsAspect)
              .removeChild(blurGroup.id)
          }
        }

        return
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceAlignmentInBox) {
        const property = node.getComponentOrThrow(PositionComponent)
        const entry = getEntryOrThrow(node.getProjectOrThrow())
        const rootSize = getSize(entry)
        const size = getSize(node)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentInBox.Top
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: value ? 0 : baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentInBox.Right
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: value ? rootSize.x - size.x : baseValue.x,
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentInBox.Bottom
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: value ? rootSize.y - size.y : baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentInBox.Left
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: value ? 0 : baseValue.x,
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentInBox.Center
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: value ? rootSize.x / 2 - size.x / 2 : baseValue.x,
              y: value ? rootSize.y / 2 - size.y / 2 : baseValue.y,
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceAlignmentOutBox) {
        const property = node.getComponentOrThrow(PositionComponent)
        const entry = getEntryOrThrow(node.getProjectOrThrow())
        const rootSize = getInitialSize(entry)
        const size = getSize(node)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentOutBox.Top
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: value ? -size.y : baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentOutBox.Right
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: value ? rootSize.x + size.x : baseValue.x,
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentOutBox.Bottom
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: value ? rootSize.y + size.y : baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceAlignmentOutBox.Left
        ) {
          this.applyTransitionToProperty<Point2D>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: value ? -size.x : baseValue.x,
              y: baseValue.y,
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceDirection) {
        const property = node.getComponentOrThrow(PositionComponent)
        const nodeSize = getSize(node)
        const baseValue = getAnimatableValue(property, time)

        // @NOTE: this is required for line layers which can has 1 in width or height.
        // In this way we make sure we will have animation of such layers.
        const size = {
          x: nodeSize.x <= 1 ? nodeSize.y : nodeSize.x,
          y: nodeSize.y <= 1 ? nodeSize.x : nodeSize.y,
        }

        if (
          presetAnimation.type === PresetAnimation.Types.AppearanceDirection.Up
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: baseValue.y + value * size.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceDirection.Right
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x - value * size.x,
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceDirection.Down
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: baseValue.y - value * size.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceDirection.Left
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x + value * size.x,
              y: baseValue.y,
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceLayerBlur) {
        const blur = node
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()
          .find(
            (effect) =>
              effect.getComponentOrThrow(EffectTypeComponent).value ===
              EffectType.LayerBlur
          )

        if (blur == null) {
          console.warn(`Layer blur is not found at node with id "${node.id}"`)
          return
        }

        const baseValue = getAnimatableValue(
          blur.getComponentOrThrow(BlurRadiusComponent),
          time
        )
        this.applyTransitionToProperty<number>({
          property: blur.getComponentOrThrow(BlurRadiusComponent),
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => value * getSize(node).x + baseValue,
        })
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceShadow) {
        const shadow = node
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()
          .find(
            (effect) =>
              effect.getComponentOrThrow(EffectTypeComponent).value ===
              EffectType.DropShadow
          )

        if (shadow == null) {
          console.warn(`Drop shadow is not found at node with id "${node.id}"`)
          return
        }

        this.applyTransitionToProperty<{
          color: RGBA
          offset: Point2D
          radius: number
        }>({
          property: shadow.getComponentOrThrow(ShadowRadiusComponent),
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => value.radius,
        })
        this.applyTransitionToProperty<{
          color: RGBA
          offset: Point2D
          radius: number
        }>({
          property: shadow.getComponentOrThrow(ShadowOffsetComponent),
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => value.offset,
        })
        this.applyTransitionToProperty<{
          color: RGBA
          offset: Point2D
          radius: number
        }>({
          property: shadow.getComponentOrThrow(ShadowColorComponent),
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => value.color,
        })
      }

      if (presetAnimation.id === PresetAnimation.Id.AppearanceFade) {
        const property = node.getComponentOrThrow(OpacityComponent)
        const baseValue = getAnimatableValue(property, time)

        this.applyTransitionToProperty<number>({
          property,
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => baseValue + baseValue * value,
        })
      }

      if (presetAnimation.id === PresetAnimationId.AppearanceRotation) {
        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )
        const property = node.getComponentOrThrow(RotationComponent)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceRotation.Clockwise
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => -(baseValue + value),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceRotation.CounterClockwise
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => -(baseValue - value),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimationId.AppearanceScale) {
        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )
        const property = node.getComponentOrThrow(ScaleComponent)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type === PresetAnimation.Types.AppearanceScale.Both
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x * (1 + value),
              y: baseValue.y * (1 + value),
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceScale.Horizontal
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x * (1 + value),
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceScale.Vertical
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: baseValue.y * (1 + value),
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimationId.AppearanceTrimPath) {
        // @NOTE: handle the issue with groups. Related to ANI-2158.
        if (!node.hasAspect(StrokesRelationsAspect)) {
          return this
        }

        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )

        const strokes = node.getAspectOrThrow(StrokesRelationsAspect)
        if (strokes.getChildrenList().length === 0) {
          strokes.addChild(node.getProjectOrThrow().createEntity(SolidPaint))
        }

        if (this.type === PresetType.Out) {
          if (
            presetAnimation.type ===
            PresetAnimation.Types.AppearanceTrimPath.Clockwise
          ) {
            this.applyTransitionToProperty<number>({
              property: node.getComponentOrThrow(TrimStartComponent),
              presetId,
              presetAnimation,
              time,
              valueStrategy: (value) => 1 - value,
            })
            return
          }

          this.applyTransitionToProperty<number>({
            property: node.getComponentOrThrow(TrimEndComponent),
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => value,
          })

          return
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.AppearanceTrimPath.CounterClockwise
        ) {
          this.applyTransitionToProperty<number>({
            property: node.getComponentOrThrow(TrimStartComponent),
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => 1 - value,
          })
          return
        }

        this.applyTransitionToProperty<number>({
          property: node.getComponentOrThrow(TrimEndComponent),
          presetId,
          presetAnimation,
          time,
          valueStrategy: (value) => value,
        })

        return this
      }

      if (presetAnimation.id === PresetAnimationId.EffectTranslate) {
        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )
        const property = node.getComponentOrThrow(PositionComponent)
        const size = getSize(node)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type ===
          PresetAnimation.Types.EffectTranslate.Horizontal
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x + size.x * value,
              y: baseValue.y,
            }),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.EffectTranslate.Vertical
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: baseValue.x,
              y: baseValue.y + size.y * value,
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimationId.EffectRotation) {
        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )
        const property = node.getComponentOrThrow(RotationComponent)
        const baseValue = getAnimatableValue(property, time)

        if (
          presetAnimation.type ===
          PresetAnimation.Types.EffectRotation.Clockwise
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => -(baseValue + value),
          })
        }

        if (
          presetAnimation.type ===
          PresetAnimation.Types.EffectRotation.CounterClockwise
        ) {
          this.applyTransitionToProperty<number>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: (value) => ({
              x: -(baseValue - value),
            }),
          })
        }

        return this
      }

      if (presetAnimation.id === PresetAnimationId.EffectScale) {
        setAnchorPoint(
          node,
          mapAnchorPointToPoint2D(AnchorPointPosition.Center, getSize(node))
        )
        const property = node.getComponentOrThrow(ScaleComponent)
        const baseValue = getAnimatableValue(property, time)

        if (presetAnimation.type === PresetAnimation.Types.EffectScale.Both) {
          this.applyTransitionToProperty<[number, number]>({
            property,
            presetId,
            presetAnimation,
            time,
            valueStrategy: ([x, y]) => ({
              x: baseValue.x * (1 + x),
              y: baseValue.y * (1 + y),
            }),
          })
        }

        return this
      }

      return this
    }
}
