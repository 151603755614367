import {
  Component,
  DurationComponent,
  Entity,
  EntityType,
  EntityTypeComponent,
  NodeColorComponent,
  ParentRelationAspect,
  Root,
  SelectionSystem,
  getAnimatableValue,
  getDuration,
  getKeyframeValueComponent,
  getNode,
  getSortedKeyframes,
  getStartTime,
  isSegment,
} from '@aninix-inc/model'
import { TimelineTrack, useMouseMove } from '@aninix/app-design-system'
import { useComponent, useSystem } from '@aninix/core/updates'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import * as timeConverters from '../../../../helpers/timeConverters'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import * as styles from './index.scss'
import { Keyframe } from './keyframe'
import { Segment } from './segment'

const handlerWidth = 6

export interface IProps {
  component: Component
  parentTrackWidth: number
}
export const Property: React.FCC<IProps> = ({
  component,
  parentTrackWidth,
}) => {
  useComponent(component)
  const containerRef = React.useRef<HTMLDivElement>()
  // @NOTE: it can resolve not to node, but also to effect, paint, color stop etc
  const layer = getNode(component)

  if (layer === undefined) {
    throw new Error('Invalid state. Node is not found')
  }

  const project = layer.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isSelfSelectedValue = isComponentSelected(component)

  const id = component.id
  const keyframes = getSortedKeyframes(component)
  const startTime =
    layer.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node
      ? // @NOTE: in case of selected layer
        getStartTime(layer)
      : // @NOTE: in case of property group (effect or paint)
        getStartTime(
          layer.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow()
        )
  const duration =
    layer.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node
      ? // @NOTE: in case of selected layer
        getDuration(layer)
      : // @NOTE: in case of property group (effect or paint)
        getDuration(
          layer.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow()
        )
  const projectDuration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const { isListening, startListen } = useMouseMove()

  const dynamicSegments = R.pipe(
    (keys: Entity[]) => R.aperture(2, keys),
    R.filter(([left, right]: [Entity, Entity]) =>
      isSegment(left, right, (l, r) =>
        R.equals(
          getAnimatableValue(getKeyframeValueComponent(l) as Component),
          getAnimatableValue(getKeyframeValueComponent(r) as Component)
        )
      )
    )
  )(keyframes)

  const isListeningSegments = React.useMemo(
    () => dynamicSegments,
    [isListening]
  )

  const segments = isListening ? isListeningSegments : dynamicSegments

  const convertTimeToPixels = React.useCallback(
    (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        time,
      }),
    [projectDuration, parentTrackWidth]
  )

  const left = React.useMemo(
    () => convertTimeToPixels(startTime) + handlerWidth,
    [startTime, convertTimeToPixels]
  )
  const width = React.useMemo(
    () => convertTimeToPixels(duration),
    [duration, convertTimeToPixels]
  )

  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue || isLayerSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    if (isSelfSelectedValue) {
      // return `${
      //   defaults.nodeColors[
      //     layer.getComponentOrThrow(NodeColorComponent).value
      //   ]
      // }35`
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isLayerSelectedValue,
    isSelfSelectedValue,
  ])

  return (
    <TimelineTrack
      className={styles.container}
      // @NOTE: isSelected=false because we change color by the --bg-color of div below
      isSelected={false}
      variant="property"
      ref={containerRef}
    >
      <div
        data-id={id}
        data-model-type="property"
        className={styles.background}
        style={{
          left,
          width,
          // @ts-ignore
          '--bg-color': color,
        }}
      />

      {segments.map((segment) => (
        <Segment
          key={`${segment[0].id}-${segment[1].id}`}
          leftKeyframe={segment[0]}
          rightKeyframe={segment[1]}
          parentTrackWidth={parentTrackWidth}
          onDrag={startListen}
        />
      ))}

      {keyframes.map((keyframe) => (
        <Keyframe
          key={keyframe.id}
          keyframe={keyframe}
          parentTrackWidth={parentTrackWidth}
        />
      ))}
    </TimelineTrack>
  )
}

Property.displayName = 'Property'
