import { Typography } from 'apps/web-app/src/components/design-system/typography'
import * as React from 'react'
import { ResetIcon } from '../../../../icons/ResetIcon'
// import { ColorType, useOverrides } from '../../../use-overrides'
import { NameComponent, RgbaValueComponent, getNode } from '@aninix-inc/model'
import { useComponent } from '@aninix/core/updates'
import classNames from 'classnames'
import { useOverride } from '../../use-override-item'
import { ColorInfo } from './color-info'

export interface IProps {
  component: RgbaValueComponent
}
export const Fill: React.FCC<IProps> = ({ component }) => {
  const { isEntityChanged, resetEntity } = useOverride()

  useComponent(component)

  console.log(component.constructor.name, getNode(component)?.getSnapshot())

  const entitiyName = getNode(component)?.getComponent(NameComponent)?.value

  const isChanged = isEntityChanged(component.id)

  const handleReset = () => {
    resetEntity(component.id)
  }

  return (
    <div className="flex flex-row w-full justify-between px-2 items-center">
      <div className="w-[80px] py-1.5">
        <Typography
          className="text-gray-500 line-clamp-1"
          style="Body5Regular"
          text={entitiyName}
        />
      </div>
      <div className="min-w-[148px] flex flex-row justify-between items-center">
        <ColorInfo key={component.id} id={component.id} component={component} />
      </div>
      <button
        onClick={handleReset}
        className={classNames('', {
          ['opacity-30 pointer-events-none']: !isChanged,
        })}
      >
        <ResetIcon />
      </button>
    </div>
  )
}

Fill.displayName = 'Fill'
