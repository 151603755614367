import {
  CornerRadiusComponent,
  Entity,
  NumberKeyframe,
  getValueNumber,
  isIndividualCornerRadiusAvailable,
  isIndividualCornerRadiusEnabled,
  round,
  toggleIndividualCornerRadius,
} from '@aninix-inc/model'
import { CompactPropertyRow, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 12,
  y: 12,
}

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const CornerRadius: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const properties = layers.map((l) =>
    l.getComponentOrThrow(CornerRadiusComponent)
  )
  const layersWithAvailableIndividualCorners = layers.filter((l) =>
    isIndividualCornerRadiusAvailable(l)
  )

  const individualCornersAllowed =
    layersWithAvailableIndividualCorners.length > 0
  const individualCornersEnabled =
    individualCornersAllowed &&
    R.all(
      (l) => isIndividualCornerRadiusEnabled(l),
      layersWithAvailableIndividualCorners
    )

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={properties}
            time={time}
            icon={<icons.propertiesPanel.CornerRadius size={iconSize} />}
            min={0}
            dragDisabled={individualCornersEnabled}
            disabled={individualCornersEnabled}
            format={(v) => `${round(v, { fixed: 2 })}`}
          />
        </div>
      }
      rightColumn={
        <div className="flex flex-row">
          {individualCornersAllowed && (
            <buttons.Icon
              onClick={() =>
                layers
                  .filter((l) => isIndividualCornerRadiusAvailable(l))
                  .forEach((l) => toggleIndividualCornerRadius(l))
              }
              btnSize={btnSize}
              active={individualCornersEnabled}
              tooltip="Independent corners"
            >
              <icons.propertiesPanel.IndividualCorners size={iconSize} />
            </buttons.Icon>
          )}
          <KeyframesPropertyControl
            components={properties}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        </div>
      }
    />
  )
}
