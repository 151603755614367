import {
  Project,
  Root,
  getDuration,
  getStartTime,
  setDuration,
} from '@aninix-inc/model'
import { defaultFigmaLikeFormat } from '@aninix/app-design-system/utils/figma-like-number-format'
import {
  TimeFormat,
  nodeColors,
  usePlayback,
  useSettings,
  useTimeline,
} from '@aninix/core'
import { MAX_DURATION, MIN_DURATION } from '@aninix/editor/defaults'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  useFormatTime,
  useFormattedTime,
} from '../../../properties-panel/components/formatted-time'
import { useThreshold } from '../../../properties-panel/components/threshold'
import { NumericValue } from '../property-values/numeric-value'
import { TimeFormatSwitcher } from '../time-format-switcher'
import * as styles from './index.scss'

const noop = () => {}

export interface IProps {
  project: Project
}
export const TimeControlIndicator: React.FCC<IProps> = observer(
  ({ project }) => {
    const playback = usePlayback()
    const timeline = useTimeline()

    const threshold = useThreshold()
    const { timeFormat } = useSettings()
    const { value, suffix } = useFormattedTime(playback.time)
    const { toFormat, toSeconds } = useFormatTime()
    const root = project.getEntityByTypeOrThrow(Root)
    const currentStartTime = toFormat(getStartTime(root))

    const updateTime = React.useCallback(
      (time: number) => {
        if (time > getDuration(root)) {
          setDuration(root, time)
          timeline.reset()
          playback.reset()
        }
        playback.updateTime(time)
      },
      [root, playback, timeline]
    )

    const handleChange = React.useCallback(
      (newValue: number) => {
        updateTime(toSeconds(newValue))
      },
      [updateTime, toSeconds]
    )

    return (
      <div className={styles.container}>
        <NumericValue
          value={value as number}
          onChange={handleChange}
          onEndChange={noop}
          threshold={threshold}
          suffix={`\u00a0${suffix}`}
          min={MIN_DURATION}
          max={MAX_DURATION}
          fullHeight
          textColor={nodeColors.BLUE}
          format={
            timeFormat === TimeFormat.Seconds
              ? (val) => val.toFixed(2)
              : defaultFigmaLikeFormat
          }
        />

        <div className={styles.icon}>
          <TimeFormatSwitcher />
        </div>
      </div>
    )
  }
)

TimeControlIndicator.displayName = 'TimeControlIndicator'
