import * as React from 'react'

import { Hidden } from './Hidden'
import { Visible as VisibleIcon } from './Visible'

const defaultSize = {
  x: 20,
  y: 20,
}

export enum VisibilityType {
  Visible = 'VISIBLE',
  Hidden = 'HIDDEN',
}

export interface IProps {
  type: VisibilityType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Visible: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (VisibilityType.Visible === type) {
    return <VisibleIcon size={size} style={style} />
  }

  if (VisibilityType.Hidden === type) {
    return <Hidden size={size} style={style} />
  }

  return null
}
