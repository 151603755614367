import {
  ColorStopsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  HashComponent,
  MaskComponent,
  PaintType,
  PaintTypeComponent,
  RgbaValueComponent,
  StrokesRelationsAspect,
  getAnimatableValue,
} from '@aninix-inc/model'
import { PropertyRowV2 } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { Paint } from '../fills'

export interface IProps {
  layers: Entity[]
  time: number
}
/**
 * @todo add color grouping
 */
export const ColorSelection: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const paints = layers
    .filter((layer) => !layer.getComponent(MaskComponent)?.value)
    .flatMap((l) => [
      ...l.getAspectOrThrow(FillsRelationsAspect).getChildrenList(),
      ...l.getAspectOrThrow(StrokesRelationsAspect).getChildrenList(),
    ])
  const groupedPaints = R.collectBy((paint) => {
    const type = paint.getComponentOrThrow(PaintTypeComponent).value

    switch (type) {
      case PaintType.Solid: {
        // @TODO: use getRgba or getColor function
        const value = getAnimatableValue(
          paint.getComponentOrThrow(RgbaValueComponent),
          time
        )
        return `${type}-${JSON.stringify(value)}`
      }

      case PaintType.GradientLinear:
      case PaintType.GradientRadial:
        return `${type}-${paint
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
          .map((colorStop) => {
            const value = getAnimatableValue(
              colorStop.getComponentOrThrow(RgbaValueComponent),
              time
            )
            return JSON.stringify(value)
          })
          .join('-')}`

      case PaintType.Image:
        return `${type}-${paint.getComponentOrThrow(HashComponent).value}`

      default: {
        const never: never = type
        return never
      }
    }
  }, paints)

  if (layers.length <= 1 || paints.length === 0) {
    return null
  }

  return (
    <PropertyRowV2
      name="Selection colors"
      inputs={
        paints.length === 0 ? undefined : (
          <div className="flex w-full flex-col gap-[8px]">
            {groupedPaints.map((paints) => (
              <Paint key={paints[0].id} paints={paints} time={time} />
            ))}
          </div>
        )
      }
      empty={paints.length === 0}
    />
  )
}
