import {
  Entity,
  Point2dKeyframe,
  SkewComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { Point2dValue } from '../../values/point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export interface IProps {
  layers: Entity[]
  time: number
}
export const Skew: React.FCC<IProps> = ({ layers, time }) => {
  const components = layers.map((l) => l.getComponentOrThrow(SkewComponent))

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <Point2dValue
            components={components}
            time={time}
            iconX={<icons.propertiesPanel.SkewX />}
            iconY={<icons.propertiesPanel.SkewY />}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}
