import {
  Entity,
  NumberKeyframe,
  StrokeAlign,
  StrokeAlignComponent,
  StrokeWeightComponent,
  getValueNumber,
  isIndividualCornerRadiusAvailable,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
  mixed,
  round,
  toggleIndividualStrokes,
} from '@aninix-inc/model'
import { Select, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const smallIconSize = {
  x: 12,
  y: 12,
}

const iconSize = {
  x: 32,
  y: 32,
}

const btnSize = {
  width: 32,
  height: 32,
}

const strokeAlignOptions = [
  {
    id: StrokeAlign.Center,
    title: 'Center',
  },
  {
    id: StrokeAlign.Inside,
    title: 'Inside',
  },
  {
    id: StrokeAlign.Outside,
    title: 'Outside',
  },
]

export interface IProps {
  layers: Entity[]
  time: number
}
export const StrokeWeight: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const strokeWeightComponents = layers.map((l) =>
    l.getComponentOrThrow(StrokeWeightComponent)
  )
  const strokeAlignComponents = layers.map((l) =>
    l.getComponentOrThrow(StrokeAlignComponent)
  )
  const strokeAlign = R.all(
    (c) => c.value === strokeAlignComponents[0].value,
    strokeAlignComponents
  )
    ? strokeAlignComponents[0].value
    : mixed
  const individualStrokesEnabled = R.all(
    (l) =>
      isIndividualCornerRadiusAvailable(l) && isIndividualStrokesEnabled(l),
    layers
  )

  return (
    <div className="flex w-full flex-row justify-between pl-[6px]">
      <Select
        activeValueId={strokeAlign}
        options={strokeAlignOptions}
        onChange={(newStrokeAlign) =>
          layers.forEach((l) =>
            l.updateComponent(
              StrokeAlignComponent,
              newStrokeAlign as StrokeAlign
            )
          )
        }
        className="w-[96px]"
      />

      <NumberValue
        components={strokeWeightComponents}
        time={time}
        icon={<icons.StrokeWeight size={iconSize} />}
        min={0}
        width={96}
        disabled={individualStrokesEnabled}
        dragDisabled={individualStrokesEnabled}
        format={(v) => `${round(v, { fixed: 2 })}`}
      />

      {isIndividualStrokesAvailable(layers[0]) && (
        <buttons.Icon
          onClick={() => {
            layers.forEach((l) => toggleIndividualStrokes(l))
          }}
          btnSize={btnSize}
          active={individualStrokesEnabled}
          tooltip="Strokes per side"
        >
          <icons.propertiesPanel.IndividualStrokes size={smallIconSize} />
        </buttons.Icon>
      )}

      <KeyframesPropertyControl
        components={strokeWeightComponents}
        isDisabled={individualStrokesEnabled}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  )
}

StrokeWeight.displayName = 'StrokeWeight'
