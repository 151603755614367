import { Component, Project, setAnimatableValue } from '@aninix-inc/model'
import { ApiPatchParams, httpClient } from '@aninix/api'
import { useProject } from '@aninix/core/stores'
import { useReloadOnAnyUpdate } from '@aninix/core/updates'
import { toast } from 'apps/web-app/src/modules/toasts'
import * as React from 'react'
import { applyMatchingEntitiesValues } from '../../overrides-export/utils/apply-matching-entities-values'
import { getDiffSnapshot } from '../../overrides-export/utils/make-diff-snapshot'
import { EntityType, OverrideWithProject, useOverrides } from '../use-overrides'

const OverrideContext = React.createContext<
  | {
      data: OverrideWithProject
      isOverrideOpened: boolean
      openOverride: () => void
      closeOverride: () => void
      openedPaneles: OpenedPanels
      updateOpenedPanels: (updatedValue: Partial<OpenedPanels>) => void
      updateEntity: (component: Component<unknown>, value: any) => void
      isEntityChanged: (id: string) => boolean
      resetEntity: (entityId: string) => void
      resetAllChanges: () => void

      deleteOverride: () => void
      duplicateOverride: () => void

      isSyncedStatus: boolean
    }
  | undefined
>(undefined)

interface OverrideProps {
  data: OverrideWithProject
}

interface OpenedPanels {
  colors: boolean
  medias: boolean
  texts: boolean
}

const DEBOUNCE_TIMEOUT = 2000
//@TODO: refactor to handle types of panel from data dynamically. handle open/close via loops and open state via ids.
export const OverrideProvider: React.FCC<OverrideProps> = ({
  data,
  children,
}) => {
  const project = useProject()

  const {
    createOverride,
    deleteOverride: deleteOverrideApi,
    data: overrides,
  } = useOverrides()

  const [openedPaneles, setOpenedPanels] = React.useState<OpenedPanels>({
    colors: true,
    medias: true,
    texts: true,
  })

  useReloadOnAnyUpdate(data.project)

  const isOverrideOpened = React.useMemo(() => {
    const values = Object.values(openedPaneles)
    return values.length > 0 && values.some((v) => v === true)
  }, [openedPaneles])

  const openOverride = () =>
    setOpenedPanels({
      colors: true,
      medias: true,
      texts: true,
    })

  const closeOverride = () =>
    setOpenedPanels({
      colors: false,
      medias: false,
      texts: false,
    })

  const updateOpenedPanels = (updatedValue: Partial<OpenedPanels>) =>
    setOpenedPanels((v) => {
      return { ...v, ...updatedValue }
    })

  const [changedEntitiesMap, setChangedEntitiesMap] = React.useState<
    Record<EntityType['id'], EntityType['value']>
  >({})

  const debounceUpdateTimeout = React.useRef<NodeJS.Timeout>()

  const [isSyncedStatus, setIsSyncedStatus] = React.useState(true)

  React.useEffect(() => {
    window.onbeforeunload = isSyncedStatus ? null : () => ''

    if (!isSyncedStatus) {
      document.title = document.title + '⠀(Saving)'
    } else {
      document.title = document.title.replace('⠀(Saving)', '')
    }
  }, [isSyncedStatus])

  const updateEntity = (component: Component<unknown>, value: any) => {
    const previousValue = component.value

    applyValue({
      setChangedEntitiesMap,
      value,
      component,
      project,
      sourceOfChanges: data.project,
    })

    let isSynced = false
    setIsSyncedStatus(false)

    clearTimeout(debounceUpdateTimeout.current)
    // @ts-ignore
    debounceUpdateTimeout.current = setTimeout(() => {
      const snapshot = getDiffSnapshot({
        originalProject: overrides[0].project,
        overridenProject: data.project,
      })

      httpClient
        .patch<
          ApiPatchParams<'/v2/projects/{projectId}/overrides/{overrideId}'>
        >(`/v2/projects/${data.projectId}/overrides/${data.id}`, {
          snapshot,
        })
        .then((response) => {
          if (response.status === 202) {
            isSynced = true
          }
        })
        .catch(() => {
          toast(`${data.name} changes couldn't be saved`, {
            variant: 'error',
          })
        })
        .finally(() => {
          if (!isSynced) {
            applyValue({
              setChangedEntitiesMap,
              value: previousValue,
              component,
              project,
              sourceOfChanges: data.project,
            })
          }

          setIsSyncedStatus(true)
        })
    }, DEBOUNCE_TIMEOUT)
  }

  const resetEntity = (componentId: string) => {
    const filteredEntitiesMap: typeof changedEntitiesMap = {
      ...changedEntitiesMap,
    }
    filteredEntitiesMap[componentId] = undefined

    const [entityId, tag] = componentId.split('/')

    const overrideComponent = data.project
      .getEntity(entityId)!
      .components.find((c) => c.id.split('/')[1] === tag)!

    const initialComponent = data
      .retrieveProjectFromInitialData()
      .getEntity(entityId)!
      .components.find((c) => c.id.split('/')[1] === tag)!

    updateEntity(overrideComponent, initialComponent.value)

    setChangedEntitiesMap(filteredEntitiesMap)
  }

  const resetAllChanges = () => {
    const ids = Object.entries(changedEntitiesMap)
      .filter((e) => e[1] !== undefined)
      .map((e) => e[0])

    ids.forEach((id) => {
      resetEntity(id)
    })

    const cleanMap = ids.reduce((acc: any, id) => {
      acc[id] = undefined
      return acc
    }, {})

    setChangedEntitiesMap(cleanMap)
  }

  const isEntityChanged = (id: string) => changedEntitiesMap[id] !== undefined

  const deleteOverride = () => {
    deleteOverrideApi(data.id)
  }

  const duplicateOverride = () => {
    createOverride(data.id)
  }

  return (
    <OverrideContext.Provider
      value={{
        data,
        isOverrideOpened,
        openOverride,
        closeOverride,
        openedPaneles,
        updateOpenedPanels,
        updateEntity,
        isEntityChanged,
        resetEntity,
        resetAllChanges,

        deleteOverride,
        duplicateOverride,

        isSyncedStatus,
      }}
    >
      {children}
    </OverrideContext.Provider>
  )
}

export const useOverride = () => {
  const context = React.useContext(OverrideContext)

  if (!context) {
    throw new Error('useOverride must be used within a OverrideProvider')
  }

  return context
}

const applyValue = ({
  setChangedEntitiesMap,
  value,
  component,
  project,
  sourceOfChanges,
}: {
  setChangedEntitiesMap: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >

  value: any
  component: Component<unknown, unknown>
  project: Project
  sourceOfChanges: Project
}) => {
  const { id } = component

  setChangedEntitiesMap((v) => {
    return { ...v, ...{ [id]: value } }
  })

  setAnimatableValue(component, value)

  applyMatchingEntitiesValues({
    project,
    sourceOfChanges,
  })
}
