import * as React from 'react'
import type { OnScrollParams } from 'react-virtualized'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import * as styles from './styles.scss'

interface IVirtualizedListPops {
  data: unknown[]
  renderRow: (payload: {
    value: unknown
    measure: () => void
    registerChild: ((element: Element) => void) | undefined
    isVisible: boolean
    time: number
  }) => React.ReactNode
  offsetY?: number
  onScroll?: (params: OnScrollParams) => void
  height?: number
  time: number
}

const VirtualizedList: React.FCC<IVirtualizedListPops> = React.forwardRef(
  ({ data, renderRow, offsetY, onScroll, height = 200, time }, ref) => {
    const cache = React.useRef(
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 16,
      })
    )

    return (
      <div className={styles.list} style={{ height }}>
        {/* @ts-ignore */}
        <AutoSizer>
          {(options) => (
            // @ts-ignore
            <List
              // @ts-ignore
              ref={ref}
              className={styles.grid}
              onScroll={onScroll}
              scrollTop={offsetY}
              width={options.width}
              height={options.height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={data.length}
              rowRenderer={({ key, index, style, parent, isVisible }) => {
                const item = data[index]
                return (
                  // @ts-ignore
                  <CellMeasurer
                    key={key}
                    cache={cache.current}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    {({ measure, registerChild }) => (
                      <div style={style}>
                        {renderRow({
                          value: item,
                          measure,
                          registerChild,
                          isVisible,
                          time,
                        })}
                      </div>
                    )}
                  </CellMeasurer>
                )
              }}
            />
          )}
        </AutoSizer>
      </div>
    )
  }
)

export default VirtualizedList
