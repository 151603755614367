import {
  Entity,
  Point2dKeyframe,
  ScaleComponent,
  ScaleLockedComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { formatPercents } from '../../keyframes/value'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export interface IProps {
  layers: Entity[]
  time: number
}
export const Scale: React.FCC<IProps> = ({ layers, time }) => {
  const components = layers.map((l) => l.getComponentOrThrow(ScaleComponent))

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <LockablePoint2dValue
            components={layers.map((l) =>
              l.getComponentOrThrow(ScaleComponent)
            )}
            lockableComponents={layers.map((l) =>
              l.getComponentOrThrow(ScaleLockedComponent)
            )}
            time={time}
            iconX={<icons.propertiesPanel.ScaleX />}
            iconY={<icons.propertiesPanel.ScaleY />}
            before={(v) => v * 100}
            after={(v) => v / 100}
            formatValue={formatPercents}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}
