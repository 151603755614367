import {
  Entity,
  NumberKeyframe,
  PathReversedComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimPathAspect,
  TrimStartComponent,
  getValueNumber,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { Group } from '../../common/group'
import { formatPercents } from '../../keyframes/value'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const TrimPath: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const trimPathEnabled = R.any(
    (l) => l.getAspectOrThrow(TrimPathAspect).isEnabled,
    layers
  )
  const enableTrimPath = () =>
    layers.forEach((l) => l.getAspectOrThrow(TrimPathAspect).enable())
  const disableTrimPath = () =>
    layers.forEach((l) => l.getAspectOrThrow(TrimPathAspect).disable())
  const toggleReversePath = () =>
    layers.forEach((l) => {
      if (l.hasComponent(PathReversedComponent)) {
        l.removeComponent(PathReversedComponent)
        return
      }

      l.createComponent(PathReversedComponent)
    })
  const isPathReversed = R.any(
    (l) => l.hasComponent(PathReversedComponent),
    layers
  )
  const trimStartComponents = layers.flatMap((l) =>
    l.hasComponent(TrimStartComponent)
      ? l.getComponentOrThrow(TrimStartComponent)
      : []
  )
  const trimEndComponents = layers.flatMap((l) =>
    l.hasComponent(TrimEndComponent)
      ? l.getComponentOrThrow(TrimEndComponent)
      : []
  )
  const trimOffsetComponents = layers.flatMap((l) =>
    l.hasComponent(TrimOffsetComponent)
      ? l.getComponentOrThrow(TrimOffsetComponent)
      : []
  )

  return (
    <Group
      title="Trim Path"
      isOpen={trimPathEnabled}
      onClick={enableTrimPath}
      headerButtons={
        <>
          {trimPathEnabled ? (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                toggleReversePath()
              }}
              tooltip="Reverse path"
            >
              {isPathReversed ? (
                <icons.propertiesPanel.ReversePath
                  type="reversed"
                  size={iconSize}
                />
              ) : (
                <icons.propertiesPanel.ReversePath
                  type="normal"
                  size={iconSize}
                />
              )}
            </buttons.Icon>
          ) : null}

          {trimPathEnabled ? (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                disableTrimPath()
              }}
              tooltip="Disable trim path"
            >
              <icons.Remove />
            </buttons.Icon>
          ) : (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                enableTrimPath()
              }}
              tooltip="Enable trim path"
            >
              <icons.Add />
            </buttons.Icon>
          )}
        </>
      }
    >
      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={trimStartComponents}
          time={time}
          icon={<span className="w-full pl-[4px]">Start</span>}
          min={0}
          max={100}
          before={(value) => value * 100}
          after={(value) => value / 100}
          format={formatPercents}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={trimStartComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>

      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={trimEndComponents}
          time={time}
          icon={<span className="w-full pl-[4px]">End</span>}
          min={0}
          max={100}
          before={(value) => value * 100}
          after={(value) => value / 100}
          format={formatPercents}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={trimEndComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>

      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={trimOffsetComponents}
          time={time}
          icon={<span className="w-full pl-[4px]">Offset</span>}
          min={-100}
          max={100}
          before={(value) => value * 100}
          after={(value) => value / 100}
          format={formatPercents}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={trimOffsetComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>
    </Group>
  )
}

TrimPath.displayName = 'TrimPath'
