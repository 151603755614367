import {
  AnchorPointComponent,
  Component,
  ConstructorWithTag,
  CurveType,
  EffectsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  getAnimatableValue,
  getComponentValueType,
  getInitialSize,
  getTransformMatrix,
  multiplyMatrixAndPoint,
  OpacityComponent,
  Point2D,
  PositionComponent,
  RotationComponent,
  setAnchorPoint,
  setAnimatableValue,
  setCurveType,
  SpringCurveComponent,
  StrokesRelationsAspect,
  TimingCurveComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { TimingCurve } from '@aninix/figma'
import * as R from 'ramda'
import type { Transition, TransitionOptions } from './transition'

/**
 * @mutate entity
 */
function setAnchorPointToCenter(entity: Entity): void {
  const initialSize = getInitialSize(entity)
  setAnchorPoint(entity, {
    x: initialSize.x / 2,
    y: initialSize.y / 2,
  })
}

function isPositionUnchanged(left: Entity, right: Entity): boolean {
  const initialSizeLeft = getInitialSize(left)
  const pointAtCenterLeft: Point2D = {
    x: initialSizeLeft.x / 2,
    y: initialSizeLeft.y / 2,
  }
  const matrixLeft = getTransformMatrix({ entity: left })
  const finalPointLeft = multiplyMatrixAndPoint(matrixLeft, pointAtCenterLeft)

  const initialSizeRight = getInitialSize(right)
  const pointAtCenterRight: Point2D = {
    x: initialSizeRight.x / 2,
    y: initialSizeRight.y / 2,
  }
  const matrixRight = getTransformMatrix({ entity: right })
  const finalPointRight = multiplyMatrixAndPoint(
    matrixRight,
    pointAtCenterRight
  )

  return (
    finalPointLeft.x === finalPointRight.x &&
    finalPointLeft.y === finalPointRight.y
  )
}

function animate<T>(
  component: Component<T>,
  from: T,
  to: T,
  options: {
    timeStart: number
    timeEnd: number
    curve: TimingCurve
  }
): void {
  const { timeStart, timeEnd, curve } = options

  // @TODO: provide proper type casting here
  if (R.not(R.equals(from, to))) {
    const leftKeyframe = setAnimatableValue(component, from, timeStart, true)
    const rightKeyframe = setAnimatableValue(component, to, timeEnd)

    if (curve.type === CurveType.Spring) {
      setCurveType(leftKeyframe, curve.type)
      leftKeyframe.updateComponent(SpringCurveComponent, curve.value)
    }

    if (curve.type === CurveType.Timing) {
      leftKeyframe.updateComponent(TimingCurveComponent, curve.value)
      rightKeyframe?.updateComponent(TimingCurveComponent, curve.value)
    }
  }
}

function blendComponents(
  left: Entity,
  right: Entity,
  options: TransitionOptions
): void {
  const { timeStart, timeEnd, includeComponents } = options

  for (const leftComponent of left.components) {
    const isStatic = (() => {
      try {
        getComponentValueType(leftComponent)
        return false
      } catch {
        return true
      }
    })()

    if (isStatic) {
      continue
    }

    const ComponentConstructor =
      leftComponent.constructor as ConstructorWithTag<Component>

    if (
      includeComponents != null &&
      !includeComponents
        .map((Constructor) => Constructor.tag)
        .includes(ComponentConstructor.tag)
    ) {
      continue
    }

    const rightComponent = right.getComponentOrThrow(ComponentConstructor)
    const leftValue = getAnimatableValue(leftComponent, timeStart) as number
    const rightValue = getAnimatableValue(rightComponent, timeEnd) as number

    // @NOTE: in case of right entity is hidden
    if (
      ComponentConstructor.tag === OpacityComponent.tag &&
      right.getComponentOrThrow(VisibleInViewportComponent).value === false
    ) {
      animate(leftComponent, leftValue, 0, options)
      continue
    }

    // @TODO: enable once we figure out how to solve problem with anchor points
    // @NOTE: ignore anchor point animations
    if (ComponentConstructor.tag === AnchorPointComponent.tag) {
      // continue
    }

    // @TODO: enable once we figure out how to solve problem with anchor points
    if (ComponentConstructor.tag === PositionComponent.tag) {
      // @NOTE: checking if position animation needed.
      // It can be avoided in cases when node is rotated.
      // In that case Figma change the position but in the Aninix model the position should remain unchanged.
      if (isPositionUnchanged(left, right)) {
        // continue
      }
    }

    // @NOTE: we have different rotation logic from Figma:
    // Input:  0, 45, 90, 135, 180, -135, -90, -45, 0
    // Output: 0, 45, 90, 135, 180, 225, 270, 315, 360
    if (ComponentConstructor.tag === RotationComponent.tag) {
      const value = (() => {
        const leftSign = Math.sign(leftValue)
        const isSwitched = Math.abs(leftValue - rightValue) > 180

        if (isSwitched) {
          return 360 * leftSign + rightValue
        }

        return rightValue
      })()
      animate(leftComponent, leftValue, value, options)
      continue
    }

    animate(leftComponent, leftValue, rightValue, options)
  }
}

/**
 * Applies `smart animate` to provided pair.
 */
export const smartAnimateTransition: Transition = (pair, options) => {
  // @NOTE: required to properly cast types
  if (pair.left == null || pair.right == null) {
    return
  }

  // @TODO: enable once we figure out how to solve problem with anchor points
  // setAnchorPointToCenter(pair.left.entity)
  // setAnchorPointToCenter(pair.right.entity)

  // @NOTE: create animation on the left entities
  blendComponents(pair.left.entity, pair.right.entity, options)

  const project = pair.left.entity.getProjectOrThrow()

  if (
    pair.left.entity.hasAspect(FillsRelationsAspect) &&
    pair.right.entity.hasAspect(FillsRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(FillsRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(FillsRelationsAspect)
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      // @NOTE: we are making sure above that we have equal number of fills/strokes/effects between both nodes.
      // @TODO: simplify code.
      if (left == null && right != null) {
        const Constructor =
          project.entitiesProvider.getEntityConstructorByTagName(
            // @ts-ignore
            right.constructor.tag
          )
        const newLeft = project.createEntity(Constructor)
        leftAspect.addChild(newLeft)
        blendComponents(newLeft, right, options)
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }

  if (
    pair.left.entity.hasAspect(StrokesRelationsAspect) &&
    pair.right.entity.hasAspect(StrokesRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(StrokesRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(
      StrokesRelationsAspect
    )
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      if (left == null && right != null) {
        const Constructor =
          project.entitiesProvider.getEntityConstructorByTagName(
            // @ts-ignore
            right.constructor.tag
          )
        const newLeft = project.createEntity(Constructor)
        leftAspect.addChild(newLeft)
        blendComponents(newLeft, right, options)
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }

  if (
    pair.left.entity.hasAspect(EffectsRelationsAspect) &&
    pair.right.entity.hasAspect(EffectsRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(EffectsRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(
      EffectsRelationsAspect
    )
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      if (left == null && right != null) {
        const Constructor =
          project.entitiesProvider.getEntityConstructorByTagName(
            // @ts-ignore
            right.constructor.tag
          )
        const newLeft = project.createEntity(Constructor)
        leftAspect.addChild(newLeft)
        blendComponents(newLeft, right, options)
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }
}
