import {
  ClipContentComponent,
  CornerRadiusComponent,
  Entity,
  FillsRelationsAspect,
  SizeComponent,
  SizeLockedComponent,
  StrokesRelationsAspect,
} from '@aninix-inc/model'
import { Checkbox, PropertyRowV2, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { getFlattenChildren } from '../nodes'
import { ColorSelection } from '../nodes/color-selection'
import { Fills } from '../nodes/fills'
import { Strokes } from '../nodes/strokes'
import { TrimLayer } from '../nodes/trim-layer'
import { LockablePoint2dValue } from '../values/lockable-point-2d'
import { NumberValue } from '../values/number'

const iconSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const EntryProperties: React.FCC<IProps> = ({ layers, time }) => {
  return (
    <>
      <PropertyRowV2
        name="Size"
        inputs={
          <LockablePoint2dValue
            components={layers.map((l) => l.getComponentOrThrow(SizeComponent))}
            lockableComponents={layers.map((l) =>
              l.getComponentOrThrow(SizeLockedComponent)
            )}
            iconX={<span>W</span>}
            iconY={<span>H</span>}
            minX={0}
            minY={0}
          />
        }
      />

      <PropertyRowV2
        name="Corner Radius"
        inputs={
          <NumberValue
            components={layers.map((layer) =>
              layer.getComponentOrThrow(CornerRadiusComponent)
            )}
            icon={<icons.propertiesPanel.CornerRadius size={iconSize} />}
            min={0}
          />
        }
      />

      <PropertyRowV2
        inputs={
          <div className="py-[6px]">
            <Checkbox
              isChecked={
                layers.map((layer) =>
                  layer.getComponentOrThrow(ClipContentComponent)
                )[0].value
              }
              onClick={() => {
                layers.forEach((l) =>
                  l.updateComponent(ClipContentComponent, (v) => !v)
                )
              }}
              label="Clip content"
            />
          </div>
        }
      />

      <TrimLayer layers={layers} time={time} />

      <Fills layers={layers} time={time} />
      <Strokes layers={layers} time={time} />
      <ColorSelection
        layers={getFlattenChildren(layers).filter(
          (layer) =>
            layer.hasAspect(FillsRelationsAspect) &&
            layer.hasAspect(StrokesRelationsAspect)
        )}
        time={time}
      />
    </>
  )
}

EntryProperties.displayName = 'EntryProperties'
