import {
  Entity,
  NumberKeyframe,
  StrokeBottomWeightComponent,
  getValueNumber,
  round,
} from '@aninix-inc/model'
import { icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const StrokeBottomWeight: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const properties = layers.map((l) =>
    l.getComponentOrThrow(StrokeBottomWeightComponent)
  )

  return (
    <div className="flex flex-row w-full justify-between">
      <NumberValue
        components={properties}
        time={time}
        icon={<icons.StrokeBottomWeight size={iconSize} />}
        min={0}
        format={(v) => `${round(v, { fixed: 2 })}`}
      />

      <KeyframesPropertyControl
        components={properties}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  )
}
