import {
  Entity,
  PositionComponent,
  SpatialPoint2dKeyframe,
  getValueSpatialPoint2d,
  round,
} from '@aninix-inc/model'
import { CompactPropertyRow } from '@aninix/app-design-system'
import * as React from 'react'
import { SpatialPoint2dValue } from '../../values/spatial-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export interface IProps {
  layers: Entity[]
  time: number
}
export const Position: React.FCC<IProps> = ({ layers, time }) => {
  const components = layers.map((l) => l.getComponentOrThrow(PositionComponent))

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <SpatialPoint2dValue
            components={components}
            time={time}
            iconX={<span>X</span>}
            iconY={<span>Y</span>}
            formatValue={(n) => round(n, { fixed: 2 }).toFixed(2)}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={SpatialPoint2dKeyframe}
          valueGetter={getValueSpatialPoint2d}
        />
      }
    />
  )
}
