import {
  ChildrenRelationsAspect,
  ColorStopsRelationsAspect,
  Component,
  DurationComponent,
  EffectsRelationsAspect,
  Entity,
  EntityType,
  EntityTypeComponent,
  FillsRelationsAspect,
  NodeColorComponent,
  PaintType,
  PaintTypeComponent,
  ParentRelationAspect,
  Root,
  SelectionSystem,
  StrokesRelationsAspect,
  TargetRelationAspect,
  TimeComponent,
  commitUndo,
  getDuration,
  getKeyframeValueComponent,
  getNode,
  getSortedKeyframes,
  getStartTime,
  isSegment,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { TimelineTrack } from '@aninix/app-design-system'
import {
  KeyModificator,
  getSelection,
  useEntity,
  useSession,
  useSystem,
} from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import * as timeConverters from '../../../../helpers/timeConverters'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { GroupOfSegments as GroupOfKeyframes } from './group-of-segments'
import * as styles from './index.scss'
import { Range, rangify } from './rangify'

// TODO: refactor
const handlerWidth = 6

// @TODO: move to model package
export const createIsKeyframeInRange =
  (start: number, end: number) => (keyframe: Entity) =>
    keyframe.getComponentOrThrow(TimeComponent).value >= start &&
    keyframe.getComponentOrThrow(TimeComponent).value <= end

// @TODO: move to model package
export const getOwnAnimatedProperties = (node: Entity) =>
  node.components.filter(
    (component) => getSortedKeyframes(component).length > 0
  )

// @TODO: move to model package
const getAnimatedProperties = (node: Entity): Component[] => {
  const project = node.getProjectOrThrow()
  let cacheMap = project.cache.get<Map<string, unknown>>(node.id)

  if (cacheMap === undefined) {
    cacheMap = new Map<string, unknown>()
    project.cache.set(node.id, cacheMap)
  }

  const cachedValue = cacheMap.get('animated-properties') as
    | Component[]
    | undefined

  if (cachedValue !== undefined) {
    return cachedValue
  }

  const properties = node.components.filter(
    (component) => getSortedKeyframes(component).length > 0
  )

  if (node.hasAspect(ChildrenRelationsAspect)) {
    node
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
      .forEach((child) => {
        properties.push(...getAnimatedProperties(child))
      })
  }

  if (node.hasAspect(FillsRelationsAspect)) {
    node
      .getAspectOrThrow(FillsRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

        if (
          paintType === PaintType.GradientLinear ||
          paintType === PaintType.GradientRadial
        ) {
          paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()
            .forEach((colorStop) => {
              properties.push(...getAnimatedProperties(colorStop))
            })
        }

        properties.push(...getAnimatedProperties(paint))
      })
  }

  if (node.hasAspect(StrokesRelationsAspect)) {
    node
      .getAspectOrThrow(StrokesRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        properties.push(...getAnimatedProperties(paint))
      })
  }

  if (node.hasAspect(EffectsRelationsAspect)) {
    node
      .getAspectOrThrow(EffectsRelationsAspect)
      .getChildrenList()
      .forEach((paint) => {
        properties.push(...getAnimatedProperties(paint))
      })
  }

  cacheMap.set('animated-properties', properties)

  return properties
}

type GroupOfKeyframes = {
  keyframes: Entity[]
  start: number
  end: number
}

const getTreeIds = (node: Entity): string[] => {
  const nodeIds: string[] = [node.id]

  if (node.hasAspect(FillsRelationsAspect)) {
    for (const fill of node
      .getAspectOrThrow(FillsRelationsAspect)
      .getChildrenList()) {
      const paintType = fill.getComponentOrThrow(PaintTypeComponent).value
      if (
        paintType === PaintType.GradientLinear ||
        paintType === PaintType.GradientRadial
      ) {
        for (const colorStop of fill
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()) {
          nodeIds.push(colorStop.id)
        }
      }

      nodeIds.push(fill.id)
    }
  }

  if (node.hasAspect(StrokesRelationsAspect)) {
    for (const stroke of node
      .getAspectOrThrow(StrokesRelationsAspect)
      .getChildrenList()) {
      const paintType = stroke.getComponentOrThrow(PaintTypeComponent).value
      if (
        paintType === PaintType.GradientLinear ||
        paintType === PaintType.GradientRadial
      ) {
        for (const colorStop of stroke
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()) {
          nodeIds.push(colorStop.id)
        }
      }

      nodeIds.push(stroke.id)
    }
  }

  if (node.hasAspect(EffectsRelationsAspect)) {
    for (const effect of node
      .getAspectOrThrow(EffectsRelationsAspect)
      .getChildrenList()) {
      nodeIds.push(effect.id)
    }
  }

  if (node.hasAspect(ChildrenRelationsAspect)) {
    for (const child of node
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()) {
      nodeIds.push(...getTreeIds(child))
    }
  }

  return nodeIds
}

/**
 * 1. get all segments for each property;
 * 2. find all the groups for all properties;
 * 3. return keyframes and group range.
 * @todo add tests.
 */
export const getGroupOfSegments = (node: Entity): GroupOfKeyframes[] => {
  const nodeIds: string[] = getTreeIds(node)
  const keyframes = node.getProjectOrThrow().getEntitiesByPredicate((e) => {
    // @NOTE: check for nullability required in cases when there is a keyframe
    // which pointed to removed layer.
    const target = e.getAspect(TargetRelationAspect)?.getTargetEntity()

    if (target == null) {
      return false
    }

    return (
      e.getComponentOrThrow(EntityTypeComponent).value ===
        EntityType.Keyframe && nodeIds.includes(target.id)
    )
  })
  const sortedKeyframes = R.sortBy(
    (k) => k.getComponentOrThrow(TimeComponent).value,
    keyframes
  )
    // @NOTE: this is required to avoid invalid keyframes.
    // Related to https://linear.app/aninix/issue/ANI-2204/invalide-project-state-after-sync.
    // @TODO: move to the model so we avoid such states completely.
    .filter(
      (k) =>
        k.getAspectOrThrow(TargetRelationAspect).getTargetComponent() != null
    )
  const keyframesByProperties = R.groupBy(
    (k) =>
      k.getAspectOrThrow(TargetRelationAspect).getTargetComponentOrThrow().id,
    sortedKeyframes
  )
  const allRanges: Range[] = Object.values(keyframesByProperties).flatMap(
    (propertyKeyframes) =>
      R.aperture(2, propertyKeyframes!)
        .filter(([left, right]) =>
          isSegment(left, right, (left, right) =>
            R.equals(
              getKeyframeValueComponent(left).value,
              getKeyframeValueComponent(right).value
            )
          )
        )
        .map(
          ([left, right]) =>
            [
              left.getComponentOrThrow(TimeComponent).value,
              right.getComponentOrThrow(TimeComponent).value,
            ] as Range
        )
  )
  const mergedRanges: Range[] = rangify(allRanges)
  return mergedRanges.map((range) => ({
    keyframes: sortedKeyframes.filter(
      createIsKeyframeInRange(range[0], range[1])
    ),
    start: range[0],
    end: range[1],
  }))
}

export interface IProps {
  layer: Entity
  keyframes: Entity[]
  parentTrackWidth: number
}
export const Layer: React.FCC<IProps> = ({ layer, parentTrackWidth }) => {
  const analytics = useAnalytics()
  const session = useSession()
  const project = layer.getProjectOrThrow()
  const root = project.getEntityByTypeOrThrow(Root)
  useEntity(layer)
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  const projectDuration = root.getComponentOrThrow(DurationComponent).value
  const groupsOfSegments = getGroupOfSegments(layer)
  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isSelfSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    layer.components
  )
  const node = getNode(layer)

  if (node === undefined) {
    throw new Error('Invalid state. Node is not found')
  }

  const startTime = getStartTime(node)
  const duration = getDuration(node)
  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    if (isComponentSelectedValue) {
      // return `${
      //   defaults.nodeColors[
      //     layer.getComponentOrThrow(NodeColorComponent).value
      //   ]
      // }10`
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    if (isSelfSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isSelfSelectedValue,
    isComponentSelectedValue,
  ])

  const selectNode = React.useCallback(() => {
    analytics.track({
      eventName: AnalyticsEvent.LayerSelected,
      properties: {
        context: 'webapp.editor.timeline.tracks',
      },
    })
    // @NOTE: also used in info part of timeline
    if (session.keyModificators.includes(KeyModificator.Shift)) {
      const selectedNodes = getSelection(project, EntityType.Node)
      if (selectedNodes.length === 0) {
        selection.select([layer.id])
        commitUndo(project)
        return
      }
      const selectedNodeIds = selectedNodes.map((_node) => _node.id)
      const parent = layer
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow()
      const nodesToSelect = parent
        .getAspectOrThrow(ChildrenRelationsAspect)
        .getChildrenList()
      const minSelectedIndex = R.findIndex(
        (_node) => selectedNodeIds.includes(_node.id),
        nodesToSelect
      )
      const currentIndex = R.findIndex(
        (_node) => _node.id === layer.id,
        nodesToSelect
      )
      const maxSelectedIndex = R.findLastIndex(
        (_node) => selectedNodeIds.includes(_node.id),
        nodesToSelect
      )
      // @NOTE: reversed
      if (currentIndex < minSelectedIndex) {
        R.reverse(
          R.range(
            R.min(currentIndex, minSelectedIndex),
            R.max(currentIndex, minSelectedIndex) + 1
          )
        ).forEach((i) => {
          if (nodesToSelect[i] == null) {
            return
          }
          selection.select([nodesToSelect[i].id])
        })
        commitUndo(project)
        return
      }
      R.range(
        R.min(currentIndex, maxSelectedIndex),
        R.max(currentIndex, maxSelectedIndex) + 1
      ).forEach((i) => {
        if (nodesToSelect[i] == null) {
          return
        }
        selection.select([nodesToSelect[i].id])
      })
      commitUndo(project)
      return
    }
    if (session.keyModificators.includes(KeyModificator.Ctrl)) {
      if (selection.isSelected(layer.id)) {
        selection.deselect([layer.id])
        return
      }
      selection.select([layer.id])
      commitUndo(project)
      return
    }
    selection.deselectAll().select([layer.id])
  }, [selection, layer])

  const enableHighlight = React.useCallback(() => {
    session.setBuffer(layer.id)
  }, [])

  const disableHighlight = React.useCallback(() => {
    session.cleanBuffer()
  }, [])

  const convertTimeToPixels = React.useCallback(
    (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        time,
      }),
    [projectDuration, parentTrackWidth]
  )

  const left = React.useMemo(
    () => convertTimeToPixels(startTime) + handlerWidth,
    [convertTimeToPixels, startTime]
  )

  const width = React.useMemo(
    () => convertTimeToPixels(duration),
    [convertTimeToPixels, duration]
  )

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      selectNode()
    },
    []
  )

  return (
    // @NOTE: isSelected=false because we change color by the --bg-color of div below
    <TimelineTrack isSelected={false} alignment="right">
      <div
        data-model-type="node"
        className={styles.background}
        style={{
          left,
          width,
          // @ts-ignore
          '--bg-color': color,
          overflow: 'visible',
        }}
        onMouseEnter={enableHighlight}
        onMouseLeave={disableHighlight}
        onClick={onClick}
      />
      {groupsOfSegments.map((groupOfSegments, idx) => (
        <GroupOfKeyframes
          key={idx}
          layer={layer}
          keyframes={groupOfSegments.keyframes}
          parentTrackWidth={parentTrackWidth}
          projectDuration={projectDuration}
          startTime={groupOfSegments.start}
          endTime={groupOfSegments.end}
        />
      ))}
    </TimelineTrack>
  )
}

Layer.displayName = 'Layer'
