import {
  Entity,
  EntityType,
  EntityTypeComponent,
  Project,
  SelectionSystem,
  getEntryOrThrow,
} from '@aninix-inc/model'
import {
  VirtualizedList,
  useMouseMove,
  useThrottle,
} from '@aninix/app-design-system'
import {
  usePlayback,
  useReloadOnAnyUpdate,
  useSystem,
  useTimeline,
} from '@aninix/core'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { List } from 'react-virtualized'
import { TRow, getNodeRows } from '../get-row-data'
import { useAutoScrollSync, useScrollSync } from '../scroll-sync'
import { LayerDndProvider } from './drag-n-drop'
import * as styles from './index.scss'
import { Layer } from './layer'
import { Property } from './property'
import { PropertyGroup } from './property-group'
import { TimeControlIndicator } from './time-control-indicator'
import { TimeControlPlayback } from './time-control-playback'

const Row: React.FC<{
  value: unknown
  measure: () => void
  registerChild: ((element: Element) => void) | undefined
  isVisible: boolean
  time: number
}> = (props) => {
  const value = props.value as TRow

  if (value.type === 'layer') {
    return (
      <Layer key={value.entity.id} layer={value.entity} indent={value.indent} />
    )
  }

  if (value.type === 'property-group') {
    return (
      <PropertyGroup
        key={value.entity.id}
        propertyGroup={value.entity}
        name={value.meta.name}
        indent={value.indent}
      />
    )
  }

  if (value.type === 'property') {
    return (
      <Property
        key={value.entity.id}
        property={value.entity}
        name={value.meta.name}
        iconType={value.meta.iconType}
        indent={value.indent}
        additionalIndent={value.additionalIndent}
        time={props.time}
      />
    )
  }

  return <div />
}

const InfoActual: React.FCC<{
  project: Project
  isPlaying: boolean
  time: number
  width: number
  height: number
  onWidthUpdate: (width: number) => void
}> = React.memo(
  ({ project, time, width, height, onWidthUpdate }) => {
    useReloadOnAnyUpdate(project)
    const selection = project.getSystemOrThrow(SelectionSystem)
    useSystem(selection)

    const listRef = React.useRef<List>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const listContainerRef = React.useRef<HTMLDivElement>(null)
    const data = getNodeRows(getEntryOrThrow(project), { indent: 0 })

    const [autoScroll, setAutoScroll] = React.useState<boolean>(false)
    const { scrollTop, scrollTo, scrollToEntity } = useScrollSync()
    useAutoScrollSync({
      containerRef,
      listContainerRef,
      rowsCount: data.length,
      enabled: autoScroll,
    })

    const handleClick = React.useCallback(() => {
      selection.deselectAll()
    }, [selection])

    const bufferTimelineInfoWidthRef = React.useRef(0)
    const minTimelineInfoWidthRef = React.useRef(240)
    const maxTimelineInfoWidthRef = React.useRef(640)

    const resizeStart = React.useCallback(() => {
      bufferTimelineInfoWidthRef.current = width
    }, [width])

    const handleResize = useThrottle({
      callback: React.useCallback(
        (deltaX) => {
          const nextValue = R.clamp(
            minTimelineInfoWidthRef.current,
            maxTimelineInfoWidthRef.current,
            bufferTimelineInfoWidthRef.current + deltaX
          )

          onWidthUpdate(nextValue)
        },
        [onWidthUpdate]
      ),
      delay: 32,
    })

    const resizeEnd = React.useCallback(() => {
      bufferTimelineInfoWidthRef.current = 0
    }, [])

    React.useEffect(
      function autoscrollToSelectedRow() {
        const subscription = selection.onSelectionUpdate((prev, current) => {
          const newEntities = R.difference(current, prev)
          const selectedNodes = newEntities
            .map((id) => {
              const entity = project.getEntity(id)
              if (
                entity?.getComponentOrThrow(EntityTypeComponent).value ===
                EntityType.Node
              ) {
                return entity
              }
              return undefined
            })
            .filter((n) => n !== undefined) as Entity[]

          if (selectedNodes.length === 0) {
            return
          }

          const lastItem = R.last(selectedNodes)
          scrollToEntity(listRef, lastItem)
        })

        return subscription
      },
      [selection]
    )

    const { startListen, offsetX, isListening } = useMouseMove()

    const startListenFn = React.useCallback((e: MouseEvent) => {
      resizeStart()
      startListen(e)
    }, [])

    React.useEffect(() => {
      if (isListening === false) {
        resizeEnd()
        return
      }

      handleResize(offsetX)
    }, [isListening, offsetX])

    const handleDrop = React.useCallback(
      (entities: Entity[]) => {
        queueMicrotask(() =>
          scrollToEntity(listRef, entities[entities.length - 1])
        )
      },
      [scrollToEntity]
    )

    return (
      <LayerDndProvider
        onDragStart={() => setAutoScroll(true)}
        onDragEnd={() => setAutoScroll(false)}
        onDrop={handleDrop}
      >
        <div className={styles.container} ref={containerRef}>
          <div
            className={classNames(styles.resizer, {
              [styles['resizer--active']]: isListening,
            })}
            // @ts-ignore
            onMouseDown={startListenFn}
          />

          <div className={styles.time_control_container}>
            <TimeControlPlayback />
            <TimeControlIndicator project={project} />
          </div>

          {/* TODO: replace div with another interactive component? */}
          <div
            onClick={handleClick}
            style={{
              overflow: 'hidden',
              height: height,
            }}
          >
            <div ref={listContainerRef} style={{ position: 'relative' }}>
              <VirtualizedList
                // @ts-ignore
                ref={listRef}
                onScroll={scrollTo}
                data={data}
                renderRow={Row}
                offsetY={scrollTop}
                height={height}
                time={time}
              />
            </div>
          </div>
        </div>
      </LayerDndProvider>
    )
  },
  (_, current) => current.isPlaying
)

InfoActual.displayName = 'InfoActual'

export interface IProps {
  project: Project
}
export const Info: React.FC<IProps> = observer(({ project }) => {
  const playback = usePlayback()
  const timeline = useTimeline()
  return (
    <InfoActual
      project={project}
      isPlaying={playback.isPlaying}
      time={playback.time}
      width={timeline.infoWidth}
      height={timeline.height}
      onWidthUpdate={timeline.updateInfoWidth}
    />
  )
})

Info.displayName = 'Info'
