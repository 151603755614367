import * as React from 'react'

import { Locked } from './Locked'
import { Unlocked } from './Unlocked'

const defaultSize = {
  x: 20,
  y: 20,
}

export enum LockType {
  Locked = 'LOCKED',
  Normal = 'NORMAL',
}

export interface IProps {
  type: LockType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Lock: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (LockType.Normal === type) {
    return <Unlocked size={size} style={style} />
  }

  if (LockType.Locked === type) {
    return <Locked size={size} style={style} />
  }

  return null
}
