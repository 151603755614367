import {
  BlurRadiusComponent,
  DropShadow,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  NumberKeyframe,
  ParentRelationAspect,
  Point2dKeyframe,
  RgbaKeyframe,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  UpdatesSystem,
  VisibleInViewportComponent,
  commitUndo,
  getValueNumber,
  getValuePoint2d,
  getValueRgba,
  setEffectType,
} from '@aninix-inc/model'
import {
  PropertyRowV2,
  Select,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useEntities, useEntity } from '@aninix/core'
import * as React from 'react'
import { Group } from '../../common/group'
import { NumberValue } from '../../values/number'
import { Point2dValue } from '../../values/point-2d'
import { RgbaValue } from '../../values/rgba'
import { KeyframesPropertyControl } from '../keyframes-property-control'
import { effectSlices } from './effect-slices'
import * as styles from './index.scss'

const EffectContent: React.FC<{
  effects: Entity[]
  time: number
}> = ({ effects, time }) => {
  useEntities(effects)
  useEntity(effects[0])
  const type = effects[0].getComponentOrThrow(EffectTypeComponent).value

  switch (type) {
    case EffectType.DropShadow:
    case EffectType.InnerShadow:
      return (
        <div>
          <div className={styles.row}>
            <Point2dValue
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowOffsetComponent)
              )}
              time={time}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowOffsetComponent)
              )}
              time={time}
              KeyframeConstructor={Point2dKeyframe}
              valueGetter={getValuePoint2d}
            />
          </div>

          <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowRadiusComponent)
              )}
              time={time}
              icon={<span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>}
              min={0}
              iconWidth={96}
              width={192}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowRadiusComponent)
              )}
              time={time}
              KeyframeConstructor={NumberKeyframe}
              valueGetter={getValueNumber}
            />
          </div>

          {/* <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
              icon={
                <span style={{ width: '100%', paddingLeft: 4 }}>
                  Spread
                </span>
              }
              iconWide
              width={96}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
            />
          </div> */}

          <div className="pt-1">
            <div className={styles.row}>
              <RgbaValue
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowColorComponent)
                )}
                time={time}
              />

              <KeyframesPropertyControl
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowColorComponent)
                )}
                time={time}
                KeyframeConstructor={RgbaKeyframe}
                valueGetter={getValueRgba}
              />
            </div>
          </div>
        </div>
      )

    case EffectType.BackgroundBlur:
    case EffectType.LayerBlur:
      return (
        <div>
          <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponentOrThrow(BlurRadiusComponent)
              )}
              time={time}
              icon={<span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>}
              min={0}
              iconWidth={96}
              width={192}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(BlurRadiusComponent)
              )}
              time={time}
              KeyframeConstructor={NumberKeyframe}
              valueGetter={getValueNumber}
            />
          </div>
        </div>
      )

    default: {
      const never: never = type
      throw new Error(`Should never reach: "${never}"`)
    }
  }
}

const Effect: React.FC<{ effects: Entity[]; time: number }> = ({
  effects,
  time,
}) => {
  useEntities(effects)
  const project = effects[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  return (
    <div className={styles.row}>
      <Group
        title={
          <Select
            className="pl-[2px]"
            activeValueId={
              effects[0].getComponentOrThrow(EffectTypeComponent).value
            }
            options={[
              {
                id: EffectType.InnerShadow,
                title: 'Inner shadow',
              },
              {
                id: EffectType.DropShadow,
                title: 'Drop shadow',
              },
              {
                id: EffectType.LayerBlur,
                title: 'Layer blur',
                disabled: effects.some(
                  (effect) =>
                    effect.getComponentOrThrow(EffectTypeComponent).value ===
                    EffectType.LayerBlur
                ),
              },
              {
                id: EffectType.BackgroundBlur,
                title: 'Background blur',
                disabled: effects.some(
                  (effect) =>
                    effect.getComponentOrThrow(EffectTypeComponent).value ===
                    EffectType.BackgroundBlur
                ),
              },
            ]}
            onChange={(id) => {
              updates.batch(() => {
                effects.forEach((effect) => {
                  setEffectType(effect, id as EffectType)
                })
              })
              commitUndo(project)
            }}
          />
        }
        isOpen
        headerButtons={
          <>
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  effects.forEach((effect) => {
                    effect.updateComponent(
                      VisibleInViewportComponent,
                      (value) => !value
                    )
                  })
                })
                commitUndo(project)
              }}
            >
              <icons.Visible
                type={
                  effects[0].getComponentOrThrow(VisibleInViewportComponent)
                    .value
                    ? icons.VisibilityType.Visible
                    : icons.VisibilityType.Hidden
                }
                size={iconSize}
              />
            </buttons.Icon>
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  effects.forEach((effect) => {
                    effect
                      .getAspectOrThrow(ParentRelationAspect)
                      .getParentEntity()
                      ?.getAspectOrThrow(EffectsRelationsAspect)
                      .removeChild(effect.id)
                  })
                })
                commitUndo(project)
              }}
            >
              <icons.Remove />
            </buttons.Icon>
          </>
        }
        sliderInHeader
      >
        <EffectContent effects={effects} time={time} />
      </Group>
    </div>
  )
}

const iconSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const Effects: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const project = layers[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  const effectRows = effectSlices(layers)

  const create = React.useCallback(() => {
    updates.batch(() => {
      layers.forEach((layer) => {
        layer
          .getAspectOrThrow(EffectsRelationsAspect)
          .addChild(project.createEntity(DropShadow))
      })
    })
    commitUndo(project)
  }, [layers, project, updates])

  return (
    <PropertyRowV2
      name="Effects"
      headerButtons={
        <buttons.Icon onClick={create}>
          <icons.Add />
        </buttons.Icon>
      }
      wideInputs={
        effectRows.length === 0 ? undefined : (
          <div className={styles.container}>
            {effectRows.map((effectRow, idx) => (
              <Effect key={idx} effects={effectRow} time={time} />
            ))}
          </div>
        )
      }
      empty={effectRows.length === 0}
    />
  )
}
