import { Entity, SmoothCornerRadiusComponent, mixed } from '@aninix-inc/model'
import { Checkbox, CompactPropertyRow } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'

export interface IProps {
  layers: Entity[]
}
export const SmoothCorners: React.FCC<IProps> = ({ layers }) => {
  useEntities(layers)
  const properties = layers.map((l) =>
    l.getComponentOrThrow(SmoothCornerRadiusComponent)
  )

  const equals = R.all((p) => p.value === properties[0].value, properties)
  const value = equals ? properties[0].value : mixed

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="py-[6px]">
          <Checkbox
            // @TODO: add support of mixed component
            isChecked={value === mixed ? false : value}
            onClick={() => {
              layers.forEach((l) =>
                l.updateComponent(SmoothCornerRadiusComponent, (v) => !v)
              )
            }}
            label="Smooth corners"
          />
        </div>
      }
      rightColumn={null}
    />
  )
}
