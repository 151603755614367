import { paper } from '@aninix-inc/renderer'

import {
  Entity,
  LayoutAspect,
  getAnchorPoint,
  getSize,
} from '@aninix-inc/model'
import { getTransformMatrixV2 } from './get-transform-matrix'

/**
 * @description calculate actual bounding box of the node related to parent.
 * It includes calculation of size, scale, anchor point and rotation
 */
export function getBoundingBoxAtTimeV2(payload: {
  entity: Entity
  time: number
  ignorePosition?: boolean
}) {
  const { entity, time, ignorePosition } = payload
  const transformMatrix = getTransformMatrixV2({
    entity,
    matrix: new paper.Matrix(),
    time,
    ignorePosition,
  })

  const layout = entity.getAspectOrThrow(LayoutAspect)
  const anchorPoint = getAnchorPoint(entity)
  const baseSize = layout.initialSize
  const size = getSize(entity, time)
  const rect = new paper.Path.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(size.x, size.y)
  )
  rect.scale(
    size.x / baseSize.x,
    size.y / baseSize.y,
    new paper.Point(anchorPoint.x, anchorPoint.y)
  )
  rect.transform(transformMatrix)
  const bounds = rect.bounds

  return {
    x: bounds.topLeft.x,
    y: bounds.topLeft.y,
    width: bounds.width,
    height: bounds.height,
  }
}
