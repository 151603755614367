import {
  BlendModeComponent,
  BlendModeType,
  Entity,
  NumberKeyframe,
  OpacityComponent,
  getValueNumber,
  mixed,
} from '@aninix-inc/model'
import {
  OptionType,
  PropertyRowV2,
  Select,
  icons,
} from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { formatPercents } from '../../keyframes/value'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const Opacity: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const properties = layers.map((l) => l.getComponentOrThrow(OpacityComponent))
  const blendingModes = layers.map(
    (l) => l.getComponentOrThrow(BlendModeComponent).value
  )

  const blendingModesEquals = R.all(
    (bm) => bm === blendingModes[0],
    blendingModes
  )
  const blendingMode = blendingModesEquals ? blendingModes[0] : mixed

  const blendingModeOptions: (OptionType | 'divider')[] = React.useMemo(() => {
    const values = [
      ...(blendingMode === mixed
        ? [
            {
              id: mixed,
              title: 'Mixed',
              disabled: true,
            },
            'divider' as const,
          ]
        : []),
      {
        id: BlendModeType.PassThrough,
        title: 'Pass Through',
      },
      {
        id: BlendModeType.Normal,
        title: 'Normal',
      },
      'divider' as const,
      {
        id: BlendModeType.Darken,
        title: 'Darken',
      },
      {
        id: BlendModeType.Multiply,
        title: 'Multiply',
      },
      {
        id: BlendModeType.LinearBurn,
        title: 'Plus darker',
      },
      {
        id: BlendModeType.ColorBurn,
        title: 'Color Burn',
      },
      'divider' as const,
      {
        id: BlendModeType.Lighten,
        title: 'Lighten',
      },
      {
        id: BlendModeType.Screen,
        title: 'Screen',
      },
      {
        id: BlendModeType.LinearDodge,
        title: 'Plus ligher',
      },
      {
        id: BlendModeType.ColorDodge,
        title: 'Color Dodge',
      },
      'divider' as const,
      {
        id: BlendModeType.Overlay,
        title: 'Overlay',
      },
      {
        id: BlendModeType.SoftLight,
        title: 'Soft Light',
      },
      {
        id: BlendModeType.HardLight,
        title: 'Hard Light',
      },
      'divider' as const,
      {
        id: BlendModeType.Difference,
        title: 'Difference',
      },
      {
        id: BlendModeType.Exclusion,
        title: 'Exclusion',
      },
      'divider' as const,
      {
        id: BlendModeType.Hue,
        title: 'Hue',
      },
      {
        id: BlendModeType.Saturation,
        title: 'Saturation',
      },
      {
        id: BlendModeType.Color,
        title: 'Color',
      },
      {
        id: BlendModeType.Luminosity,
        title: 'Luminosity',
      },
    ]

    return values
  }, [])

  return (
    <PropertyRowV2
      name="Layer"
      inputs={
        <div className="flex w-full flex-row justify-between">
          <Select
            className={classNames('flex-[2]', 'pl-[6px]')}
            onChange={(blendMode) =>
              layers.forEach((layer) => {
                layer.updateComponent(
                  BlendModeComponent,
                  blendMode as BlendModeType
                )
              })
            }
            activeValueId={blendingMode}
            options={blendingModeOptions}
            activeIcon={
              <div className="flex-shrink-0 flex-grow-0 pl-[4px]">
                <icons.propertiesPanel.BlendingMode
                  size={iconSize}
                  type={
                    blendingMode === BlendModeType.PassThrough ||
                    blendingMode === BlendModeType.Normal
                      ? 'normal'
                      : 'applied'
                  }
                />
              </div>
            }
          />

          <NumberValue
            components={properties}
            time={time}
            min={0}
            max={100}
            before={(v) => v * 100}
            after={(v) => v / 100}
            format={formatPercents}
          />

          <KeyframesPropertyControl
            components={properties}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        </div>
      }
    />
  )
}
