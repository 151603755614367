import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  value: string
  onChange: (value: string) => void
  isMultiline: boolean
}
export const TextInput: React.FCC<IProps> = ({
  value,
  onChange,
  isMultiline,
}) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  const handleAutoResize = () => {
    if (!inputRef || !inputRef.current) return

    if (!isMultiline) {
      inputRef.current.value = clearNewLines(inputRef.current.value)
      return
    }

    const element = inputRef.current
    element.style.height = '1px'
    element.style.height = `${element.scrollHeight}px`
  }

  React.useEffect(() => {
    if (isMultiline) handleAutoResize()
  }, [])

  const defaultValue = isMultiline ? value : clearNewLines(value)

  return (
    <textarea
      ref={inputRef}
      onInput={handleAutoResize}
      className={classNames(
        'resize-none  font-body text-sm font-normal py-1.5 px-2 rounded-md bg-gray-500/10',
        { ['!h-[32px]']: !isMultiline, ['h-max']: isMultiline }
      )}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  )
}

const clearNewLines = (value: string) => value.replaceAll('\n', ' ')

TextInput.displayName = 'TextInput'
