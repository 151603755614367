import {
  CurveStyles as CurveStylesComponent,
  PropertyRowV2,
} from '@aninix/app-design-system'
import { Popover } from '@material-ui/core'
import * as React from 'react'

import { EntityType, EntityTypeComponent, Project } from '@aninix-inc/model'
import { useEntities } from '@aninix/core'
import { EditCurveStyle } from '../../common/edit-curve-style'

const noop = () => {}

export interface IProps {
  project: Project
}
export const CurveStyles: React.FCC<IProps> = ({ project }) => {
  const [editingCurveId, setEditingCurveId] = React.useState<
    string | undefined
  >()
  const curveStyles = project.getEntitiesByPredicate(
    (entity) =>
      entity.getComponentOrThrow(EntityTypeComponent).value ===
      EntityType.CurveStyle
  )
  useEntities(curveStyles)

  const showEditCurve = React.useCallback((curveId: string) => {
    setEditingCurveId(curveId)
  }, [])

  const hideEditCurve = React.useCallback(() => {
    setEditingCurveId(undefined)
  }, [])

  const editingCurve =
    editingCurveId != null ? project.getEntity(editingCurveId) : undefined

  const userPresetsRef = React.useRef<any>()

  if (curveStyles.length === 0) {
    return null
  }

  return (
    <>
      <PropertyRowV2
        name="Curve Styles"
        inputs={
          <div className="w-full" ref={userPresetsRef}>
            <CurveStylesComponent
              curveStyles={curveStyles}
              onApply={noop}
              onEditPreset={showEditCurve}
            />
          </div>
        }
      />

      <Popover
        open={editingCurveId != null}
        anchorEl={userPresetsRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={hideEditCurve}
        disableRestoreFocus
      >
        {editingCurve != null && (
          <EditCurveStyle curveStyle={editingCurve} onClose={hideEditCurve} />
        )}
      </Popover>
    </>
  )
}
