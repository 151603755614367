import {
  CharactersComponent,
  EntityType,
  EntityTypeComponent,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  StyledTextSegmentRelationsAspect,
} from '@aninix-inc/model'
import * as React from 'react'
import { ComponentGroupPanel } from '../../components/component-group-panel'
import { useOverride } from '../use-override-item'
import { TextItem } from './texts/text-item'

function trimmed(string: string, maxCharacters: number): string {
  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + '...'
  }

  return string
}

export interface IProps {}
export const Texts: React.FCC<IProps> = () => {
  const {
    openedPaneles,
    updateOpenedPanels,
    data: { project },
  } = useOverride()

  const data = project
    .getEntitiesByPredicate(
      (e) =>
        e.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node &&
        e.getComponentOrThrow(NodeTypeComponent).value === NodeType.Text
    )
    .map((entity) => {
      return {
        type: 'text' as const,
        id: entity.id,
        name: trimmed(entity.getComponentOrThrow(NameComponent).value, 14),
        value: entity
          .getAspectOrThrow(StyledTextSegmentRelationsAspect)
          .getChildrenList()
          .map((child) => child.getComponentOrThrow(CharactersComponent).value)
          .join(''),
      }
    })

  if (data === undefined || data.length === 0) {
    return null
  }

  return (
    <div>
      <ComponentGroupPanel
        isOpen={openedPaneles.texts}
        toggleOpen={() => updateOpenedPanels({ texts: !openedPaneles.texts })}
        label="Texts"
      >
        <div className="flex flex-col gap-1">
          {data.map((item) => (
            <TextItem key={item.id} data={item} />
          ))}
        </div>
      </ComponentGroupPanel>
    </div>
  )
}

Texts.displayName = 'Texts'
