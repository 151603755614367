import {
  Entity,
  ParentRelationAspect,
  getPosition,
  getSize,
  setPosition,
} from '@aninix-inc/model'
import {
  Dropdown,
  NodesAlignType,
  NodesDistributionType,
  buttons,
  icons,
} from '@aninix/app-design-system'
import {
  KeyModificator,
  getBoundingBoxAtTimeV2,
  usePlayback,
  useSession,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

type BoundingBox = {
  x: number
  y: number
  width: number
  height: number
}

function getBoundingBox(payload: {
  layers: Entity[]
  time: number
}): BoundingBox {
  const { layers, time } = payload

  if (layers.length === 1) {
    const boundingBox = getBoundingBoxAtTimeV2({
      // @TODO: use helpder getParent(layer) instead
      entity: R.head(layers)!
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow(),
      time,
    })

    return {
      x: 0,
      y: 0,
      width: boundingBox.width,
      height: boundingBox.height,
    }
  }

  return layers.reduce<BoundingBox>(
    (box, entity) => {
      const nodeBoundingBox = getBoundingBoxAtTimeV2({
        entity,
        time,
      })

      const left: number = R.min(box.x, nodeBoundingBox.x)
      const top: number = R.min(box.y, nodeBoundingBox.y)
      const right: number = R.max(
        box.x + box.width,
        nodeBoundingBox.x + nodeBoundingBox.width
      )
      const bottom: number = R.max(
        box.y + box.height,
        nodeBoundingBox.y + nodeBoundingBox.height
      )

      return {
        x: left,
        y: top,
        width: Math.abs(right - left),
        height: Math.abs(bottom - top),
      }
    },
    getBoundingBoxAtTimeV2({
      entity: R.head(layers)!,
      time,
    })
  )
}

export interface IProps {
  layers: Entity[]
}
export const Align: React.FCC<IProps> = observer(({ layers }) => {
  const playback = usePlayback()
  const session = useSession()

  const updateNodePosition = React.useCallback(
    (payload: { entity: Entity; point: { x?: number; y?: number } }) => {
      if (payload.point.x == null && payload.point.y == null) {
        throw new TypeError('paylod.x or payload.y are required')
      }

      const position = getPosition(payload.entity, playback.time)

      const point = {
        x: payload.point.x == null ? position.x : payload.point.x,
        y: payload.point.y == null ? position.y : payload.point.y,
      }
      // @TODO: move logic into model
      const deltaX = position.x - point.x
      const deltaY = position.y - point.y

      setPosition(
        payload.entity,
        {
          x: point.x,
          y: point.y,
          tx1: position.tx1 + deltaX,
          ty1: position.ty1 + deltaY,
          tx2: position.tx2 + deltaX,
          ty2: position.ty2 + deltaY,
        },
        playback.time
      )
    },
    [playback]
  )

  // @NOTE: bounding box calculates correctly, but final coordinate not.
  // @TODO: fix final coordinate calculation.
  const align = React.useCallback(
    // @TODO: provide type
    ({ type }: any) => {
      const applyToNode = (
        entity: Entity,
        type: NodesAlignType,
        boundingBox: BoundingBox
      ) => {
        const size = getSize(entity, playback.time)
        const topLeftX = boundingBox.x
        const topLeftY = boundingBox.y
        const centerX = boundingBox.x + boundingBox.width / 2
        const centerY = boundingBox.y + boundingBox.height / 2
        const bottomRightX = boundingBox.x + boundingBox.width
        const bottomRightY = boundingBox.y + boundingBox.height
        if (type === NodesAlignType.HorizontalLeft) {
          updateNodePosition({
            entity,
            point: { x: topLeftX },
          })
          return
        }
        if (type === NodesAlignType.HorizontalLeftOutside) {
          updateNodePosition({
            entity,
            point: { x: -size.x },
          })
          return
        }
        if (type === NodesAlignType.HorizontalMiddle) {
          updateNodePosition({
            entity,
            point: { x: centerX - size.x / 2 },
          })
          return
        }
        if (type === NodesAlignType.HorizontalRight) {
          updateNodePosition({
            entity,
            point: { x: bottomRightX - size.x },
          })
          return
        }
        if (type === NodesAlignType.HorizontalRightOutside) {
          updateNodePosition({
            entity,
            point: { x: bottomRightX },
          })
          return
        }
        if (type === NodesAlignType.VerticalTop) {
          updateNodePosition({
            entity,
            point: { y: topLeftY },
          })
          return
        }
        if (type === NodesAlignType.VerticalTopOutside) {
          updateNodePosition({
            entity,
            point: { y: -size.y },
          })
          return
        }
        if (type === NodesAlignType.VerticalCenter) {
          updateNodePosition({
            entity,
            point: { y: centerY - size.y / 2 },
          })
          return
        }
        if (type === NodesAlignType.VerticalBottom) {
          updateNodePosition({
            entity,
            point: { y: bottomRightY - size.y },
          })
          return
        }
        if (type === NodesAlignType.VerticalBottomOutside) {
          updateNodePosition({
            entity,
            point: { y: bottomRightY },
          })
          return
        }
      }
      const boundingBox = getBoundingBox({
        layers: layers,
        time: playback.time,
      })
      layers.forEach((entity) => {
        applyToNode(entity, type, boundingBox)
      })
    },
    [layers, playback, updateNodePosition]
  )

  // @TODO: improve how distribute work. Now it's not looking for coordinates coorectly
  const distribute = React.useCallback(
    // @TODO: provide type
    ({ type }: any) => {
      if (layers.length <= 1) {
        return
      }
      const boundingBox = getBoundingBox({
        layers: layers,
        time: playback.time,
      })
      if (type === NodesDistributionType.Horizontally) {
        const sortedEntities = R.sort(
          (left, right) =>
            getPosition(left, playback.time).x -
            getPosition(right, playback.time).x,
          layers
        )
        sortedEntities.forEach((entity, idx) => {
          const topLeftX = boundingBox.x
          const bottomRightX = boundingBox.x + boundingBox.width
          const x =
            ((bottomRightX - topLeftX) / sortedEntities.length) * idx + topLeftX
          updateNodePosition({
            entity,
            point: {
              x,
            },
          })
        })
        return
      }
      if (type === NodesDistributionType.Vertically) {
        const sortedEntities = R.sort(
          (left, right) =>
            getPosition(left, playback.time).y -
            getPosition(right, playback.time).y,
          layers
        )
        sortedEntities.forEach((entity, idx) => {
          const topLeftY = boundingBox.y
          const bottomRightY = boundingBox.y + boundingBox.height
          const y =
            ((bottomRightY - topLeftY) / sortedEntities.length) * idx + topLeftY
          updateNodePosition({
            entity,
            point: {
              y,
            },
          })
        })
        return
      }
    },
    [playback, layers, updateNodePosition]
  )

  const isOutside = session.keyModificators.includes(KeyModificator.Alt)

  const [isDistributionVisible, setIsDistributionVisible] =
    React.useState(false)

  const openDistributionPopover = React.useCallback(() => {
    setIsDistributionVisible(true)
  }, [])

  const closeDistributionPopover = React.useCallback(() => {
    setIsDistributionVisible(false)
  }, [])

  const onClickAlignHorizontalLeft = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalLeft,
    })
  }, [align])

  const onClickAlignHorizontalLeftOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalLeftOutside,
    })
  }, [align])

  const onClickAlignHorizontalMiddle = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalMiddle,
    })
  }, [align])

  const onClickAlignHorizontalRight = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalRight,
    })
  }, [align])

  const onClickAlignHorizontalRightOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalRightOutside,
    })
  }, [align])

  const onClickAlignVerticalTop = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalTop,
    })
  }, [align])

  const onClickAlignVerticalTopOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalTopOutside,
    })
  }, [align])

  const onClickAlignVerticalCenter = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalCenter,
    })
  }, [align])

  const onClickAlignVerticalBottom = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalBottom,
    })
  }, [align])

  const onClickAlignVerticalBottomOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalBottomOutside,
    })
  }, [align])

  const onClickDistributeHorizontally = React.useCallback(() => {
    distribute({
      type: icons.propertiesPanel.NodesDistributionType.Horizontally,
    })
    closeDistributionPopover()
  }, [closeDistributionPopover])

  const onClickDistributeVertically = React.useCallback(() => {
    distribute({
      type: icons.propertiesPanel.NodesDistributionType.Vertically,
    })
    closeDistributionPopover()
  }, [closeDistributionPopover])

  const distributionContainerRef = React.useRef<HTMLDivElement | null>(null)

  return (
    <div className="border-b-solid flex flex-row flex-nowrap items-stretch justify-between border-b-[1px] border-b-[var(--aninix-color-foreground-black-05)] px-2 py-1">
      {isOutside ? (
        <buttons.Icon onClick={onClickAlignHorizontalRightOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalRightOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignHorizontalLeft}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalLeft}
          />
        </buttons.Icon>
      )}

      <buttons.Icon onClick={onClickAlignHorizontalMiddle}>
        <icons.propertiesPanel.NodesAlign
          type={icons.propertiesPanel.NodesAlignType.HorizontalMiddle}
        />
      </buttons.Icon>

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignHorizontalLeftOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalLeftOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignHorizontalRight}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalRight}
          />
        </buttons.Icon>
      )}

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignVerticalBottomOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalBottomOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignVerticalTop}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalTop}
          />
        </buttons.Icon>
      )}

      <buttons.Icon onClick={onClickAlignVerticalCenter}>
        <icons.propertiesPanel.NodesAlign
          type={icons.propertiesPanel.NodesAlignType.VerticalCenter}
        />
      </buttons.Icon>

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignVerticalTopOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalTopOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignVerticalBottom}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalBottom}
          />
        </buttons.Icon>
      )}

      <div ref={distributionContainerRef}>
        <buttons.Icon onClick={openDistributionPopover}>
          <div className="flex flex-row flex-nowrap items-center justify-center">
            <icons.propertiesPanel.NodesDistribution
              type={icons.propertiesPanel.NodesDistributionType.Horizontally}
            />

            <div className="ml-1 flex flex-col items-center justify-center">
              <icons.Dropdown />
            </div>
          </div>
        </buttons.Icon>

        <Dropdown
          anchorEl={distributionContainerRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isDistributionVisible}
          onClose={closeDistributionPopover}
          items={[
            {
              title: 'Distribute Horizontally',
              onClick: onClickDistributeHorizontally,
              icon: (
                <icons.propertiesPanel.NodesDistribution
                  type={
                    icons.propertiesPanel.NodesDistributionType.Horizontally
                  }
                  style={{
                    // @ts-ignore
                    '--figma-color-text': 'rgba(255, 255, 255, 0.9)',
                  }}
                />
              ),
              // @TODO: uncomment when hotkeys implemented
              // rightPart: '⌃⌥H',
            },
            {
              title: 'Distribute Vertically',
              onClick: onClickDistributeVertically,
              icon: (
                <icons.propertiesPanel.NodesDistribution
                  type={icons.propertiesPanel.NodesDistributionType.Vertically}
                  style={{
                    // @ts-ignore
                    '--figma-color-text': 'rgba(255, 255, 255, 0.9)',
                  }}
                />
              ),
              // @TODO: uncomment when hotkeys implemented
              // rightPart: '⌃⌥V',
            },
          ]}
        />
      </div>
    </div>
  )
})

Align.displayName = 'Align'
