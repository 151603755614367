import {
  Entity,
  Point2dKeyframe,
  SizeComponent,
  SizeLockedComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { CompactPropertyRow } from '@aninix/app-design-system'
import * as React from 'react'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export interface IProps {
  layers: Entity[]
  time: number
}
export const Size: React.FCC<IProps> = ({ layers, time }) => {
  const components = layers.map((l) => l.getComponentOrThrow(SizeComponent))

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <LockablePoint2dValue
            components={layers.map((l) => l.getComponentOrThrow(SizeComponent))}
            lockableComponents={layers.map((l) =>
              l.getComponentOrThrow(SizeLockedComponent)
            )}
            time={time}
            iconX={<span>W</span>}
            iconY={<span>H</span>}
            minX={0}
            minY={0}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}
