import {
  DashComponent,
  DashOffsetComponent,
  Entity,
  GapComponent,
  NumberKeyframe,
  disableDashes,
  enableDashes,
  getValueNumber,
  isDashesEnabled,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { Group } from '../../common/group'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const Dashes: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)

  const isEnabled = R.any(isDashesEnabled, layers)
  const enable = () =>
    layers.forEach((l) => {
      if (isDashesEnabled(l) === false) {
        enableDashes(l)
      }
    })
  const disable = () =>
    layers.forEach((l) => {
      if (isDashesEnabled(l)) {
        disableDashes(l)
      }
    })
  const dashComponents = layers.flatMap(
    (l) => l.getComponent(DashComponent) ?? []
  )
  const gapComponents = layers.flatMap(
    (l) => l.getComponent(GapComponent) ?? []
  )
  const dashOffsetComponents = layers.flatMap(
    (l) => l.getComponent(DashOffsetComponent) ?? []
  )

  return (
    <Group
      title="Dashes"
      isOpen={isEnabled}
      onClick={enable}
      headerButtons={
        isEnabled ? (
          <buttons.Icon
            btnSize={btnSize}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              disable()
            }}
            tooltip="Disable dashes"
          >
            <icons.Remove />
          </buttons.Icon>
        ) : (
          <buttons.Icon
            btnSize={btnSize}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              enable()
            }}
            tooltip="Enable dashes"
          >
            <icons.Add />
          </buttons.Icon>
        )
      }
    >
      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={dashComponents}
          icon={<span className="w-full pl-[4px]">Dash</span>}
          time={time}
          min={0}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={dashComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>

      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={gapComponents}
          icon={<span className="w-full pl-[4px]">Gap</span>}
          time={time}
          min={0}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={gapComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>

      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={dashOffsetComponents}
          icon={<span className="w-full pl-[4px]">Offset</span>}
          time={time}
          min={0}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={dashOffsetComponents}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>
    </Group>
  )
}
