import {
  DurationComponent,
  FpsComponent,
  Project,
  RgbaValueComponent,
  Root,
  TimeComponent,
  setEndTime,
} from '@aninix-inc/model'
import { PropertyRowV2, buttons, icons } from '@aninix/app-design-system'
import { getAllKeyframes, useEntity } from '@aninix/core'
import * as React from 'react'
import {
  MAX_DURATION,
  MAX_FPS,
  MIN_DURATION,
  MIN_FPS,
} from '../../../../defaults'
import { NumberValue } from '../values/number'
import { RgbaValue } from '../values/rgba'
import { TimeValue } from '../values/time'
import { CurveStyles } from './curve-styles'

const btnSize = {
  width: 32,
  height: 32,
}
const eyeIconSize = {
  x: 16,
  y: 16,
}

const iconSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  project: Project
}
export const ProjectProperties: React.FCC<IProps> = ({ project }) => {
  const root = project.getEntityByTypeOrThrow(Root)
  useEntity(root)

  const isDurationBiggerThanAnimation = true

  const fitDuration = React.useCallback(() => {
    const allKeyframes = getAllKeyframes(project)

    if (allKeyframes.length < 2) {
      return
    }

    const maxTime = allKeyframes.reduce(
      (max, keyframe) =>
        Math.max(max, keyframe.getComponentOrThrow(TimeComponent).value),
      -Infinity
    )
    setEndTime(root, maxTime)
  }, [project, root])

  return (
    <div className="flex w-full flex-col">
      <PropertyRowV2
        name="Background color"
        inputs={
          <RgbaValue
            components={[root.getComponentOrThrow(RgbaValueComponent)]}
          />
        }
      />

      <PropertyRowV2
        name="FPS (frames per second)"
        inputs={
          <NumberValue
            components={[root.getComponentOrThrow(FpsComponent)]}
            icon={<icons.Copy size={iconSize} />}
            min={MIN_FPS}
            max={MAX_FPS}
            dragDisabled
          />
        }
      />

      <PropertyRowV2
        name="Project duration"
        inputs={
          <div className="flex w-full flex-col gap-[8px]">
            <TimeValue
              components={[root.getComponentOrThrow(DurationComponent)]}
              icon={<icons.Time />}
              min={MIN_DURATION}
              max={MAX_DURATION}
              dragDisabled
            />
            {isDurationBiggerThanAnimation && (
              <buttons.Regular
                title="Fit to animation"
                onClick={fitDuration}
                variant="outlined"
                className="w-full"
              />
            )}
          </div>
        }
      />

      <CurveStyles project={project} />
    </div>
  )
}

ProjectProperties.displayName = 'ProjectProperties'
