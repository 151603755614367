import {
  ChildrenRelationsAspect,
  Entity,
  EntryComponent,
  MaskComponent,
  NodeColorComponent,
  ParentRelationAspect,
  PropertiesExpandedComponent,
  SelectionSystem,
  getNode,
} from '@aninix-inc/model'
import { TimelineTrack, buttons, icons } from '@aninix/app-design-system'
import { useEntity, useSystem } from '@aninix/core/updates'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { Spacer } from '../property'
import * as styles from './index.scss'

export interface IProps {
  propertyGroup: Entity
  name: string
  indent: number
}
export const PropertyGroup: React.FCC<IProps> = ({
  propertyGroup,
  name,
  indent: providedIndent,
}) => {
  const layer = getNode(propertyGroup)

  if (layer === undefined) {
    throw new Error('Invalid state. Node is not found')
  }

  useEntity(propertyGroup)
  const project = propertyGroup.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const isExpanded = propertyGroup.getComponentOrThrow(
    PropertiesExpandedComponent
  ).value

  const handleExpandToggle = () => {
    propertyGroup.updateComponent(PropertiesExpandedComponent, (v) => !v)
  }

  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    propertyGroup.components
  )

  // @NOTE: also used in ../property.
  // @TODO: extract to common place
  const maskType = (() => {
    const node = propertyGroup
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()

    if (node.hasComponent(EntryComponent)) {
      return 'none'
    }

    const children = layer.getAspect(ChildrenRelationsAspect)?.getChildrenList()

    if (children === undefined) {
      return 'none'
    }

    const maskIdx = children.findIndex((_node) =>
      _node.hasComponent(MaskComponent)
    )
    const hasMask = maskIdx !== -1

    if (!hasMask) {
      return 'none'
    }

    if (node.hasComponent(MaskComponent)) {
      return 'none'
    }

    const currentIdx = children.findIndex((_node) => _node.id === node.id)

    if (hasMask && currentIdx < maskIdx) {
      return 'in-scope'
    }

    return 'between'
  })()

  const indent = providedIndent - 1
  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue || isLayerSelectedValue) {
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    if (isComponentSelectedValue) {
      // return `${
      //   defaults.nodeColors[
      //     layer.getComponentOrThrow(NodeColorComponent).value
      //   ]
      // }35`
      return `${
        defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isLayerSelectedValue,
    isComponentSelectedValue,
  ])
  // const maskColor= nodeColors[propertyGroup.getNode().color as LayerColor]
  const maskColor = 'rgba(0, 0, 0, 0.1)'

  const baseIndent = 3

  return (
    <TimelineTrack
      isSelected={false}
      className="flex flex-row flex-nowrap items-center justify-start"
      color={color}
      variant="property"
      maskColor={maskColor}
    >
      {R.range(0, indent || 0).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      {maskType !== 'none' ? (
        <div
          className={classNames(
            'relative h-[18px] w-[20px] flex-shrink-0 p-0 px-[2px]',
            {
              [styles.mask__between]: maskType === 'between',
            }
          )}
        />
      ) : (
        <Spacer />
      )}

      {R.range(0, baseIndent).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      <div className={styles.component}>
        <buttons.Icon
          onClick={handleExpandToggle}
          btnSize={{
            width: 16,
            height: 16,
          }}
        >
          <icons.ExpandProperties
            type={
              isExpanded
                ? icons.NodeExpandType.Expanded
                : icons.NodeExpandType.Normal
            }
            size={{ x: 16, y: 16 }}
          />
        </buttons.Icon>
      </div>

      <p className={styles.name}>{name}</p>
    </TimelineTrack>
  )
}
