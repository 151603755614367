import {
  Entity,
  LayoutAspect,
  getAnchorPoint,
  getInitialRotation,
  getInitialScale,
  getPosition,
  getRotation,
  getScale,
} from '@aninix-inc/model'
import { Point1D, Point2D } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'

type Matrix = [number, number, number, number, number, number]

const rads = Math.PI / 180

function multiplyMatrixAndPoint(matrix: Matrix, point: Point2D): Point2D {
  const [a, b, c, d, e, f] = matrix
  const x = point.x
  const y = point.y
  const newX = a * x + c * y + e
  const newY = b * x + d * y + f
  return { x: newX, y: newY }
}

function multiplyMatrices(...matrices: Matrix[]): Matrix {
  let result: Matrix = [1, 0, 0, 1, 0, 0]

  for (const matrix of matrices) {
    const a = result[0]
    const b = result[1]
    const c = result[2]
    const d = result[3]
    const e = result[4]
    const f = result[5]

    const g = matrix[0]
    const h = matrix[1]
    const i = matrix[2]
    const j = matrix[3]
    const k = matrix[4]
    const l = matrix[5]

    result = [
      a * g + b * i,
      a * h + b * j,
      c * g + d * i,
      c * h + d * j,
      e * g + f * i + k,
      e * h + f * j + l,
    ]
  }

  return result
}

function TranslateMatrix(position: Point2D): Matrix {
  return [1, 0, 0, 1, position.x, position.y]
}

function SkewMatrix(skew: Point2D): Matrix {
  return [1, Math.tan(skew.y * rads), Math.tan(skew.x * rads), 1, 0, 0]
}

function ScaleMatrix(scale: Point2D): Matrix {
  return [scale.x, 0, 0, scale.y, 0, 0]
}

function RotateMatrix(rotation: Point1D): Matrix {
  const rotationInRadians = rotation.x * rads
  const cosTheta = Math.cos(rotationInRadians)
  const sinTheta = Math.sin(rotationInRadians)
  return [cosTheta, -sinTheta, sinTheta, cosTheta, 0, 0]
}

export function getTransformMatrixV2(payload: {
  entity: Entity
  time: number
  matrix?: paper.Matrix
  ignorePosition?: boolean
  ignoreScale?: boolean
}) {
  const {
    entity,
    matrix = new paper.Matrix(),
    time,
    ignorePosition = false,
    ignoreScale = false,
  } = payload

  const anchorPoint = getAnchorPoint(entity, time)
  // @NOTE: on big number of entities this ternary operator affect performance
  const position = ignorePosition
    ? {
        x: 0,
        y: 0,
      }
    : getPosition(entity, time)
  // const position = layoutAspect.position.getValue(time)
  const skew = entity.getAspectOrThrow(LayoutAspect).skew.getValue(time)
  // @NOTE: on big number of entities this ternary operator affect performance
  const scale = getScale(entity, ignoreScale ? undefined : time)
  // const scale = layoutAspect.scale.getValue(time)
  const rotation = getRotation(entity, time)
  const initialRotation = getInitialRotation(entity)
  const initialScale = getInitialScale(entity)

  const initialMatrix = multiplyMatrices(
    RotateMatrix({ x: initialRotation }),
    ScaleMatrix(initialScale)
  )
  const modifiedAnchorPoint = multiplyMatrixAndPoint(initialMatrix, anchorPoint)
  const transformedMatrix = multiplyMatrices(
    TranslateMatrix({ x: -anchorPoint.x, y: -anchorPoint.y }),
    RotateMatrix({ x: initialRotation }),
    SkewMatrix(skew),
    ScaleMatrix(scale),
    RotateMatrix({ x: rotation - initialRotation }),
    TranslateMatrix({ x: modifiedAnchorPoint.x, y: modifiedAnchorPoint.y }),
    TranslateMatrix(position)
  )
  return matrix.appended(new paper.Matrix(transformedMatrix))
}
