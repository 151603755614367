import {
  ChildrenRelationsAspect,
  clone,
  Entity,
  EntityType,
  EntityTypeComponent,
  getAnchorPoint,
  getPosition,
  getRotation,
  getScale,
  getSortedKeyframes,
  LayerColor,
  NodeColorComponent,
  NodeType,
  NodeTypeComponent,
  ParentRelationAspect,
  Point2D,
  PositionComponent,
  Project,
  RotationComponent,
  ScaleComponent,
  SelectionSystem,
  setAnimatableValue,
  setPosition,
  setRotation,
  setScale,
  TimeComponent,
  UndoRedoSystem,
  UpdatesSystem,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { useMouseMove } from '@aninix/app-design-system'
import {
  featureFlags,
  getAbsoluteTransformMatrixV2,
  KeyModificator,
  nodeColors,
  useEntities,
  useImagesStore,
  usePlayback,
  useProject,
  useSession,
  useSystem,
  useViewport,
} from '@aninix/core'
import { createCursor, removeCursor } from '@aninix/core/utils/cursor'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { getBoundsFromNodes } from '../svg-distances'
import { Transform } from './types'

export const getInitCursorRotation = (transform?: Transform) => {
  let cursorInitRotation = 0
  if (
    transform === Transform.SCALE_LEFT ||
    transform === Transform.SCALE_RIGHT ||
    transform === Transform.ROTATE_TOP_LEFT
  )
    cursorInitRotation = 90
  if (
    transform === Transform.SCALE_TOP_LEFT ||
    transform === Transform.SCALE_BOTTOM_RIGHT
  )
    cursorInitRotation = -45
  if (
    transform === Transform.SCALE_BOTTOM_LEFT ||
    transform === Transform.SCALE_TOP_RIGHT
  )
    cursorInitRotation = 45
  if (transform === Transform.ROTATE_BOTTOM_RIGHT) cursorInitRotation = 180
  if (transform === Transform.ROTATE_BOTTOM_LEFT) cursorInitRotation = 270
  return cursorInitRotation
}

function getAngle(
  dragStart: paper.Point,
  center: paper.Point,
  dragEnd: paper.Point,
  isShiftPressed: boolean
) {
  const circle = new paper.Shape.Circle(
    center,
    Math.max(center.getDistance(dragStart), 20)
  ).toPath()

  const length = circle.length

  const start = circle.getNearestLocation(dragStart).offset
  const end = circle.getNearestLocation(dragEnd).offset

  let angle = ((end - start) / length) * 360

  if (isShiftPressed) {
    angle = Math.round(angle / 15) * 15
  }

  return {
    circle,
    angle,
    startPosition: circle.getNearestLocation(dragStart).point,
    endPosition: circle.getNearestLocation(dragEnd).point,
  }
}

const getPivot = ({
  isAltPressed,
  currentTransform,
  dragStartBounds,
}: {
  isAltPressed: boolean
  currentTransform: Transform
  dragStartBounds: paper.Rectangle
}): paper.Point => {
  if (isAltPressed) return new paper.Point(0, 0)

  switch (currentTransform) {
    case Transform.SCALE_TOP_LEFT:
      return new paper.Point(
        dragStartBounds.width / 2,
        dragStartBounds.height / 2
      )
    case Transform.SCALE_TOP_RIGHT:
      return new paper.Point(
        -dragStartBounds.width / 2,
        dragStartBounds.height / 2
      )
    case Transform.SCALE_BOTTOM_LEFT:
      return new paper.Point(
        dragStartBounds.width / 2,
        -dragStartBounds.height / 2
      )
    case Transform.SCALE_BOTTOM_RIGHT:
      return new paper.Point(
        -dragStartBounds.width / 2,
        -dragStartBounds.height / 2
      )
    case Transform.SCALE_TOP:
      return new paper.Point(0, dragStartBounds.height / 2)
    case Transform.SCALE_BOTTOM:
      return new paper.Point(0, -dragStartBounds.height / 2)
    case Transform.SCALE_LEFT:
      return new paper.Point(dragStartBounds.width / 2, 0)
    case Transform.SCALE_RIGHT:
      return new paper.Point(-dragStartBounds.width / 2, 0)

    default:
      throw Error(`Pivot point ${currentTransform} is not set before dragging`)
  }
}

const calculateSize = ({
  isAltPressed,
  isShiftPressed,
  dragStartBounds,
  currentTransform,
  zoom,
  offsetX,
  offsetY,
  rotation,
}: {
  isAltPressed: boolean
  isShiftPressed: boolean
  dragStartBounds: paper.Rectangle
  currentTransform: Transform
  zoom: number
  offsetX: number
  offsetY: number
  rotation: number
}) => {
  const size = dragStartBounds.size.clone()
  const altFactor = isAltPressed ? 2 : 1

  const deltaX = (offsetX / zoom) * altFactor
  const deltaY = (offsetY / zoom) * altFactor

  const rotatedOffset = new paper.Point(deltaX, deltaY).rotate(
    -rotation,
    new paper.Point(0, 0)
  )

  if (isShiftPressed) {
    const maxDelta = Math.max(
      Math.abs(rotatedOffset.x),
      Math.abs(rotatedOffset.y)
    )
    const scaleDeltaX = (rotatedOffset.x < 0 ? -1 : 1) * maxDelta
    const scaleDeltaY = (rotatedOffset.y < 0 ? -1 : 1) * maxDelta

    if (
      currentTransform.includes('left') ||
      currentTransform.includes('right')
    ) {
      size.width += scaleDeltaX
    }
    if (
      currentTransform.includes('top') ||
      currentTransform.includes('bottom')
    ) {
      size.height += scaleDeltaY
    }

    if (
      currentTransform.includes('left') ||
      currentTransform.includes('right')
    ) {
      size.height =
        (dragStartBounds.height / dragStartBounds.width) * size.width
    }

    if (
      currentTransform.includes('top') ||
      currentTransform.includes('bottom')
    ) {
      size.width =
        (dragStartBounds.width / dragStartBounds.height) * size.height
    }
  } else {
    if (currentTransform.includes('left')) {
      size.width += -rotatedOffset.x
    }
    if (currentTransform.includes('right')) {
      size.width += rotatedOffset.x
    }

    if (currentTransform.includes('top')) {
      size.height += -rotatedOffset.y
    }
    if (currentTransform.includes('bottom')) {
      size.height += rotatedOffset.y
    }
  }

  return size
}

enum AlignmentType {
  LEFT_TO_LEFT = 'LEFT_TO_LEFT',
  LEFT_TO_RIGHT = 'LEFT_TO_RIGHT',
  LEFT_TO_CENTER_X = 'LEFT_TO_CENTER_X',
  RIGHT_TO_RIGHT = 'RIGHT_TO_RIGHT',
  RIGHT_TO_LEFT = 'RIGHT_TO_LEFT',
  RIGHT_TO_CENTER_X = 'RIGHT_TO_CENTER_X',
  TOP_TO_TOP = 'TOP_TO_TOP',
  TOP_TO_BOTTOM = 'TOP_TO_BOTTOM',
  TOP_TO_CENTER_Y = 'TOP_TO_CENTER_Y',
  BOTTOM_TO_BOTTOM = 'BOTTOM_TO_BOTTOM',
  BOTTOM_TO_TOP = 'BOTTOM_TO_TOP',
  BOTTOM_TO_CENTER_Y = 'BOTTOM_TO_CENTER_Y',
  CENTER_Y = 'CENTER_Y',
  CENTER_Y_TO_TOP = 'CENTER_Y_TO_TOP',
  CENTER_Y_TO_BOTTOM = 'CENTER_Y_TO_BOTTOM',
  CENTER_X = 'CENTER_X',
  CENTER_X_TO_LEFT = 'CENTER_X_TO_LEFT',
  CENTER_X_TO_RIGHT = 'CENTER_X_TO_RIGHT',
}

const snapToNearbyNodes = (
  currentBounds: paper.Shape,
  targetBounds: paper.Rectangle[],
  currentTransform: Transform,
  zoom: number
) => {
  const threshold = 10 / zoom
  const alignmentDiff = new paper.Point(0, 0)
  const snapGuides: {
    x1: number
    y1: number
    x2: number
    y2: number
    type: AlignmentType
  }[] = []

  const horizontalAlignments: {
    diff: number
    target: number
    current: number
    type: AlignmentType
  }[] = []
  const verticalAlignments: {
    diff: number
    target: number
    current: number
    type: AlignmentType
  }[] = []

  const checkAlignment = (
    current: number,
    target: number,
    isHorizontal: boolean,
    type: AlignmentType
  ) => {
    const diff = Math.abs(current - target)
    if (diff < threshold) {
      const alignment = { diff, target, current, type }
      if (isHorizontal) {
        horizontalAlignments.push(alignment)
      } else {
        verticalAlignments.push(alignment)
      }
      return true
    }
    return false
  }

  targetBounds.forEach((bounds) => {
    checkAlignment(
      currentBounds.bounds.left,
      bounds.left,
      true,
      AlignmentType.LEFT_TO_LEFT
    )
    checkAlignment(
      currentBounds.bounds.right,
      bounds.right,
      true,
      AlignmentType.RIGHT_TO_RIGHT
    )
    checkAlignment(
      currentBounds.bounds.top,
      bounds.top,
      false,
      AlignmentType.TOP_TO_TOP
    )
    checkAlignment(
      currentBounds.bounds.bottom,
      bounds.bottom,
      false,
      AlignmentType.BOTTOM_TO_BOTTOM
    )
    checkAlignment(
      currentBounds.bounds.center.x,
      bounds.center.x,
      true,
      AlignmentType.CENTER_X
    )
    checkAlignment(
      currentBounds.bounds.center.y,
      bounds.center.y,
      false,
      AlignmentType.CENTER_Y
    )
    checkAlignment(
      currentBounds.bounds.left,
      bounds.center.x,
      true,
      AlignmentType.LEFT_TO_CENTER_X
    )
    checkAlignment(
      currentBounds.bounds.right,
      bounds.center.x,
      true,
      AlignmentType.RIGHT_TO_CENTER_X
    )
    checkAlignment(
      currentBounds.bounds.top,
      bounds.center.y,
      false,
      AlignmentType.TOP_TO_CENTER_Y
    )
    checkAlignment(
      currentBounds.bounds.bottom,
      bounds.center.y,
      false,
      AlignmentType.BOTTOM_TO_CENTER_Y
    )
    checkAlignment(
      currentBounds.bounds.center.x,
      bounds.left,
      true,
      AlignmentType.CENTER_X_TO_LEFT
    )
    checkAlignment(
      currentBounds.bounds.center.x,
      bounds.right,
      true,
      AlignmentType.CENTER_X_TO_RIGHT
    )
    checkAlignment(
      currentBounds.bounds.center.y,
      bounds.top,
      false,
      AlignmentType.CENTER_Y_TO_TOP
    )
    checkAlignment(
      currentBounds.bounds.center.y,
      bounds.bottom,
      false,
      AlignmentType.CENTER_Y_TO_BOTTOM
    )
    checkAlignment(
      currentBounds.bounds.top,
      bounds.bottom,
      false,
      AlignmentType.TOP_TO_BOTTOM
    )
    checkAlignment(
      currentBounds.bounds.bottom,
      bounds.top,
      false,
      AlignmentType.BOTTOM_TO_TOP
    )
    checkAlignment(
      currentBounds.bounds.left,
      bounds.right,
      true,
      AlignmentType.LEFT_TO_RIGHT
    )
    checkAlignment(
      currentBounds.bounds.right,
      bounds.left,
      true,
      AlignmentType.RIGHT_TO_LEFT
    )
  })

  const applyAlignment = (
    alignments: typeof horizontalAlignments,
    isHorizontal: boolean
  ) => {
    if (alignments.length > 0) {
      const bestAlignment = alignments.reduce(
        (best, current) => (current.diff < best.diff ? current : best),
        alignments[0]
      )
      if (isHorizontal) {
        alignmentDiff.x = bestAlignment.target - bestAlignment.current
      } else {
        alignmentDiff.y = bestAlignment.target - bestAlignment.current
      }
      return bestAlignment
    }
    return null
  }

  let bestHorizontal = null
  let bestVertical = null

  if (currentTransform === Transform.POSITION) {
    bestHorizontal = applyAlignment(horizontalAlignments, true)
    bestVertical = applyAlignment(verticalAlignments, false)
  } else if (currentTransform.includes('scale')) {
    const isInvertedX = currentBounds.scaling.x < 0
    const isInvertedY = currentBounds.scaling.y < 0

    if (
      currentTransform.includes('left') ||
      currentTransform.includes('right')
    ) {
      const relevantAlignments = horizontalAlignments.filter(
        (a) =>
          (currentTransform.includes('left') &&
            !isInvertedX &&
            a.type.includes('LEFT')) ||
          (currentTransform.includes('right') &&
            !isInvertedX &&
            a.type.includes('RIGHT')) ||
          (currentTransform.includes('left') &&
            isInvertedX &&
            a.type.includes('RIGHT')) ||
          (currentTransform.includes('right') &&
            isInvertedX &&
            a.type.includes('LEFT'))
      )
      bestHorizontal = applyAlignment(relevantAlignments, true)
    }
    if (
      currentTransform.includes('top') ||
      currentTransform.includes('bottom')
    ) {
      const relevantAlignments = verticalAlignments.filter(
        (a) =>
          (currentTransform.includes('top') &&
            !isInvertedY &&
            a.type.includes('TOP')) ||
          (currentTransform.includes('bottom') &&
            !isInvertedY &&
            a.type.includes('BOTTOM')) ||
          (currentTransform.includes('top') &&
            isInvertedY &&
            a.type.includes('BOTTOM')) ||
          (currentTransform.includes('bottom') &&
            isInvertedY &&
            a.type.includes('TOP'))
      )
      bestVertical = applyAlignment(relevantAlignments, false)
    }
  }

  const checkExactMatch = (
    alignment: (typeof horizontalAlignments)[0],
    isHorizontal: boolean
  ) => {
    const adjustedCurrent =
      alignment.current + (isHorizontal ? alignmentDiff.x : alignmentDiff.y)
    if (Math.abs(alignment.target - adjustedCurrent) < 0.1) {
      snapGuides.push({
        x1: isHorizontal
          ? alignment.target
          : Math.min(
              currentBounds.bounds.left,
              ...targetBounds.map((b) => b.left)
            ),
        x2: isHorizontal
          ? alignment.target
          : Math.max(
              currentBounds.bounds.right,
              ...targetBounds.map((b) => b.right)
            ),
        y1: isHorizontal
          ? Math.min(
              currentBounds.bounds.top,
              ...targetBounds.map((b) => b.top)
            )
          : alignment.target,
        y2: isHorizontal
          ? Math.max(
              currentBounds.bounds.bottom,
              ...targetBounds.map((b) => b.bottom)
            )
          : alignment.target,
        type: alignment.type,
      })
    }
  }

  if (bestHorizontal) {
    const adjustedX = bestHorizontal.target
    snapGuides.push({
      x1: adjustedX,
      x2: adjustedX,
      y1: Math.min(
        currentBounds.bounds.top + alignmentDiff.y,
        ...targetBounds.map((b) => b.top)
      ),
      y2: Math.max(
        currentBounds.bounds.bottom + alignmentDiff.y,
        ...targetBounds.map((b) => b.bottom)
      ),
      type: bestHorizontal.type,
    })
    horizontalAlignments.forEach((alignment) => {
      if (alignment !== bestHorizontal) {
        checkExactMatch(alignment, true)
      }
    })
  }
  if (bestVertical) {
    const adjustedY = bestVertical.target
    snapGuides.push({
      x1: Math.min(
        currentBounds.bounds.left + alignmentDiff.x,
        ...targetBounds.map((b) => b.left)
      ),
      x2: Math.max(
        currentBounds.bounds.right + alignmentDiff.x,
        ...targetBounds.map((b) => b.right)
      ),
      y1: adjustedY,
      y2: adjustedY,
      type: bestVertical.type,
    })
    verticalAlignments.forEach((alignment) => {
      if (alignment !== bestVertical) {
        checkExactMatch(alignment, false)
      }
    })
  }

  return { alignmentDiff, snapGuides }
}
const isVisibleNode = (entity: Entity): boolean =>
  entity.hasComponent(EntityTypeComponent) &&
  entity.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node &&
  entity.getComponentOrThrow(VisibleInViewportComponent).value

const getVisibleChildren = (node: Entity): Entity[] =>
  node.hasAspect(ChildrenRelationsAspect)
    ? node
        .getAspectOrThrow(ChildrenRelationsAspect)
        .getChildrenList()
        .filter(isVisibleNode)
    : []

const removeDescendantsRecursively = (
  node: Entity,
  allNodes: Set<Entity>
): void => {
  allNodes.delete(node)
  getVisibleChildren(node).forEach((child) =>
    removeDescendantsRecursively(child, allNodes)
  )
}

const getAllVisibleNodesWithoutDescendants = (
  project: Project,
  entities: Entity[]
): Entity[] => {
  const allNodes = new Set(project.getEntitiesByPredicate(isVisibleNode))

  entities.forEach((entity) => removeDescendantsRecursively(entity, allNodes))

  return Array.from(allNodes)
}

export const SvgTransform: React.FCC = observer(() => {
  const session = useSession()
  const playback = usePlayback()
  const images = useImagesStore()
  const viewport = useViewport()
  const project = useProject()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)
  useSystem(selection)

  const [entities, setEntities] = React.useState<Entity[]>([])
  const updateKey = useEntities(entities)

  const { startListen, isListening, offsetX, offsetY } = useMouseMove()

  const [dragStartPoint, setDragStartPoint] = React.useState<
    paper.Point | undefined
  >(undefined)

  const [dragStartBounds, setDragStartBounds] = React.useState(
    new paper.Rectangle(0, 0, 0, 0)
  )
  const [dragStartMatrix, setDragStartMatrix] = React.useState(
    new paper.Matrix()
  )

  const vertexSize = 7 / viewport.zoom
  const rotationHitSlop = 20 / viewport.zoom
  const sideHitSlop = 10 / viewport.zoom
  const sideSize = 1 / viewport.zoom

  const [currentTransform, setCurrentTransform] = React.useState<Transform>()

  const initScale = React.useRef<Point2D>({ x: 0, y: 0 })
  const initPosition = React.useRef<Point2D>({ x: 0, y: 0 })
  const initRotation = React.useRef<number>(0)

  const [color, setColor] = React.useState<string>('')

  const [boundsRect, setBoundsRect] = React.useState<paper.Shape>()

  const [isMatrixApplied, setIsMatrixApplied] = React.useState(true)

  const [duplicated, setDuplicated] = React.useState<boolean>(false)

  const [wasTriggered, setWasTriggered] = React.useState<boolean>(false)

  const memoizedAllBounds = React.useMemo(() => {
    if (!boundsRect) return []
    const allBounds = getAllVisibleNodesWithoutDescendants(
      project,
      selection.getEntitiesByEntityType(EntityType.Node)
    )
      .filter(
        (entity) =>
          entity.getComponentOrThrow(NodeTypeComponent).value !== NodeType.Group
      )
      .map((entity) => getBoundsFromNodes([entity], playback.time, images))
      .filter((bounds) => bounds !== undefined)

    return allBounds
  }, [
    project,
    selection,
    playback.time,
    boundsRect,
    duplicated,
    isMatrixApplied,
    images,
  ])

  const createBoundsRectangle = React.useCallback(() => {
    const selected = selection.getEntitiesByEntityType(EntityType.Node)
    const bounds = getBoundsFromNodes(selected, playback.time, images, true)

    if (!bounds) {
      setBoundsRect(undefined)
      setEntities([])
      return
    }

    const layerColor =
      (selected[0].getComponent(NodeColorComponent)?.value as LayerColor) ??
      nodeColors.BLUE

    const shape = new paper.Shape.Rectangle(bounds)

    if (selected.length === 1) {
      const matrix = getAbsoluteTransformMatrixV2({
        entity: selected[0],
        time: playback.time,
      })
      shape.rotation = matrix.rotation
      const anchorPoint = getAnchorPoint(selected[0], playback.time)
      const localAnchorPoint = new paper.Point(anchorPoint).multiply(
        getScale(selected[0], playback.time)
      )

      if (matrix.scaling.x < 0) localAnchorPoint.x += shape.bounds.width
      if (matrix.scaling.y < 0) localAnchorPoint.y += shape.bounds.height

      shape.pivot = new paper.Point(localAnchorPoint).add(
        shape.internalBounds.topLeft.subtract(shape.internalBounds.center)
      )
    }

    setEntities(selected)
    setColor(nodeColors[layerColor])
    setBoundsRect(shape)
  }, [selection, playback.time, images])

  React.useEffect(() => {
    if (isMatrixApplied) createBoundsRectangle()
  }, [updateKey, isMatrixApplied, playback.time])

  React.useEffect(() => {
    return selection.onSelectionUpdate(createBoundsRectangle)
  }, [selection, createBoundsRectangle])

  const internalBounds =
    boundsRect?.internalBounds ?? new paper.Rectangle(0, 0, 0, 0)

  const setCursor = React.useCallback(
    (cursor: Parameters<typeof createCursor>[0], transform?: Transform) => {
      const rotation =
        getInitCursorRotation(transform ?? currentTransform) +
        (boundsRect?.rotation ?? 0)

      createCursor(cursor, rotation, document.getElementById('stage'))
    },
    [currentTransform, boundsRect]
  )

  const clearCursor = React.useCallback(() => {
    removeCursor(document.getElementById('stage'))
  }, [])

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      if (!boundsRect) return
      e.stopPropagation()

      startListen(e)

      setCurrentTransform(e.currentTarget.dataset.transformType as Transform)

      setDragStartPoint(
        new paper.Point(
          parseFloat(e.currentTarget.getAttribute('x') ?? '0'),
          parseFloat(e.currentTarget.getAttribute('y') ?? '0')
        )
      )

      setIsMatrixApplied(false)

      setDragStartBounds(internalBounds)
      setDragStartMatrix(boundsRect.matrix.clone())

      initScale.current = getScale(entities[0], playback.time)
      initPosition.current = getPosition(entities[0], playback.time)
      initRotation.current = getRotation(entities[0], playback.time)
    },
    [playback.time, internalBounds, entities]
  )

  const applyTransforms = React.useCallback(() => {
    const selected = selection.getEntitiesByEntityType(EntityType.Node)

    updates.batch(() => {
      selected.forEach((entity) => {
        if (!boundsRect) return

        const parent = entities[0]
          .getAspect(ParentRelationAspect)
          ?.getParentEntity()
        const parentMatrix = parent
          ? getAbsoluteTransformMatrixV2({
              entity: parent,
              time: playback.time,
            })
          : new paper.Matrix()

        const scaleX = initScale.current.x * boundsRect.scaling.x
        const scaleY = initScale.current.y * boundsRect.scaling.y

        if (
          +scaleX.toFixed(4) !== initScale.current.x ||
          +scaleY.toFixed(4) !== initScale.current.y
        )
          setScale(
            entity,
            {
              x: scaleX,
              y: scaleY,
            },
            playback.time
          )

        const parentMatrixRotation = parentMatrix.rotation

        const rotation = parentMatrixRotation - boundsRect.rotation

        if (+rotation.toFixed(4) !== initRotation.current)
          setRotation(entity, rotation, playback.time)

        const pivot = getAnchorPoint(entity, playback.time)

        const newPosition = parentMatrix.inverseTransform(
          new paper.Point(
            boundsRect.position.x,
            boundsRect.position.y
          ).subtract(pivot)
        )

        if (
          +newPosition.x.toFixed(4) !== initPosition.current.x ||
          +newPosition.y.toFixed(4) !== initPosition.current.y
        )
          setPosition(
            entity,
            {
              x: newPosition.x,
              y: newPosition.y,
              tx1: newPosition.x,
              ty1: newPosition.y,
              tx2: newPosition.x,
              ty2: newPosition.y,
            },
            playback.time
          )
      })
    })
  }, [selection, currentTransform, dragStartMatrix, playback.time])

  const commitTransforms = React.useCallback(() => {
    updates.batch(() => {
      if (!wasTriggered) return
      entities.forEach((entity) => {
        if (!currentTransform?.includes('scale')) return

        const scaleComponent = entity.getComponentOrThrow(ScaleComponent)
        const scaleKeyframes = getSortedKeyframes(scaleComponent)
        if (!scaleKeyframes.length) return

        const rotationComponent = entity.getComponentOrThrow(RotationComponent)
        const rotationKeyframes = getSortedKeyframes(rotationComponent)

        const positionComponent = entity.getComponentOrThrow(PositionComponent)
        const positionKeyframes = getSortedKeyframes(positionComponent)

        if (rotationKeyframes.length && positionKeyframes.length) return

        const closestLeftKeyframe = [scaleKeyframes, positionKeyframes]
          .flat()
          .reduce<Entity | null>((prev, curr) => {
            const currTime = curr.getComponentOrThrow(TimeComponent).value
            return currTime < playback.time &&
              (!prev ||
                currTime > prev.getComponentOrThrow(TimeComponent).value)
              ? curr
              : prev
          }, null)
        if (!closestLeftKeyframe) return

        const currentRotation = getRotation(entity, playback.time)
        const hasRotationChanged = initRotation.current !== currentRotation

        const currentPosition = getPosition(entity, playback.time)
        const hasPositionChanged = initPosition.current !== currentPosition
        if (!hasRotationChanged && !hasPositionChanged) return

        const leftKeyframeTime =
          closestLeftKeyframe.getComponentOrThrow(TimeComponent).value

        if (hasRotationChanged) {
          if (!rotationKeyframes.length)
            setAnimatableValue(
              rotationComponent,
              currentRotation,
              playback.time,
              true
            )
          setAnimatableValue(
            rotationComponent,
            initRotation.current,
            leftKeyframeTime,
            true
          )
        }

        if (hasPositionChanged) {
          if (!positionKeyframes.length)
            setAnimatableValue(
              positionComponent,
              currentPosition,
              playback.time,
              true
            )
          setAnimatableValue(
            positionComponent,
            initPosition.current,
            leftKeyframeTime,
            true
          )
        }

        setAnimatableValue(
          scaleComponent,
          initScale.current,
          leftKeyframeTime,
          true
        )
      })
    })

    undoRedo.commitUndo()

    setIsMatrixApplied(true)
    setCurrentTransform(undefined)
    setDragStartPoint(undefined)
    clearCursor()
    createBoundsRectangle()
    setDuplicated(false)
    setWasTriggered(false)
    initRotation.current = getRotation(entities[0], playback.time)
    initScale.current = getScale(entities[0], playback.time)
    initPosition.current = getPosition(entities[0], playback.time)
  }, [
    undoRedo,
    entities,
    playback.time,
    project,
    updates,
    wasTriggered,
    currentTransform,
  ])

  const [snapGuides, setSnapGuides] = React.useState<
    { x1: number; y1: number; x2: number; y2: number }[]
  >([])

  React.useEffect(() => {
    if (!isListening && !isMatrixApplied) commitTransforms()
    if (!boundsRect || !isListening || !currentTransform) return
    if (Math.max(Math.abs(offsetX), Math.abs(offsetY)) > 3)
      setWasTriggered(true)
    if (!wasTriggered) return

    const isAltPressed = session.keyModificators.includes(KeyModificator.Alt)
    const isShiftPressed = session.keyModificators.includes(
      KeyModificator.Shift
    )

    if (currentTransform === Transform.POSITION) {
      if (isAltPressed && !duplicated) {
        clone(entities[0], project)
        setDuplicated(true)
      }

      const matrix = dragStartMatrix.clone()
      const rotation = matrix.rotation

      const offsetVector = new paper.Point(
        offsetX / viewport.zoom,
        offsetY / viewport.zoom
      )

      const rotatedOffset = offsetVector.rotate(
        -rotation,
        new paper.Point(0, 0)
      )

      matrix.translate(rotatedOffset)

      boundsRect.matrix = matrix

      const { alignmentDiff, snapGuides: alignmentLines } = snapToNearbyNodes(
        boundsRect,
        memoizedAllBounds,
        currentTransform,
        viewport.zoom
      )

      boundsRect.position = boundsRect.position.add(alignmentDiff)

      setSnapGuides(alignmentLines)
      applyTransforms()
    }

    if (currentTransform.includes('scale')) {
      setCursor('scale', currentTransform)

      const newSize = calculateSize({
        isAltPressed,
        isShiftPressed,
        dragStartBounds,
        currentTransform,
        zoom: viewport.zoom,
        offsetX,
        offsetY,
        rotation: dragStartMatrix.rotation,
      })

      const scaleX = newSize.width / dragStartBounds.width
      const scaleY = newSize.height / dragStartBounds.height

      const pivotPoint = getPivot({
        isAltPressed,
        currentTransform,
        dragStartBounds,
      })

      const tempBounds = boundsRect.clone()
      tempBounds.matrix = dragStartMatrix
        .clone()
        .scale(scaleX, scaleY, pivotPoint)

      const { alignmentDiff, snapGuides: alignmentLines } = snapToNearbyNodes(
        tempBounds,
        memoizedAllBounds,
        currentTransform,
        viewport.zoom
      )

      const isInvertedX = scaleX < 0
      const isInvertedY = scaleY < 0

      let adjustedWidth = tempBounds.bounds.width
      let adjustedHeight = tempBounds.bounds.height

      if (currentTransform.includes('left')) {
        adjustedWidth -= alignmentDiff.x * (isInvertedX ? -1 : 1)
      } else if (currentTransform.includes('right')) {
        adjustedWidth += alignmentDiff.x * (isInvertedX ? -1 : 1)
      }

      if (currentTransform.includes('top')) {
        adjustedHeight -= alignmentDiff.y * (isInvertedY ? -1 : 1)
      } else if (currentTransform.includes('bottom')) {
        adjustedHeight += alignmentDiff.y * (isInvertedY ? -1 : 1)
      }

      const finalScaleX =
        (adjustedWidth / dragStartBounds.width) * Math.sign(scaleX)
      const finalScaleY =
        (adjustedHeight / dragStartBounds.height) * Math.sign(scaleY)

      boundsRect.matrix = dragStartMatrix
        .clone()
        .scale(finalScaleX, finalScaleY, pivotPoint)

      setSnapGuides(alignmentLines)
      applyTransforms()
    }

    if (currentTransform.includes('rotate')) {
      if (!dragStartPoint) return
      setCursor('rotate', currentTransform)

      const endPoint = new paper.Point(
        dragStartPoint.x + offsetX / viewport.zoom,
        dragStartPoint.y + offsetY / viewport.zoom
      )

      const { angle, circle, startPosition, endPosition } = getAngle(
        dragStartPoint,
        boundsRect.position,
        endPoint,
        isShiftPressed
      )

      setDebugCircle(circle)
      setDebugPoints([startPosition, endPosition, dragStartPoint, endPoint])

      boundsRect.matrix = dragStartMatrix
        .clone()
        .rotate(angle, boundsRect.pivot)

      applyTransforms()
    }
  }, [
    dragStartMatrix,
    dragStartBounds,
    currentTransform,
    isListening,
    wasTriggered,
    offsetX,
    offsetY,
    session.keyModificators,
    duplicated,
    memoizedAllBounds,
    boundsRect,
  ])

  const [debugCircle, setDebugCircle] = React.useState(new paper.Path())
  const [debugPoints, setDebugPoints] = React.useState<paper.Point[]>([])

  if (!boundsRect) return null

  const displayBounds = {
    topLeft: internalBounds.topLeft.transform(boundsRect.matrix),
    topRight: internalBounds.topRight.transform(boundsRect.matrix),
    bottomLeft: internalBounds.bottomLeft.transform(boundsRect.matrix),
    bottomRight: internalBounds.bottomRight.transform(boundsRect.matrix),
  }

  return (
    <g>
      {
        /*debug*/
        featureFlags.transformSvgDebug &&
          currentTransform?.includes('rotate') && (
            <>
              {debugPoints.map((point, idx) => (
                <circle
                  key={idx}
                  cx={point.x}
                  cy={point.y}
                  r={1}
                  fill={color}
                />
              ))}
              <path
                d={debugCircle.pathData}
                stroke={color}
                strokeWidth={1}
                strokeOpacity={0.5}
                fill="transparent"
              />
            </>
          )
      }
      <polygon
        points={`${displayBounds.topLeft.x},${displayBounds.topLeft.y} ${displayBounds.topRight.x},${displayBounds.topRight.y} ${displayBounds.bottomRight.x},${displayBounds.bottomRight.y} ${displayBounds.bottomLeft.x},${displayBounds.bottomLeft.y}`}
        width={Math.abs(displayBounds.topLeft.x - displayBounds.topRight.x)}
        height={Math.abs(displayBounds.topLeft.y - displayBounds.bottomRight.y)}
        fill={'transparent'}
        data-transform-type={Transform.POSITION}
        onMouseDown={handleMouseDown}
        pointerEvents={
          entities[0] && session.buffer.includes(entities[0].id)
            ? 'auto'
            : 'none'
        }
      />
      {[
        {
          type: Transform.SCALE_TOP,
          x1: displayBounds.topLeft.x,
          y1: displayBounds.topLeft.y,
          x2: displayBounds.topRight.x,
          y2: displayBounds.topRight.y,
        },
        {
          type: Transform.SCALE_BOTTOM,
          x1: displayBounds.bottomLeft.x,
          y1: displayBounds.bottomLeft.y,
          x2: displayBounds.bottomRight.x,
          y2: displayBounds.bottomRight.y,
        },
        {
          type: Transform.SCALE_LEFT,
          x1: displayBounds.topLeft.x,
          y1: displayBounds.topLeft.y,
          x2: displayBounds.bottomLeft.x,
          y2: displayBounds.bottomLeft.y,
        },
        {
          type: Transform.SCALE_RIGHT,
          x1: displayBounds.topRight.x,
          y1: displayBounds.topRight.y,
          x2: displayBounds.bottomRight.x,
          y2: displayBounds.bottomRight.y,
        },
      ].map(({ type, x1, y1, x2, y2 }) => (
        <>
          <line
            key={type}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            strokeWidth={sideSize}
            stroke={color}
          />
          <line
            key={`${type}-hit-slop`}
            data-transform-type={type}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={'transparent'}
            strokeWidth={sideHitSlop}
            onMouseDown={handleMouseDown}
            onMouseOver={() => !currentTransform && setCursor('scale', type)}
            onMouseOut={() => !currentTransform && clearCursor()}
          />
        </>
      ))}

      {[
        {
          x: displayBounds.topLeft.x - rotationHitSlop / 2,
          y: displayBounds.topLeft.y - rotationHitSlop / 2,
          transformOrigin: 'bottom right',
          type: Transform.ROTATE_TOP_RIGHT,
        },
        {
          x: displayBounds.topRight.x + rotationHitSlop / 2,
          y: displayBounds.topRight.y - rotationHitSlop / 2,
          transformOrigin: 'bottom left',
          type: Transform.ROTATE_TOP_LEFT,
        },
        {
          x: displayBounds.bottomLeft.x - rotationHitSlop / 2,
          y: displayBounds.bottomLeft.y + rotationHitSlop / 2,
          transformOrigin: 'top right',
          type: Transform.ROTATE_BOTTOM_LEFT,
        },
        {
          x: displayBounds.bottomRight.x + rotationHitSlop / 2,
          y: displayBounds.bottomRight.y + rotationHitSlop / 2,
          transformOrigin: 'top left',
          type: Transform.ROTATE_BOTTOM_RIGHT,
        },
      ].map(({ x, y, transformOrigin, type }) => (
        <rect
          key={type}
          data-transform-type={type}
          x={x - rotationHitSlop / 2}
          y={y - rotationHitSlop / 2}
          onMouseDown={handleMouseDown}
          onMouseOver={() => !currentTransform && setCursor('rotate', type)}
          onMouseOut={() => !currentTransform && clearCursor()}
          width={rotationHitSlop}
          height={rotationHitSlop}
          fill={'transparent'}
          style={{
            transformBox: 'fill-box',
            transformOrigin,
            transform: `rotate(${boundsRect?.rotation ?? 0}deg)`,
          }}
        />
      ))}

      {[
        {
          x: displayBounds.topLeft.x,
          y: displayBounds.topLeft.y,
          type: Transform.SCALE_TOP_LEFT,
        },
        {
          x: displayBounds.topRight.x,
          y: displayBounds.topRight.y,
          type: Transform.SCALE_TOP_RIGHT,
        },
        {
          x: displayBounds.bottomLeft.x,
          y: displayBounds.bottomLeft.y,
          type: Transform.SCALE_BOTTOM_LEFT,
        },
        {
          x: displayBounds.bottomRight.x,
          y: displayBounds.bottomRight.y,
          type: Transform.SCALE_BOTTOM_RIGHT,
        },
      ].map(({ x, y, type }) => (
        <>
          <rect
            key={type}
            data-transform-type={type}
            x={x - vertexSize / 2}
            y={y - vertexSize / 2}
            onMouseDown={handleMouseDown}
            onMouseOver={() => !currentTransform && setCursor('scale', type)}
            onMouseOut={() => !currentTransform && clearCursor()}
            width={vertexSize}
            height={vertexSize}
            fill={'white'}
            style={{
              transformBox: 'fill-box',
              transformOrigin: 'center',
              transform: `rotate(${boundsRect?.rotation ?? 0}deg)`,
            }}
            strokeWidth={1 / viewport.zoom}
            stroke={color}
          />
        </>
      ))}

      {(currentTransform === Transform.POSITION ||
        currentTransform?.includes('scale')) &&
        wasTriggered &&
        snapGuides.map((guide, index) => (
          <line
            key={index}
            x1={guide.x1}
            y1={guide.y1}
            x2={guide.x2}
            y2={guide.y2}
            stroke={nodeColors.RED}
            strokeWidth={1 / viewport.zoom}
          />
        ))}
    </g>
  )
})

SvgTransform.displayName = 'SvgTransform'
