import { paper } from '@aninix-inc/renderer'

import { Entity } from '@aninix-inc/model'
import { getNodesBranchV2 } from './get-nodes-branch'
import { getTransformMatrixV2 } from './get-transform-matrix'

export function getAbsoluteTransformMatrixV2(payload: {
  entity: Entity
  time: number
}) {
  const { entity, time } = payload
  const branch = getNodesBranchV2(entity)
  const transformMatrix: paper.Matrix = branch.reduce(
    (matrix, e) => getTransformMatrixV2({ entity: e, matrix, time }),
    new paper.Matrix()
  )

  return transformMatrix
}
