import {
  Entity,
  StrokeCap,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
  mixed,
} from '@aninix-inc/model'
import { Select, SelectProps, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import * as styles from './index.scss'

export interface IProps {
  layers: Entity[]
}
export const StrokeCaps: React.FCC<IProps> = ({ layers }) => {
  useEntities(layers)
  const strokeCapStartComponents = layers.map((l) =>
    l.getComponentOrThrow(StrokeCapStartComponent)
  )
  const strokeCapEndComponents = layers.map((l) =>
    l.getComponentOrThrow(StrokeCapEndComponent)
  )
  const strokeCapStart = R.all(
    (c) => c.value === strokeCapStartComponents[0].value,
    strokeCapStartComponents
  )
    ? strokeCapStartComponents[0].value
    : mixed
  const strokeCapEnd = R.all(
    (c) => c.value === strokeCapEndComponents[0].value,
    strokeCapEndComponents
  )
    ? strokeCapEndComponents[0].value
    : mixed

  const strokeCapOptionsStart: SelectProps['options'] = [
    {
      id: StrokeCap.None,
      title: 'None',
      icon: (
        <icons.propertiesPanel.StrokeCap.None
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.None
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.None />,
    },
    {
      id: StrokeCap.ArrowLines,
      title: 'Line arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.LineArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.ArrowLines
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.LineArrow />,
    },
    {
      id: StrokeCap.ArrowEquilateral,
      title: 'Triangle arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.TriangleArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.ArrowEquilateral
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.TriangleArrow />,
    },
    {
      id: StrokeCap.TriangleFilled,
      title: 'Reverse triangle',
      icon: (
        <icons.propertiesPanel.StrokeCap.ReverseTriangle
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.TriangleFilled
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.ReverseTriangle />
      ),
    },
    {
      id: StrokeCap.CircleFilled,
      title: 'Circle arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.CircleArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.CircleFilled
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.CircleArrow />,
    },
    {
      id: StrokeCap.DiamondFilled,
      title: 'Diamond arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.DiamondArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.DiamondFilled
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.DiamondArrow />,
    },
    'divider',
    {
      id: StrokeCap.Round,
      title: 'Round',
      icon: (
        <icons.propertiesPanel.StrokeCap.Round
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.Round
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.Round />,
    },
    {
      id: StrokeCap.Square,
      title: 'Square',
      icon: (
        <icons.propertiesPanel.StrokeCap.Square
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapStart === StrokeCap.Square
                ? undefined
                : styles.textInverted,
          }}
        />
      ),
      activeElement: <icons.propertiesPanel.StrokeCap.Expanded.Square />,
    },
  ]

  const strokeCapOptionsEnd: SelectProps['options'] = [
    {
      id: StrokeCap.None,
      title: 'None',
      icon: (
        <icons.propertiesPanel.StrokeCap.None
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.None ? undefined : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.None
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.ArrowLines,
      title: 'Line arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.LineArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.ArrowLines
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.LineArrow
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.ArrowEquilateral,
      title: 'Triangle arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.TriangleArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.ArrowEquilateral
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.TriangleArrow
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.TriangleFilled,
      title: 'Reverse triangle',
      icon: (
        <icons.propertiesPanel.StrokeCap.ReverseTriangle
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.TriangleFilled
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.ReverseTriangle
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.CircleFilled,
      title: 'Circle arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.CircleArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.CircleFilled
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.CircleArrow
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.DiamondFilled,
      title: 'Diamond arrow',
      icon: (
        <icons.propertiesPanel.StrokeCap.DiamondArrow
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.DiamondFilled
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.DiamondArrow
          style={{ rotate: '180deg' }}
        />
      ),
    },
    'divider',
    {
      id: StrokeCap.Round,
      title: 'Round',
      icon: (
        <icons.propertiesPanel.StrokeCap.Round
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.Round
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.Round
          style={{ rotate: '180deg' }}
        />
      ),
    },
    {
      id: StrokeCap.Square,
      title: 'Square',
      icon: (
        <icons.propertiesPanel.StrokeCap.Square
          style={{
            // @ts-ignore
            '--figma-color-text':
              strokeCapEnd === StrokeCap.Square
                ? undefined
                : styles.textInverted,
            rotate: '180deg',
          }}
        />
      ),
      activeElement: (
        <icons.propertiesPanel.StrokeCap.Expanded.Square
          style={{ rotate: '180deg' }}
        />
      ),
    },
  ]

  return (
    <div className="flex w-full flex-row">
      <Select
        activeValueId={strokeCapStart}
        options={strokeCapOptionsStart}
        onChange={(newStrokeCapStart) => {
          layers.forEach((l) =>
            l.updateComponent(
              StrokeCapStartComponent,
              newStrokeCapStart as StrokeCap
            )
          )
        }}
        className="w-[96px] pl-[6px]"
      />
      <Select
        activeValueId={strokeCapEnd}
        options={strokeCapOptionsEnd}
        onChange={(newStrokeCapEnd) => {
          layers.forEach((l) =>
            l.updateComponent(
              StrokeCapEndComponent,
              newStrokeCapEnd as StrokeCap
            )
          )
        }}
        className="w-[96px] pl-[6px]"
      />
    </div>
  )
}

StrokeCaps.displayName = 'StrokeCaps'
