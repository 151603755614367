import {
  Entity,
  NodeType,
  NodeTypeComponent,
  NumberKeyframe,
  PointCountComponent,
  getValueNumber,
} from '@aninix-inc/model'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const PointCount: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const properties = layers.map((l) =>
    l.getComponentOrThrow(PointCountComponent)
  )

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={properties}
            time={time}
            icon={
              layers[0].getComponentOrThrow(NodeTypeComponent).value ===
              NodeType.Polygon ? (
                <icons.propertiesPanel.PolygonPointCount size={iconSize} />
              ) : (
                <icons.propertiesPanel.StarPointCount size={iconSize} />
              )
            }
            min={3}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={properties}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}
