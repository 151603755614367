import { Entity } from '@aninix-inc/model'
import {
  SvgLayer,
  convertEntityToSnapshot as convertNodeToSnapshot,
} from '@aninix-inc/renderer'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ImagesStore } from '../../stores'
import { getBoundingBoxAtTimeV2 } from '../../utils'

export interface IProps {
  time: number
  entity: Entity
  images: ImagesStore
  shouldRender?: (entity: Entity) => boolean
  isRoot?: boolean
  className?: string
}
const StaticSvgInner: React.FCC<IProps> = observer(
  ({ time, entity, images, shouldRender, isRoot }) => {
    const snapshot = convertNodeToSnapshot({
      entity,
      time,
      isRoot,
      isStatic: true,
      ignoreTrimPath: true,
      imagesStore: images,
    })

    return (
      <SvgLayer
        rootSnapshot={snapshot}
        snapshot={snapshot}
        // @ts-ignore
        shouldRender={shouldRender}
        ignoreViewportVisibility
      />
    )
  }
)

StaticSvgInner.displayName = 'StaticSvgInner'

export const StaticSvg: React.FCC<IProps> = observer(
  ({ time, entity, shouldRender, images, className, children }) => {
    const boundingBox = getBoundingBoxAtTimeV2({
      entity,
      time,
      ignorePosition: true,
    })

    return (
      <svg
        id={safeString(entity.id)}
        viewBox={`${boundingBox.x} ${boundingBox.y} ${boundingBox.width} ${boundingBox.height}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
      >
        <StaticSvgInner
          time={time}
          entity={entity}
          shouldRender={shouldRender}
          images={images}
          isRoot
        />

        {children}
      </svg>
    )
  }
)

StaticSvg.displayName = 'StaticSvg'
